<template>
    <div id="updateRank">
        <div v-if="userIsLogin == false" class="login">
            <h2>Login</h2>
            <FormKit id="formRank" class="form" type="form" @submit="handleSubmitLogin"
                :submit-attrs="{
                    inputClass: 'btn red submit'
                }"
                submit-label="Submit"
                incomplete-message="Please fill up all the fields."
            >
                <FormKit
                    type="text"
                    name="username"
                    label="Username:"
                    :classes="{
                        input: 'username',
                    }"
                    placeholder="Username"
                    validation="required"
                />
                <FormKit
                    type="password"
                    name="password"
                    label="Password:"
                    :classes="{
                        input: 'password',
                    }"
                    placeholder="password"
                    validation="required"
                />
            </FormKit>
        </div>
        <div v-else class="current-rank-list">
            <h2>UPDATE RANK</h2>
            <div :class="`card flex-custom card-${item.id}`" v-for="(item, index) in allRank" :key="index" @click="selected(item.id)">
                <img src="@/assets/kol/gold_wreath-02.png" />
                <p class="title" v-html="item.title">
                </p>
                <div style="display:none !important">{{ item.details }}</div>
                <img src="@/assets/kol/gold_wreath-03.png" />
                <img src="@/assets/kol/icon-edit.png" class="icon-edit"/>
                <div class="remove" @click="removeRank(item.id)">x</div>
            </div>
            <div class="submit-message success">
                <img src="@/assets/kol/icon-success.gif"/> Update Succeeded.
            </div>
            <div class="submit-message fail">
                <img src="@/assets/kol/icon-fail.gif"/> Failed to update.
            </div>
            <a class="createNewRank" @click="createRank()">CREATE NEW RANK</a>
        </div>
        <Modals @rankGeneratorFunc="rankGenerator" />
    </div>
</template>
  
<script lang="ts">
import {ref, provide } from 'vue'
import { checkSignInStatus } from "@/api/kol";
import { returnKey } from "@/api/kol";
import { signIn } from "@/api/kol";
import "@formkit/themes/genesis";

import { useCommonStore } from '@/stores/common'
import { useRankStore } from '@/stores/rankData'
import Modals from '@/components/Modals/ModalMain.vue'
    
    // @ is an alias to /src
    
    export default {
        name: 'updateRank',
        components: {
            Modals
        },
        computed: {
            errorCode() {
                return this.$store.getters.getError
            }
        },
        setup() {
            const commonStore = useCommonStore();
            const rankData = useRankStore()
            const allRank = ref([]);

            const rankGenerator = async () => {
                await rankData.getAllRankData();
                allRank.value = rankData.allRank;
                //console.log("allRank",allRank);
            }

            provide('rankGeneratorFunc', rankGenerator);

            return {
                commonStore,
                rankData,
                rankGenerator,
                allRank
            }
        },
        data() {
            return {
                userIsLogin: ref(false),
            }
        },
        methods: {
            async statusHandler() {
                await checkSignInStatus();
                const returnValue = await returnKey();
                //console.log("returnValue", returnValue);
                if (returnValue.data.username) {
                    this.rankData.setUser(returnValue.data.username);
                    this.userIsLogin = true;
                } else {
                    this.userIsLogin = false;
                }
                //console.log("this.userIsLogin", this.userIsLogin)
            },
            async handleSubmitLogin(formValues: any) {
                //console.log("formValues", formValues);
                const response = await signIn(formValues);
                console.log("response", response)
                await checkSignInStatus();

                //console.log("response signIn", response)
                if (response.data) {
                    window.location.replace(window.location.href)
                } else {
                    console.error('Login failed:', response.status);
                }
            },
            async createRank(){
                this.rankData.setSelectedCardContent();
                await this.rankData.setFormType("formCreate");
                this.commonStore.openModal('RankModal');
            },
            async selected(id: any){
                const ctitle = document.querySelector(".card-" + (id) + " .ctitle").textContent;
                const cname = document.querySelector(".card-" + (id) + " .name").textContent;
                
                this.rankData.setSelectedCard(id);
                this.rankData.setSelectedCardContent(id); // id
                
                await this.rankData.setFormType("formUpdate");
                await this.rankData.setCurrentRankId(id);
                this.commonStore.openModal('RankModal');
            },
            async removeRank(id : any){
                this.commonStore.openModal('RankModal');
                await this.rankData.setRemoveRankId(id);
            },
        },
        mounted() {
            this.statusHandler()
            this.rankGenerator()
        }
    }
    
</script>
  
  
<style lang="scss">
    #chat-widget-container, #livechat-eye-catcher {
        display:none;
    }
    body {
        margin: 0;
        background-color: #eff1f3;
    }
    #updateRank {
        height: 100vh;
        display: flex;
        h2 {
            font-size: 20px;
            font-family: SegoeUI;
        }
        .formkit-wrapper {
            margin:0 auto
        }
        .current-rank-list {
            padding: 40px 16px;
            max-width: 530px;
            width: 100%;
            margin: auto;
            position:relative;
            .card {
                padding: 16px 16px 20px;
                background: #fff;
                border-radius: 5px;
                border:1px solid transparent;
                margin-bottom:8px;
                cursor:pointer;
                transition:0.2s all linear;
                overflow: initial;

                &:hover {
                    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.2);
                    transform:translateY(-3px);
                    .icon-edit {
                        opacity: 0.7;
                    }
                }

                &:last-child{
                    margin-bottom:0;
                }
                .icon-edit {
                    position: absolute;
                    top: -15px;
                    left: 50%;
                    width: 25px;
                    margin-left: -12.5px;
                    opacity: 0.15;
                    z-index: 9;
                }
                .remove {
                    padding: 8px 8px 12px;
                    border-radius: 50px;
                    border: 2px dashed #000000;
                    line-height: 5px;
                    font-size: 18px;
                    font-weight: bold;
                    position: absolute;
                    right: 2px;
                    top: 2px;
                    color: #000;
                    opacity: 0.15;
                    &:hover {
                        opacity: 0.7;
                    }
                }
            }
            .flex-custom {
                display: flex;
                justify-content: space-around;
                align-items: center;
                padding: 16px 32px;
                margin: auto auto 10px;
                img {
                    max-width: 36px;
                }
            }
            
            p.title {
                font-family: SegoeUI;
                font-size: 16px;
                font-weight: bold;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.5;
                letter-spacing: normal;
                text-align: center;
                color: #d91d22;
                margin:0;
                max-width:260px;
                width:100%;
                .name {
                    font-family: Oswald;
                    font-size: 18px;
                    font-weight: 500;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.44;
                    letter-spacing: normal;
                    text-align: center;
                    color: #000018;
                    display: block;
                    margin-top:4px;
                    text-transform:uppercase;
                }
            }
        }
        .createNewRank {
            border: 2px dashed #b5cef2;
            padding: 20px;
            color: #81b5ff;
            font-weight: 500;
            display:block;
            cursor:pointer;
            border-radius: 4px;
        }
    }
    .submit-message {
        display:flex;
        position: fixed;
        top:0;
        left:0;
        font-weight: bold;
        background: #fff;
        width: 100%;
        height: 96px;
        justify-content: center;
        align-items: center;
        transition: all 0.5s linear;
        transform: translateY(-100%);
        opacity:0;
        img {
            margin-right:10px;
        }
        &.success {
            color: #00a300;
            box-shadow: inset 0 0px 12px #55d183;
        }
        &.fail {
            color: #d91d22;
            box-shadow: inset 0 0px 12px rgb(255 175 175);
        }
        &.active {
            transform: translateY(0);
            opacity:1;
        }
        
    }
    
    
</style>