<template>
  <a :id="btnId" class="btnEventDetail" :href="`${btnDetailLink}`" @click="setGTMEvent(btnId)"> {{
    $t(`ib6.eventInfo.rules`) }}</a>
</template>

<script lang="ts">
import { setGTMEvent } from "@/service/gtm";
export default {
  props: {
    btnDetailLink: String,
    btnId: {
      default: 'subpagerule_1'
    }
  },
  methods: {
    setGTMEvent
  }
};
</script>
  
<style lang="scss">
.btnEventDetail {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  border: none;
  padding: 4px 8px;
  border-radius: 13px;
  color: #e48f2b;
  background-color: #fff;

  &::after {
    margin: 1px 0 0 4px;
    content: '';
    border-top: 4px solid transparent;
    border-left: 6px solid #e48f2b;
    border-bottom: 4px solid transparent;
  }
}
</style>