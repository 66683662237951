<template>
    <section class="bg">
        <div class="content">
            <p :class="this.$i18n.locale" class="title" v-t="'jpDooTravel.tncTitle'"></p>
            <div class="wrapper">
                <div class="container-line">
                    <img 
                        :src="`${this.imageUrl}deco/airplane-divider-${isWebView ? 'big' : 'small'}.png`"
                        alt="plane">
                </div>
            </div>

            <div class="tab-buttons">
                <a :class="this.currentTab === 1 ? 'selected' : ''" v-t="'jpDooTravel.tnc'" @click="selectTab(1)"></a>
                <a :class="this.currentTab === 2 ? 'selected' : ''" v-t="'jpDooTravel.faq'" @click="selectTab(2)"></a>
            </div>

            <a class="download-btn" href="https://dooprime.link/3MEUbWr">
                <p class="download-title" v-t="`jpDooTravel.downloadTnc`"></p>
                <img class="download-icon" :src="this.imageUrl + 'external-link.png'" />
            </a>

            <div v-if="this.currentTab === 1" class="infos">
                <div v-for="(i, index) in this.tncList" :key="`desc-${index}`">
                    <TncSection :sectionNo='index + 1' :descNo="i"></TncSection>
                </div>
            </div>

            <div v-else>
                <FaqSection></FaqSection>
            </div>
        </div>
    </section>
</template>

<script lang="ts">
import { ref,  onMounted } from 'vue'
import FaqSection from './FaqSection.vue';
import TncSection from './TncSection.vue';

const currentTab = ref(1)
const tncList =[1,3,4,8,12,10]

export default {
    components: {
        FaqSection,
        TncSection,
    },
    data() {
        return {
            imageUrl: "https://cdn.doogroup.com/jp-doo-travel/",
            currentTab,
            tncList
        };
    },
    setup() {
        const isWebView = ref(window.innerWidth > 1000);

        const calculateScreenWidth = async () => {
            isWebView.value = window.innerWidth > 1000;
        }

        onMounted(() => {
            window.addEventListener("resize", () => { calculateScreenWidth() });
        });
        return {
            isWebView
        }
    },
    methods: {
        selectTab(tab: number) {
            if (currentTab.value === tab) {
                return;
            }
            currentTab.value = tab
        },
    }
}
</script>

<style lang="scss" scoped>
p,
h1 {
    margin: 0;
}

ul {

    padding-left: 0;
}

.bg {
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;

    .content {
        padding-bottom: 45px !important;
        max-width: 1360px;
        display: flex;
        flex-direction: column;
        padding: 0 24px;

        @media screen and (max-width: 1000px) {
            max-width: 600px;
        }
        
        .title {
            padding-top: 64px;
            font-size: 36px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: center;
            color: #de2026;
            margin-bottom: 4px;

            @media screen and (max-width:1000px) {
                font-size: 24px;
            }
        }

        .wrapper {
            display: flex;
            justify-content: center;

            .container-line {
                display: flex;
                justify-content: center;
                align-items: center;
                padding-left: 13px;
                width: 262px;
            }
        }

        .tab-buttons {
            display: flex;
            flex-direction: row;
            margin-top: 64px;
            justify-content: center;

            a {
                font-size: 20px;
                font-weight: bold;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                text-align: center;
                color: #757575;
                margin: 0 16px;

                @media screen and (max-width: 1000px) {
                    font-size: 16px;
                }
            }

            .selected {
                color: #000;
                border-bottom: 2px solid #de2026;
            }
        }

        .download-btn {
            display: flex;
            flex-direction: row;
            margin: 24px 0;
            justify-content: center;

            .download-title {
                font-size: 16px;
                font-weight: bold;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                text-align: center;
                color: #e51c1c;
            }

            .download-icon {
                width: 20px;
                height: 20px;
                margin-left: 16px;
            }
        }
    }
}
</style>