import { getCookie } from "@/service/cookie";
export const setGTMEvent = (btnId: string) => {
  //   this.window.dataLayer;
  //   window.dataLayer
  const userID = getCookie("uuid") || "";
  window.dataLayer.push({
    event: "buttonClick",
    UserID: userID,
    buttonId: btnId,
  });
  // console.log(window.dataLayer);
  return null;
};
