<template>
  <EventTabHeader :id="'4'"
    :title="agentStore.isLogin ? this.$t('ib6.eventBenefit.benefitTitle') : this.$t('ib6.eventBenefit.descriptionTitle')"
    :btnDetailLink="`${this.$i18n.locale}/benefitDetail#section_event_rule`"
    v-bind:class="agentStore.isLogin && 'benefit_after_login'"
    :btnId="`${commonStore.currentEvent}_subpagerule4_visitor`">

    <div id="redeem"
      v-if="Object.keys(redPacketApiData).length > 0 && (redPacketApiData.isDob || redPacketApiData.isFestive)">
      <img @click="this.checkRedPacketFunction()" :src="imageUrl + 'pending_redeem.png'" class="pending_redeem" />
      <p>{{ $t('ib6.eventBenefit.redeem') }}</p>
    </div>

    <div class="benefit_subtitle">
      <p>{{ $t("ib6.eventBenefit.subTitle1") }}</p>
      <p>{{ $t("ib6.eventBenefit.subTitle2") }}</p>
    </div>
    <div class="benefit_content">
      <div class="dob_box">
        <img :src="imageUrl + 'dob_redpacket.png'" class="dob_redpacket" />
        <h3>{{ $t("ib6.eventBenefit.dob") }}</h3>
        <p>{{ $t("ib6.eventBenefit.dobDescription1") }}</p>
        <p>{{ $t("ib6.eventBenefit.dobDescription2") }}</p>
      </div>
      <img :src="imageUrl + 'add.svg'" class="add" />
      <div class="festive_box">
        <img :src="imageUrl + 'festive_redpacket.png'" class="festive_redpacket" />
        <h3>{{ $t("ib6.eventBenefit.festive") }}</h3>
        <p>{{ $t("ib6.eventBenefit.festiveDescription1") }}</p>
        <p>{{ $t("ib6.eventBenefit.festiveDescription2") }}</p>
      </div>
    </div>
    <EventInfoBtn :btnInfoTitle="this.$t('ib6.eventBenefit.moreInfo')" :btnInfoLink="`${this.$i18n.locale}/benefitDetail`"
      :btn-id="`${commonStore.currentEvent}_subpage4_visitor`" />
  </EventTabHeader>
</template>

<script lang="ts">
import EventTabHeader from "@/views/ib6/home_event/EventTabHeader.vue";
import EventSatisticTime from "./EventSatisticTime.vue";
import EventInfoBtn from "@/components/button/EventInfoBtn.vue";
import { useAgentStore } from "@/stores/user";
import { RedPacketData } from "@/modal/RedPacket";
import { useCommonStore } from "@/stores/common";


export default {
  props: {
    redPacketApiData: {
      default: {} as RedPacketData,
    },
    checkRedPacketFunction: {},
  },

  data() {
    return {
      imageUrl:
        "https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/ib_event/ib6/images/",
      agentStore: useAgentStore(),
      commonStore: useCommonStore(),
    };
  },
  components: {
    EventTabHeader,
    EventSatisticTime,
    EventInfoBtn,
  },
};
</script>

<style lang="scss">
#tab_4 {
  margin-bottom: 0;

  .event_description {
    padding: 24px;
  }

  #redeem {
    img {
      cursor: pointer;
      width: 240px;
      height: 147px;
    }

    p {
      margin: 0;
      margin-bottom: 41px;
      font-size: 12px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #979797;
    }
  }

  .benefit_subtitle {
    margin-bottom: 16px;

    p {
      margin: 0;
      font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #252525;
    }
  }

  .benefit_content {
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-bottom: 40px;

    .dob_box {
      .dob_redpacket {
        width: 95px;
        height: 88px;
      }

      h3 {
        font-family: AlibabaPuHuiTi;
        margin: 0;
        margin-bottom: 8px;
        font-size: 16px;
        font-weight: 900;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #000;
      }

      p {
        margin: 0;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #424b55;
      }
    }

    .add {
      height: 16px;
      width: 16px;
      margin-bottom: 60px;
    }

    .festive_box {
      .festive_redpacket {
        width: 95px;
        height: 88px;
      }

      h3 {
        font-family: AlibabaPuHuiTi;
        margin: 0;
        margin-bottom: 8px;
        font-size: 16px;
        font-weight: 900;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #000;
      }

      p {
        margin: 0;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #424b55;
      }
    }
  }
}

.benefit_after_login {
  padding: 0 16px;
  margin-bottom: 40px !important;
}
</style>
