import api from "./config";

const baseUrl = "/track";

export const trackId = async (data: any) => {
  try {
    const payload = await api({
      method: "post",
      url: baseUrl,
      data: data,
    });
    return payload;
  } catch (e) {
    return e;
  }
};
