<template>
    <div class="modal-content">
        <h3>{{ $t("modal.myRecord") }}</h3>
        <div v-if="(lists.length > 0)">
            <div v-for="item in lists" class="list" :key=item>
                <h4 v-if="item.receiveRound === 1">
                    {{ $t("modal.round1") }} USD {{ item.rewardAmount.toFixed(2).toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    }} <span class="status">{{ $t("modal.rewarded") }}</span>
                </h4>
                <h4 v-if="item.receiveRound === 2">
                    {{ $t("modal.round2") }} USD {{ item.rewardAmount.toFixed(2).toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    }} <span class="status">{{ $t("modal.rewarded") }}</span>
                </h4>
                <h4 v-if="item.receiveRound === 3">
                    {{ $t("modal.round3") }} USD {{ item.rewardAmount.toFixed(2).toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    }} <span class="status">{{ $t("modal.rewarded") }}</span>
                </h4>

                <p>{{ new Date(item.createTime).getFullYear() }}.{{ ('0' + (new Date(item.createTime).getMonth() +
                    1)).slice(-2) }}.{{
        ('0' + (new Date(item.createTime).getDate())).slice(-2)
    }} {{ ('0' + (new Date(item.createTime).getHours())).slice(-2) }}:{{ ('0' + (new
    Date(item.createTime).getMinutes())).slice(-2) }}</p>
                <p :class="(item.serialNumber.length > 18) ? 'overflow' : ''">{{ $t("modal.serialNumber") }}{{
                    item.serialNumber
                }}</p>
            </div>
        </div>
        <div v-else-if="lists.length === 0 && isLoading === false" class="modal-body">
            <img :src="imageUrl + 'pop_record.png'" alt="">
            <p>{{ $t("modal.noRecord") }}</p>
        </div>
    </div>
</template>

<script lang="ts">
import { useCommonStore } from '@/stores/common'
import { claimRewardHistory, } from '@/api/getEventConfig'
export default {
    setup() {
        const commonStore = useCommonStore()
        return {
            commonStore
        }
    },

    data() {
        return {
            commonStore: useCommonStore(),
            imageUrl: "http://doo-prime-static.oss-cn-hongkong.aliyuncs.com/ib_3.0/images/",
            lists: [],
            isLoading: false,
        }
    },
    methods: {
        async getClaimRewardHistory() {
            this.isLoading = true;
            const apiData = await claimRewardHistory();
            if (apiData.data.code === 0) {
                this.lists = apiData.data.data || [];
            } else {
                this.lists = [];
            }
            this.isLoading = false;

        },
    },

    created() {
        this.getClaimRewardHistory();
    }
}
</script>

<style lang="scss">
.PrizeHistory {
    .modal-content-wrapper {
        max-width: 320px;
        width: 100%;
        min-height: 394px;
        padding: 24px 32px 31.5px;

        .modal-btn-close {
            bottom: -93px;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }

    .modal-content {
        height: 100%;

        .list:last-child {
            border-bottom: 0;
        }

        .list {
            text-align: left;
            border-bottom: 1px solid rgba(0, 0, 0, 0.15);
            padding-bottom: 15.5px;
            margin-top: 18.5px;

            &:first-child {
                margin-top: 24px;
            }

            &:last-child {
                border-bottom: 0px;
            }

            h4 {
                font-family: MicrosoftYaHei;
                font-size: 16px;
                font-weight: 600;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                text-align: left;
                color: #14142a;
                margin: 0;
                margin-bottom: 8px;

                .status {
                    float: right;
                    padding: 2px 8px;
                    background-color: rgba(255, 0, 0, 0.1);
                    font-family: MicrosoftYaHei;
                    font-size: 12px;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: normal;
                    letter-spacing: 1px;
                    text-align: left;
                    color: #ee0a24;
                }
            }

            p {

                text-align: left;
                margin: 0;
                margin-bottom: 4px;
                font-family: MicrosoftYaHei;
                font-size: 12px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                color: #878793;



                &:last-child {
                    margin-bottom: 0px;
                }
            }

            .overflow {
                overflow: hidden;
                // color: #d00e0e;
                max-width: 180px;
                // display: inline-block;
                white-space: nowrap;
                text-overflow: ellipsis !important;
            }
        }



        .modal-body {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            margin-top: 32px;

            h3 {
                margin-bottom: 0;
            }
        }

        h3 {
            margin: 0;
            font-family: MicrosoftYaHei;
            font-size: 24px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: 2px;
            text-align: center;
            color: #14142a;
        }

        p {
            font-family: MicrosoftYaHei;
            font-size: 16px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: center;
            color: #687783;
        }

        button {
            max-width: 240px;
            width: 100%;
            display: block;
        }
    }
}
</style>