<template>
    <section id="reward_claim_history">
        <div class="container">
            <h4>我的领取记录</h4>
            <div class="tab">
                <a @click="activeTab(0)" class="tablinks active">{{ $t('ib6.monthlyStatistics.tabTitle1') }}</a>
                <a @click="activeTab(1)" class="tablinks ">{{ $t('ib6.monthlyStatistics.tabTitle2') }}</a>
            </div>
        </div>
    </section>
</template>

<script lang="ts">
export default {
    data() {
        return {
        }
    },
    methods: {
        activeTab(id: number) {
            if (this.selected_tab != id) {
                let head = document.querySelectorAll<HTMLElement>('.tablinks');

                for (let index = 0; index < head.length; index++) {
                    const element = head[index];
                    if (index == id) {
                        element.classList.toggle("active");
                    } else {
                        element.classList.remove("active");
                    }
                }
            }

            this.selected_tab = id
        },
    },
    mounted() {

    },
}
</script>
<style lang="scss">
#reward_claim_history {

    /* Style the tab */
    .tab {
        margin-top: 48px;
        overflow: hidden;
        border-radius: 6px;
        border-style: solid;
        border-width: 2px;
        border-image-source: linear-gradient(96deg, #e0e0e0 6%, #fff 26%, #e0e0e0 53%, #fff 72%, #e0e0e0 87%);
        border-image-slice: 1;
        background-image: linear-gradient(to bottom, #fff, #fff), linear-gradient(96deg, #e0e0e0 6%, #fff 26%, #e0e0e0 53%, #fff 72%, #e0e0e0 87%);
        background-origin: border-box;
        background-clip: content-box, border-box;
        position: relative;
        z-index: 1;



        a {
            width: 50%;
            float: left;
            cursor: pointer;
            padding: 14px 16px 22px;
            transition: 0.3s;
            font-size: 20px;
            font-weight: 900;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: center;
            color: #c39654;
        }

        a.active {
            // background-color: #ccc;
            box-shadow: 0 2px 12px 0 rgba(151, 151, 151, 0.3);
            border-radius: 6px;
            border: 2px solid #e0e0e0;
            border-image-slice: 1;
            background-image: linear-gradient(to right, #da2328 0%, #eb5d61 100%);
            color: #fff;

        }
    }

    /* Style the tab content */
    .tab_content {
        margin-top: -8px;
        position: relative;
        z-index: 1;
        display: none;
        padding: 6px 16px;
        border-radius: 12px;
        border: solid 1px #fff;
        background-color: #fff;

        &.active {
            display: block;
        }
    }
}
</style>
