<template>
  <div id="backToPrevious">
    <button @click="back">
      <img :src="imageUrl + 'back_icon.svg'" />
    </button>
    <h1>{{ pageTitle }}</h1>
    <div class="hidden"></div>
  </div>
</template>

<script lang="ts">
export default {
  props: {
    pageTitle: String,
  },
  data() {
    return {
      imageUrl:
        "https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/ib_event/ib6/images/",
    };
  },
  methods: {
    back() {
      this.$router.push('/');
    },
  },
};
</script>

<style lang="scss">
#backToPrevious {
  width: 100%;
  height: 64px;
  display: flex;
  align-items:center;
  background:transparent;

  h1 {
    font-family: AlibabaPuHuiTi;
    font-size: 16px !important;
    font-weight: 900 !important;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #252525 !important;
    margin:auto !important;
  }

  button {
    background: transparent;
    border: none;
    padding: 0;
    cursor: pointer;
  }

  .hidden{
    width:16px;
  }
}
</style>
