import api from "./config";

const baseUrl = "/kol";

export const getRankList = async () => {
  try {
    const payload = await api({
      method: "get",
      url: `${baseUrl}/getRankList`,
    });
    return payload;
  } catch (e) {
    return e;
  }
};

export const createNewRank = async (data: any) => {
  try {
    const payload = await api({
      method: "post",
      url: `${baseUrl}/createNewRank`,
      data: data,
    });
    return payload;
  } catch (e) {
    return e;
  }
};

export const updateRank = async (data: any) => {
  try {
    const payload = await api({
      method: "post",
      url: `${baseUrl}/updateRank`,
      data: data,
    });
    return payload;
  } catch (e) {
    return e;
  }
};

export const deleteRank = async (id: any) => {
  try {
    const payload = await api({
      method: "delete",
      url: `${baseUrl}/deleteRank/${id}`,
    });
    return payload;
  } catch (e) {
    return e;
  }
};

export const checkSignInStatus = async () => {
  try {
    const payload = await api({
      method: "GET",
      url: `${baseUrl}/authenticateToken`,
    });
    return payload;
  } catch (e) {
    return e;
  }
};
export const returnKey = async () => {
  try {
    const payload = await api({
      method: "GET",
      url: `${baseUrl}/protected`,
    });
    return payload;
  } catch (e) {
    return e;
  }
};

export const signIn = async (data: any) => {
  try {
    const payload = await api({
      method: "post",
      url: `${baseUrl}/signIn`, 
      data: data,
    });
    return payload;
  } catch (e) {
    return e;
  }
};