<template>
    <TheMessage :successMail="this.successMail" @close-pop-up="this.closePopUp()"></TheMessage>
    <section>
        <form @submit.prevent="this.submitForm()">

            <div class="wrapper">
                <div :class="this.$i18n.locale" class="form-control">
                    <input :class="this.$i18n.locale" type="text" name="surname" class="surname" required :placeholder="this.$t('jpDooTravel.surname')"
                        @blur="this.validateInput([surname, 'checkSurname'])" v-model.trim="this.surname">
                    <p :class="this.inValidSurname, this.$i18n.locale" v-if="this.checkSurname === 'invalid'"
                        v-t="'jpDooTravel.invalid-surname'"></p>
                </div>
                <div class="form-control">
                    <input type="text" name="name" class="name" :class="this.$i18n.locale" required
                        :placeholder="this.$t('jpDooTravel.name')" @blur="this.validateInput([name, 'checkName'])"
                        v-model="this.name">
                    <p :class="this.inValidName, this.$i18n.locale" v-if="this.checkName === 'invalid'"
                        v-t="'jpDooTravel.invalid-name'"></p>
                </div>
            </div>

            <div class="form-control">
                <input class="input-row email" name="email" :class="this.$i18n.locale" type="email" required
                    :placeholder="this.$t('jpDooTravel.emailAddress')" v-model="this.email"
                    @blur="this.validateEmail(email)">
                <p :class="this.inValidEmail, this.$i18n.locale" v-if="this.checkEmail === 'invalid'"
                    v-t="'jpDooTravel.invalid-email'"></p>
            </div>

            <div class="wrapper-mobile">
                <div class="form-control">
                    <div class="code-wrapper">
                        <select class="mobile-code" name="countryCode" required v-model="this.countryCode">
                            <option v-for=" code, index in this.phoneinit" :key="index" :value="code"> {{ code }} </option>
                            <!-- <option value="123">123</option>
                            <option value="456">456</option> -->
                        </select>
                    </div>
                    <input :class="this.$i18n.locale" class="input-row hp" name="number" type="text" required
                        :placeholder="this.$t('jpDooTravel.phoneNumber')" v-model="this.number"
                        @blur="this.validatePhone(number)">
                    <p :class="this.inValidNumber, this.$i18n.locale" v-if="this.checkNumber === 'invalid'"
                        v-t="'jpDooTravel.invalid-number'">
                    </p>
                </div>
            </div>

            <textarea :class="this.$i18n.locale" class="comment-section" v-model="this.comment" rows="10" cols="70"
                :placeholder="this.$t('jpDooTravel.remarks')"></textarea>

            <div class="submit-now">
                <button :class="this.$i18n.locale" :id="isWebView ? 'button_submit' : 'button_submit2'" v-t="'jpDooTravel.submit'" class="submit-now-btn">
                </button>
            </div>
        </form>
    </section>
</template>

<script lang="ts" >
import phoneList from '@/js/phonecode_en.json'
import { mailer } from '@/api/mail'
import TheMessage from './Message.vue'
import { ref,  onMounted } from 'vue'

export default {
    components: {
        TheMessage
    },
    data() {
        return {
            phoneList,
            phoneinit: [] as {
                key: string;
                name: string;
                value: string;
            }[],
            surname: "",
            name: "",
            email: "",
            countryCode: "+81",
            number: "",
            comment: "",
            checkSurname: "pending",
            checkName: "pending",
            checkEmail: "pending",
            checkNumber: "pending",
            successMail: false,
            lang: ''
        };
    },
    setup() {
        const isWebView = ref(window.innerWidth > 1000);

        const calculateScreenWidth = async () => {
            isWebView.value = window.innerWidth > 1000;
        }
        onMounted(() => {
            window.addEventListener("resize", () => { calculateScreenWidth() });
        });
        return {
            isWebView
        }
    },
    computed: {
        inValidSurname() {
            return this.checkSurname === "invalid" ? "invalid-input" : "";
        },
        inValidName() {
            return this.checkName === "invalid" ? "invalid-input" : "";
        },
        inValidEmail() {
            return this.checkEmail === "invalid" ? "invalid-input" : "";
        },
        inValidNumber() {
            return this.checkNumber === "invalid" ? "invalid-input" : "";
        }
    },
    methods: {
        initPhoneCodeSelect() {
            let newarray = [];
            for (let i = 0; i < this.phoneList.length; i++) {
                const phone = this.phoneList[i];
                newarray.push(phone.phoneCode);
            }
            this.phoneinit = newarray;
        },
        validateInput(...inputCategory: string[]) {
            inputCategory.forEach(element => {
                if (element[0] === "") {
                    this[element[1]] = "invalid";
                }
                else {
                    this[element[1]] = "valid";
                }
            });
        },
        validateEmail(email: string) {
            const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
            const isValid = emailRegex.test(email)
            this.checkEmail = isValid ? 'valid' : 'invalid'
        },
        validatePhone(phone: string) {
            const phoneRegex = /^[0-9]*$/
            const isValid = phoneRegex.test(phone)
            this.checkNumber = isValid ? 'valid' : 'invalid'
        },
        async submitForm() {

            this.lang = this.$route.path.split('/')[1]

            if (this.checkSurname === "valid" && this.checkName === "valid" && this.checkEmail === "valid" && this.checkNumber === "valid") {
                try {
                    const formValues = {
                        url: this.lang,
                        surname: this.surname,
                        name: this.name,
                        email: this.email,
                        number: this.countryCode + this.number,
                        comment: this.comment
                    };
                    console.log("flag1", formValues.url);
                    const response = await mailer(formValues);
                    console.log(response);

                    console.log(response.data.status, 'response code -------------');

                    if (response.data.status === 200) {
                        this.successMail = true
                    }

                    this.surname = "";
                    this.name = "";
                    this.email = "";
                    this.countryCode = "+81";
                    this.number = "";
                    this.comment = "";
                    this.checkSurname = "pending";
                    this.checkName = "pending";
                    this.checkEmail = "pending";
                    this.checkNumber = "pending";
                }
                catch (e) {
                    console.log(e);
                }
            }
        },
        closePopUp(code: boolean) {
            this.successMail = false
        }
    },
    created() {
        this.initPhoneCodeSelect();
        console.log(this.$route.path.split('/')[1], '----------------url');

    },
}
</script>

<style lang="scss" scoped>
p,
h1 {
    margin: 0;
}

section {
    padding-bottom: 0;
    width: 100%;
    max-width: 1360px;
    padding: 0 20px;

    form {

        .input-row {
            width: 100%;
            height: 60px;
            padding: 20px 200px 19px 16px;
            border-radius: 1px;
            border: solid 1px #e4e4e4;
            background-color: #fff;
            margin-top: 16px;

            @media only screen and (max-width: 1000px) {
                height: 60px;
                padding: 20px 50px 19px 16px;
                border-radius: 1px;
                border: solid 1px #e4e4e4;
                background-color: #fff;
            }
        }

        .invalid-input {
            color: #de2026;
            text-align: left;
        }

        .wrapper {
            margin-top: 64px;
            display: flex;
            justify-content: center;
            gap: 16px;
            width: 100%;

            @media only screen and (max-width: 1000px) {
                margin-top: 32px;
            }

            .form-control {
                width: 100%;

                input {
                    width: 100%;
                    height: 60px;
                    padding: 20px 20px 19px 16px;
                    border: solid 1px #dedede;
                    background-color: #fff;
                }


            }

            .invalid-input {
                text-align: left;
                color: #de2026;
            }

            @media only screen and (max-width: 1000px) {
                .form-control {
                    input {
                        width: 100%;
                        height: 60px;
                        padding: 20px 85px 19px 16px;
                        border: solid 1px #dedede;
                        background-color: #fff;
                    }


                }
            }
        }

        .wrapper-mobile {
            display: flex;
            justify-content: center;

            .form-control {
                position: relative;
                width: 100%;
                
                .code-wrapper {
                    position: absolute;
                    top: 38px;
                    left: 15px;

                    select {
                        width: 61px;
                    }

                    .mobile-code {
                        border: none;
                    }
                }
            }

            .form-control {
                .hp {
                    padding-left: 81px;
                }
            }

        }


        .submit-now {
            .submit-now-btn {
                width: 142px;
                white-space:nowrap;
                margin-top: 32px;
                padding: 13.5px 28.9px 13.5px 29.1px;
                border: none;
                border-radius: 8px;
                background-image: linear-gradient(to bottom, #f46661 0%, #de2026 100%);
                font-size: 20px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                text-align: center;
                color: #fff;

                @media only screen and (max-width: 1000px) {
                    font-size: 16px;
                }
            }
        }

        .comment-section {
            width: 100%;
            height: 120px;
            border: solid 1px #e4e4e4;
            background-color: #fff;
            margin-top: 16px;

            @media only screen and (max-width: 1000px) {
                height: 120px;
            }
        }

        .comment-section::placeholder {
            padding-left: 16px;
            padding-top: 20px;
            font-family: SegoeUI;
            font-size: 16px;
            font-weight: 300;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: 0.5px;
            color: #757575;
        }
    }
}</style>