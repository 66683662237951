let countryList = [
  {
    key: "LOCAL",
    name: "localhost",
    name_sc: "localhost",
    name_tc: "localhost",
    region: "latin_america_latam",
    group: "",
    asia: "",
    oceania: "",
  },
  {
    key: "CN",
    name: "China",
    name_sc: "中国",
    name_tc: "中國",
    region: "",
    group: "asia",
    asia: "east",
    oceania: "",
  },
  {
    key: "HK",
    name: "Hong Kong",
    name_sc: "香港",
    name_tc: "香港",
    region: "",
    group: "asia",
    asia: "east",
    oceania: "",
  },
  {
    key: "MO",
    name: "Macao",
    name_sc: "澳门",
    name_tc: "澳門",
    region: "",
    group: "asia",
    asia: "east",
    oceania: "",
  },
  {
    key: "JP",
    name: "Japan",
    name_sc: "日本",
    name_tc: "日本",
    region: "",
    group: "asia",
    asia: "east",
    oceania: "",
  },
  {
    key: "MN",
    name: "Mongolia",
    name_sc: "蒙古",
    name_tc: "蒙古",
    region: "",
    group: "asia",
    asia: "east",
    oceania: "",
  },
  {
    key: "KP",
    name: "North Korea",
    name_sc: "北朝鲜",
    name_tc: "北朝鮮",
    region: "",
    group: "asia",
    asia: "east",
    oceania: "",
  },
  {
    key: "KR",
    name: "South Korea",
    name_sc: "韩国",
    name_tc: "韓國",
    region: "",
    group: "asia",
    asia: "east",
    oceania: "",
  },
  {
    key: "TW",
    name: "Taiwan",
    name_sc: "台湾",
    name_tc: "台灣",
    region: "",
    group: "asia",
    asia: "east",
    oceania: "",
  },

  {
    key: "RU",
    name: "Russian Far East (Russia)",
    name_sc: "俄羅斯遠東（俄羅斯）",
    name_tc: "俄羅斯遠東（俄羅斯）",
    region: "",
    group: "asia",
    asia: "north",
    oceania: "",
  },

  {
    key: "AF",
    name: "Afghanistan",
    name_sc: "阿富汗",
    name_tc: "阿富汗",
    region: "",
    group: "asia",
    asia: "south",
    oceania: "",
  },
  {
    key: "BD",
    name: "Bangladesh",
    name_sc: "孟加拉国",
    name_tc: "孟加拉國",
    region: "",
    group: "asia",
    asia: "south",
    oceania: "",
  },
  {
    key: "BT",
    name: "Bhutan",
    name_sc: "不丹",
    name_tc: "不丹",
    region: "",
    group: "asia",
    asia: "south",
    oceania: "",
  },
  {
    key: "IO",
    name: "British Indian Ocean Territory (United Kingdom)",
    name_sc: "英属印度洋领地（英国）",
    name_tc: "英属印度洋领地（英国）",
    region: "",
    group: "asia",
    asia: "south",
    oceania: "",
  },
  {
    key: "IN",
    name: "India",
    name_sc: "印度",
    name_tc: "印度",
    region: "",
    group: "asia",
    asia: "south",
    oceania: "",
  },
  {
    key: "MV",
    name: "Maldives",
    name_sc: "马尔代夫",
    name_tc: "馬爾代夫",
    region: "",
    group: "asia",
    asia: "south",
    oceania: "",
  },
  {
    key: "NP",
    name: "Nepal",
    name_sc: "尼泊尔",
    name_tc: "尼泊爾",
    region: "",
    group: "asia",
    asia: "south",
    oceania: "",
  },
  {
    key: "PK",
    name: "Pakistan",
    name_sc: "巴基斯坦",
    name_tc: "巴基斯坦",
    region: "",
    group: "asia",
    asia: "south",
    oceania: "",
  },
  {
    key: "LK",
    name: "Sri Lanka",
    name_sc: "斯里兰卡",
    name_tc: "斯里蘭卡",
    region: "",
    group: "asia",
    asia: "south",
    oceania: "",
  },

  {
    key: "BN",
    name: "Brunei",
    name_sc: "文莱",
    name_tc: "文萊",
    region: "",
    group: "asia",
    asia: "southest",
    oceania: "",
  },
  {
    key: "KH",
    name: "Cambodia",
    name_sc: "柬埔寨",
    name_tc: "柬埔寨",
    region: "",
    group: "asia",
    asia: "southest",
    oceania: "",
  },
  {
    key: "ID",
    name: "Indonesia ",
    name_sc: "印度尼西亚",
    name_tc: "印度尼西亞",
    region: "",
    group: "asia",
    asia: "southest",
    oceania: "",
  },
  {
    key: "LA",
    name: "Laos",
    name_sc: "寮国",
    name_tc: "寮國",
    region: "",
    group: "asia",
    asia: "southest",
    oceania: "",
  },
  {
    key: "MY",
    name: "Malaysia",
    name_sc: "马来西亚",
    name_tc: "馬來西亞",
    region: "",
    group: "asia",
    asia: "southest",
    oceania: "",
  },
  {
    key: "MM",
    name: "Myanmar",
    name_sc: "缅甸",
    name_tc: "緬甸",
    region: "",
    group: "asia",
    asia: "southest",
    oceania: "",
  },
  {
    key: "PH",
    name: "Philippines",
    name_sc: "菲律宾",
    name_tc: "菲律賓",
    region: "",
    group: "asia",
    asia: "southest",
    oceania: "",
  },
  {
    key: "SG",
    name: "Singapore",
    name_sc: "新加坡",
    name_tc: "新加坡",
    region: "",
    group: "asia",
    asia: "southest",
    oceania: "",
  },
  {
    key: "TH",
    name: "Thailand",
    name_sc: "泰国",
    name_tc: "泰國",
    region: "",
    group: "asia",
    asia: "southest",
    oceania: "",
  },
  {
    key: "TL",
    name: "Timor-Leste",
    name_sc: "东帝汶",
    name_tc: "東帝汶",
    region: "",
    group: "asia",
    asia: "southest",
    oceania: "",
  },
  {
    key: "VN",
    name: "Vietnam",
    name_sc: "越南",
    name_tc: "越南",
    region: "",
    group: "asia",
    asia: "southest",
    oceania: "",
  },

  {
    key: "AU",
    name: "Australia",
    name_sc: "澳大利亚",
    name_tc: "澳大利亞",
    region: "",
    group: "oceania",
    asia: "",
    oceania: "australasia",
  },
  {
    key: "NZ",
    name: "New Zealand",
    name_sc: "新西兰",
    name_tc: "新西蘭",
    region: "",
    group: "oceania",
    asia: "",
    oceania: "australasia",
  },

  {
    key: "FJ",
    name: "Fiji",
    name_sc: "斐济",
    name_tc: "斐濟",
    region: "",
    group: "oceania",
    asia: "",
    oceania: "melanesia",
  },
  {
    key: "NC",
    name: "New Caledonia (France)",
    name_sc: "新喀里多尼亚（法国）",
    name_tc: "新喀裡多尼亞（法國）",
    region: "",
    group: "oceania",
    asia: "",
    oceania: "melanesia",
  },
  {
    key: "PG",
    name: "Papua New Guinea",
    name_sc: "巴布亚新几内亚",
    name_tc: "巴布亞新幾內亞",
    region: "",
    group: "oceania",
    asia: "",
    oceania: "melanesia",
  },
  {
    key: "SB",
    name: "Solomon Islands",
    name_sc: "所罗门群岛",
    name_tc: "所羅門群島",
    region: "",
    group: "oceania",
    asia: "",
    oceania: "melanesia",
  },
  {
    key: "VU",
    name: "Vanuatu",
    name_sc: "瓦努阿图",
    name_tc: "瓦努阿圖",
    region: "",
    group: "oceania",
    asia: "",
    oceania: "melanesia",
  },

  {
    key: "GU",
    name: "Guam (United States)",
    name_sc: "关岛（美国）",
    name_tc: "關島（美國）",
    region: "",
    group: "oceania",
    asia: "",
    oceania: "micronesia",
  },
  {
    key: "KI",
    name: "Kiribati",
    name_sc: "基里巴斯",
    name_tc: "基里巴斯",
    region: "",
    group: "oceania",
    asia: "",
    oceania: "micronesia",
  },
  {
    key: "MH",
    name: "Marshall Islands",
    name_sc: "马绍尔群岛",
    name_tc: "馬紹爾群島",
    region: "",
    group: "oceania",
    asia: "",
    oceania: "micronesia",
  },
  {
    key: "FM",
    name: "Micronesia",
    name_sc: "密克罗尼西亚",
    name_tc: "密克羅尼西亞",
    region: "",
    group: "oceania",
    asia: "",
    oceania: "micronesia",
  },
  {
    key: "NR",
    name: "Nauru",
    name_sc: "瑙鲁",
    name_tc: "瑙魯",
    region: "",
    group: "oceania",
    asia: "",
    oceania: "micronesia",
  },
  {
    key: "MP",
    name: "Northern Mariana Islands (United States)",
    name_sc: "北马里亚纳群岛（美国）",
    name_tc: "北馬里亞納群島（美國）",
    region: "",
    group: "oceania",
    asia: "",
    oceania: "micronesia",
  },
  {
    key: "PW",
    name: "Palau",
    name_sc: "帕劳",
    name_tc: "帕勞",
    region: "",
    group: "oceania",
    asia: "",
    oceania: "micronesia",
  },
  {
    key: "WK",
    name: "Wake Island (United States)",
    name_sc: "威克岛（美国）",
    name_tc: "威克島（美國）",
    region: "",
    group: "oceania",
    asia: "",
    oceania: "micronesia",
  },

  {
    key: "AS",
    name: "American Samoa (United States)",
    name_sc: "美属萨摩亚（美国）",
    name_tc: "美屬薩摩亞（美國）",
    region: "",
    group: "oceania",
    asia: "",
    oceania: "polynesia",
  },
  {
    key: "CP",
    name: "Clipperton Island (France)",
    name_sc: "克利珀顿岛（法国）",
    name_tc: "克利珀頓島（法國）",
    region: "",
    group: "oceania",
    asia: "",
    oceania: "polynesia",
  },
  {
    key: "CK",
    name: "Cook Islands",
    name_sc: "库克群岛",
    name_tc: "庫克群島",
    region: "",
    group: "oceania",
    asia: "",
    oceania: "polynesia",
  },
  {
    key: "CL",
    name: "Easter Island (Chile)",
    name_sc: "复活节岛（智利）",
    name_tc: "復活節島（智利）",
    region: "",
    group: "oceania",
    asia: "",
    oceania: "polynesia",
  },
  {
    key: "PF",
    name: "French Polynesia (France)",
    name_sc: "法属波利尼西亚（法国）",
    name_tc: "法屬波利尼西亞（法國）",
    region: "",
    group: "oceania",
    asia: "",
    oceania: "polynesia",
  },
  {
    key: "HI",
    name: "Hawaii (United States)",
    name_sc: "夏威夷（美国）",
    name_tc: "夏威夷（美國）",
    region: "",
    group: "oceania",
    asia: "",
    oceania: "polynesia",
  },
  {
    key: "NU",
    name: "Niue",
    name_sc: "纽埃",
    name_tc: "紐埃",
    region: "",
    group: "oceania",
    asia: "",
    oceania: "polynesia",
  },
  {
    key: "PN",
    name: "Pitcairn Islands (United Kingdom)",
    name_sc: "皮特凯恩群岛（英国）",
    name_tc: "皮特凱恩群島（英國）",
    region: "",
    group: "oceania",
    asia: "",
    oceania: "polynesia",
  },
  {
    key: "WS",
    name: "Samoa",
    name_sc: "萨摩亚",
    name_tc: "薩摩亞",
    region: "",
    group: "oceania",
    asia: "",
    oceania: "polynesia",
  },
  {
    key: "TK",
    name: "Tokelau (New Zealand)",
    name_sc: "托克劳（新西兰）",
    name_tc: "托克勞（新西蘭）",
    region: "",
    group: "oceania",
    asia: "",
    oceania: "polynesia",
  },
  {
    key: "TO",
    name: "Tonga",
    name_sc: "汤加",
    name_tc: "湯加",
    region: "",
    group: "oceania",
    asia: "",
    oceania: "polynesia",
  },
  {
    key: "TV",
    name: "Tuvalu",
    name_sc: "图瓦卢",
    name_tc: "圖瓦盧",
    region: "",
    group: "oceania",
    asia: "",
    oceania: "polynesia",
  },
  {
    key: "UM",
    name: "U.S. Minor Outlying Islands (United States)",
    name_sc: "美国本土外小岛屿（美国）",
    name_tc: "美國本土外小島嶼（美國）",
    region: "",
    group: "oceania",
    asia: "",
    oceania: "polynesia",
  },
  {
    key: "WF",
    name: "Wallis and Futuna (France)",
    name_sc: "瓦利斯群岛和富图纳群岛（法国）",
    name_tc: "瓦利斯群島和富圖納群島（法國）",
    region: "",
    group: "oceania",
    asia: "",
    oceania: "polynesia",
  },

  {
    key: "DZ",
    name: "Algeria",
    name_sc: "阿尔及利亚",
    name_tc: "阿爾及利亞",
    region: "",
    group: "north_africa",
    asia: "",
    oceania: "",
  },
  {
    key: "LY",
    name: "Libya",
    name_sc: "利比亚",
    name_tc: "利比亞",
    region: "",
    group: "north_africa",
    asia: "",
    oceania: "",
  },
  {
    key: "MR",
    name: "Mauritania",
    name_sc: "毛里塔尼亚",
    name_tc: "毛里塔尼亞",
    region: "",
    group: "north_africa",
    asia: "",
    oceania: "",
  },
  {
    key: "MA",
    name: "Morocco",
    name_sc: "摩洛哥",
    name_tc: "摩洛哥",
    region: "",
    group: "north_africa",
    asia: "",
    oceania: "",
  },
  {
    key: "SD",
    name: "Sudan",
    name_sc: "苏丹",
    name_tc: "蘇丹",
    region: "",
    group: "north_africa",
    asia: "",
    oceania: "",
  },
  {
    key: "SS",
    name: "South Sudan",
    name_sc: "南苏丹",
    name_tc: "南蘇丹",
    region: "",
    group: "north_africa",
    asia: "",
    oceania: "",
  },
  {
    key: "TN",
    name: "Tunisia",
    name_sc: "突尼斯",
    name_tc: "突尼斯",
    region: "",
    group: "north_africa",
    asia: "",
    oceania: "",
  },
  {
    key: "DJ",
    name: "Djibouti",
    name_sc: "吉布提",
    name_tc: "吉布提",
    region: "",
    group: "north_africa",
    asia: "",
    oceania: "",
  },

  {
    key: "EG",
    name: "Egypt",
    name_sc: "埃及",
    name_tc: "埃及",
    region: "",
    group: "middle_east",
    asia: "",
    oceania: "",
  },
  {
    key: "CY",
    name: "Cyprus",
    name_sc: "塞浦路斯",
    name_tc: "塞浦路斯",
    region: "",
    group: "middle_east",
    asia: "",
    oceania: "",
  },
  {
    key: "IR",
    name: "Iran",
    name_sc: "伊朗",
    name_tc: "伊朗",
    region: "",
    group: "middle_east",
    asia: "",
    oceania: "",
  },
  {
    key: "IQ",
    name: "Iraq",
    name_sc: "伊拉克",
    name_tc: "伊拉克",
    region: "",
    group: "middle_east",
    asia: "",
    oceania: "",
  },
  {
    key: "IL",
    name: "Israel",
    name_sc: "以色列",
    name_tc: "以色列",
    region: "",
    group: "middle_east",
    asia: "",
    oceania: "",
  },
  {
    key: "JO",
    name: "Jordan",
    name_sc: "约旦",
    name_tc: "約旦",
    region: "",
    group: "middle_east",
    asia: "",
    oceania: "",
  },
  {
    key: "KW",
    name: "Kuwait",
    name_sc: "科威特",
    name_tc: "科威特",
    region: "",
    group: "middle_east",
    asia: "",
    oceania: "",
  },
  {
    key: "LB",
    name: "Lebanon",
    name_sc: "黎巴嫩",
    name_tc: "黎巴嫩",
    region: "",
    group: "middle_east",
    asia: "",
    oceania: "",
  },
  {
    key: "OM",
    name: "Oman",
    name_sc: "阿曼",
    name_tc: "阿曼",
    region: "",
    group: "middle_east",
    asia: "",
    oceania: "",
  },
  {
    key: "PS",
    name: "Palestinian Authority",
    name_sc: "巴勒斯坦权力机构",
    name_tc: "巴勒斯坦權力機構",
    region: "",
    group: "middle_east",
    asia: "",
    oceania: "",
  },
  {
    key: "QA",
    name: "Qatar",
    name_sc: "卡塔尔",
    name_tc: "卡塔爾",
    region: "",
    group: "middle_east",
    asia: "",
    oceania: "",
  },
  {
    key: "SA",
    name: "Saudi Arabia",
    name_sc: "沙特阿拉伯",
    name_tc: "沙特阿拉伯",
    region: "",
    group: "middle_east",
    asia: "",
    oceania: "",
  },
  {
    key: "SY",
    name: "Syria",
    name_sc: "叙利亚",
    name_tc: "敘利亞",
    region: "",
    group: "middle_east",
    asia: "",
    oceania: "",
  },
  {
    key: "TR",
    name: "Turkey",
    name_sc: "土耳其",
    name_tc: "土耳其",
    region: "",
    group: "middle_east",
    asia: "",
    oceania: "",
  },
  {
    key: "AE",
    name: "United Arab Emirates",
    name_sc: "阿拉伯联合酋长国",
    name_tc: "阿拉伯聯合酋長國",
    region: "",
    group: "middle_east",
    asia: "",
    oceania: "",
  },
  {
    key: "YE",
    name: "Yemen",
    name_sc: "也门",
    name_tc: "也門",
    region: "",
    group: "middle_east",
    asia: "",
    oceania: "",
  },

  {
    key: "BZ",
    name: "Belize",
    name_sc: "伯利兹",
    name_tc: "伯利茲",
    region: "",
    group: "north_and_central_america",
    asia: "",
    oceania: "",
  },
  {
    key: "CR",
    name: "Costa Rica",
    name_sc: "哥斯达黎加",
    name_tc: "哥斯達黎加",
    region: "",
    group: "north_and_central_america",
    asia: "",
    oceania: "",
  },
  {
    key: "SV",
    name: "El Salvador",
    name_sc: "萨尔瓦多",
    name_tc: "薩爾瓦多",
    region: "",
    group: "north_and_central_america",
    asia: "",
    oceania: "",
  },
  {
    key: "GT",
    name: "Guatemala",
    name_sc: "危地马拉",
    name_tc: "危地馬拉",
    region: "",
    group: "north_and_central_america",
    asia: "",
    oceania: "",
  },
  {
    key: "HN",
    name: "Honduras",
    name_sc: "洪都拉斯",
    name_tc: "洪都拉斯",
    region: "",
    group: "north_and_central_america",
    asia: "",
    oceania: "",
  },
  {
    key: "MX",
    name: "Mexico",
    name_sc: "墨西哥",
    name_tc: "墨西哥",
    region: "",
    group: "north_and_central_america ",
    asia: "",
    oceania: "",
  },
  {
    key: "NI",
    name: "Nicaragua",
    name_sc: "尼加拉瓜",
    name_tc: "尼加拉瓜",
    region: "",
    group: "north_and_central_america",
    asia: "",
    oceania: "",
  },
  {
    key: "PA",
    name: "Panama",
    name_sc: "巴拿马",
    name_tc: "巴拿馬",
    region: "",
    group: "north_and_central_america",
    asia: "",
    oceania: "",
  },
  {
    key: "US",
    name: "United States",
    name_sc: "美国",
    name_tc: "美國",
    region: "",
    group: "north_and_central_america",
    asia: "",
    oceania: "",
  },

  {
    key: "AR",
    name: "Argentina",
    name_sc: "阿根廷",
    name_tc: "阿根廷",
    region: "",
    group: "south_america",
    asia: "",
    oceania: "",
  },
  {
    key: "BO",
    name: "Bolivia",
    name_sc: "玻利维亚",
    name_tc: "玻利維亞",
    region: "",
    group: "south_america",
    asia: "",
    oceania: "",
  },
  {
    key: "BR",
    name: "Brazil",
    name_sc: "巴西",
    name_tc: "巴西",
    region: "",
    group: "south_america",
    asia: "",
    oceania: "",
  },
  {
    key: "CL",
    name: "Chile",
    name_sc: "智利",
    name_tc: "智利",
    region: "",
    group: "south_america",
    asia: "",
    oceania: "",
  },
  {
    key: "CO",
    name: "Colombia",
    name_sc: "哥伦比亚",
    name_tc: "哥倫比亞",
    region: "",
    group: "south_america",
    asia: "",
    oceania: "",
  },
  {
    key: "EC",
    name: "Ecuador",
    name_sc: "厄瓜多尔",
    name_tc: "厄瓜多爾",
    region: "",
    group: "south_america",
    asia: "",
    oceania: "",
  },
  {
    key: "GF",
    name: "French Guiana (département of France)",
    name_sc: "法属圭亚那（法国省）",
    name_tc: "法属圭亚那（法国省）",
    region: "",
    group: "south_america",
    asia: "",
    oceania: "",
  },
  {
    key: "GY",
    name: "Guyana",
    name_sc: "圭亚那",
    name_tc: "圭亞那",
    region: "",
    group: "south_america",
    asia: "",
    oceania: "",
  },
  {
    key: "PY",
    name: "Paraguay",
    name_sc: "巴拉圭",
    name_tc: "巴拉圭",
    region: "",
    group: "south_america",
    asia: "",
    oceania: "",
  },
  {
    key: "PE",
    name: "Peru",
    name_sc: "秘鲁",
    name_tc: "秘魯",
    region: "",
    group: "south_america",
    asia: "",
    oceania: "",
  },
  {
    key: "SR",
    name: "Suriname",
    name_sc: "苏里南",
    name_tc: "蘇里南",
    region: "",
    group: "south_america",
    asia: "",
    oceania: "",
  },
  {
    key: "UY",
    name: "Uruguay",
    name_sc: "乌拉圭",
    name_tc: "烏拉圭",
    region: "",
    group: "south_america",
    asia: "",
    oceania: "",
  },
  {
    key: "VE",
    name: "Venezuela",
    name_sc: "委内瑞拉",
    name_tc: "委內瑞拉",
    region: "",
    group: "south_america",
    asia: "",
    oceania: "",
  },

  {
    key: "CU",
    name: "Cuba",
    name_sc: "古巴",
    name_tc: "古巴",
    region: "",
    group: "caribbean",
    asia: "",
    oceania: "",
  },
  {
    key: "DO",
    name: "Dominican Republic",
    name_sc: "多明尼加共和国",
    name_tc: "多明尼加共和國",
    region: "",
    group: "caribbean",
    asia: "",
    oceania: "",
  },
  {
    key: "HT",
    name: "Haiti",
    name_sc: "海地",
    name_tc: "海地",
    region: "",
    group: "caribbean",
    asia: "",
    oceania: "",
  },

  {
    key: "DK",
    name: "Denmark",
    name_sc: "丹麦",
    name_tc: "丹麥",
    region: "",
    group: "northern_europe",
    asia: "",
    oceania: "",
  },
  {
    key: "EE",
    name: "Estonia",
    name_sc: "爱沙尼亚",
    name_tc: "愛沙尼亞",
    region: "",
    group: "northern_europe",
    asia: "",
    oceania: "",
  },
  {
    key: "FI",
    name: "Finland",
    name_sc: "芬兰",
    name_tc: "芬蘭",
    region: "",
    group: "northern_europe",
    asia: "",
    oceania: "",
  },
  {
    key: "IS",
    name: "Iceland",
    name_sc: "冰岛",
    name_tc: "冰島",
    region: "",
    group: "northern_europe",
    asia: "",
    oceania: "",
  },
  {
    key: "IE",
    name: "Ireland",
    name_sc: "爱尔兰",
    name_tc: "愛爾蘭",
    region: "",
    group: "northern_europe",
    asia: "",
    oceania: "",
  },
  {
    key: "LV",
    name: "Latvia",
    name_sc: "拉脱维亚",
    name_tc: "拉脫維亞",
    region: "",
    group: "northern_europe",
    asia: "",
    oceania: "",
  },
  {
    key: "LT",
    name: "Lithuania",
    name_sc: "立陶宛",
    name_tc: "立陶宛",
    region: "",
    group: "northern_europe",
    asia: "",
    oceania: "",
  },
  {
    key: "NO",
    name: "Norway",
    name_sc: "挪威",
    name_tc: "挪威",
    region: "",
    group: "northern_europe",
    asia: "",
    oceania: "",
  },
  {
    key: "SE",
    name: "Sweden",
    name_sc: "瑞典",
    name_tc: "瑞典",
    region: "",
    group: "northern_europe",
    asia: "",
    oceania: "",
  },
  {
    key: "GB",
    name: "United Kingdom",
    name_sc: "英国",
    name_tc: "英國",
    region: "",
    group: "northern_europe",
    asia: "",
    oceania: "",
  },

  {
    key: "BY",
    name: "Belarus",
    name_sc: "白俄罗斯",
    name_tc: "白俄羅斯",
    region: "",
    group: "eastern_europe",
    asia: "",
    oceania: "",
  },
  {
    key: "BG",
    name: "Bulgaria",
    name_sc: "保加利亚",
    name_tc: "保加利亞",
    region: "",
    group: "eastern_europe",
    asia: "",
    oceania: "",
  },
  {
    key: "CZ",
    name: "Czechia",
    name_sc: "捷克共和国",
    name_tc: "捷克共和國",
    region: "",
    group: "eastern_europe",
    asia: "",
    oceania: "",
  },
  {
    key: "HU",
    name: "Hungary",
    name_sc: "匈牙利",
    name_tc: "匈牙利",
    region: "",
    group: "eastern_europe",
    asia: "",
    oceania: "",
  },
  {
    key: "PL",
    name: "Poland",
    name_sc: "波兰",
    name_tc: "波蘭",
    region: "",
    group: "eastern_europe",
    asia: "",
    oceania: "",
  },
  {
    key: "MD",
    name: "Republic of Moldova",
    name_sc: "摩尔多瓦共和国",
    name_tc: "摩爾多瓦共和國",
    region: "",
    group: "eastern_europe",
    asia: "",
    oceania: "",
  },
  {
    key: "RO",
    name: "Romania",
    name_sc: "罗马尼亚",
    name_tc: "羅馬尼亞",
    region: "",
    group: "eastern_europe",
    asia: "",
    oceania: "",
  },
  {
    key: "RU",
    name: "Russian Federation",
    name_sc: "俄罗斯联邦",
    name_tc: "俄羅斯聯邦",
    region: "",
    group: "eastern_europe",
    asia: "",
    oceania: "",
  },
  {
    key: "SK",
    name: "Slovakia",
    name_sc: "斯洛伐克",
    name_tc: "斯洛伐克",
    region: "",
    group: "eastern_europe",
    asia: "",
    oceania: "",
  },
  {
    key: "UA",
    name: "Ukraine",
    name_sc: "乌克兰",
    name_tc: "烏克蘭",
    region: "",
    group: "eastern_europe",
    asia: "",
    oceania: "",
  },

  {
    key: "AT",
    name: "Austria",
    name_sc: "奥地利",
    name_tc: "奧地利",
    region: "",
    group: "western_europe",
    asia: "",
    oceania: "",
  },
  {
    key: "BE",
    name: "Belgium",
    name_sc: "比利时",
    name_tc: "比利時",
    region: "",
    group: "western_europe",
    asia: "",
    oceania: "",
  },
  {
    key: "FR",
    name: "France",
    name_sc: "法国",
    name_tc: "法國",
    region: "",
    group: "western_europe",
    asia: "",
    oceania: "",
  },
  {
    key: "DE",
    name: "Germany",
    name_sc: "德国",
    name_tc: "德國",
    region: "",
    group: "western_europe",
    asia: "",
    oceania: "",
  },
  {
    key: "LI",
    name: "Liechtenstein",
    name_sc: "列支敦士登",
    name_tc: "列支敦士登",
    region: "",
    group: "western_europe",
    asia: "",
    oceania: "",
  },
  {
    key: "LU",
    name: "Luxembourg",
    name_sc: "卢森堡",
    name_tc: "盧森堡",
    region: "",
    group: "western_europe",
    asia: "",
    oceania: "",
  },
  {
    key: "MC",
    name: "Monaco",
    name_sc: "摩纳哥",
    name_tc: "摩納哥",
    region: "",
    group: "western_europe",
    asia: "",
    oceania: "",
  },
  {
    key: "NL",
    name: "Netherlands",
    name_sc: "荷兰",
    name_tc: "荷蘭",
    region: "",
    group: "western_europe",
    asia: "",
    oceania: "",
  },
  {
    key: "CH",
    name: "Switzerland",
    name_sc: "瑞士",
    name_tc: "瑞士",
    region: "",
    group: "western_europe",
    asia: "",
    oceania: "",
  },

  {
    key: "AL",
    name: "Albania",
    name_sc: "阿尔巴尼亚",
    name_tc: "阿爾巴尼亞",
    region: "",
    group: "southern_europe",
    asia: "",
    oceania: "",
  },
  {
    key: "AD",
    name: "Andorra",
    name_sc: "安道尔",
    name_tc: "安道爾",
    region: "",
    group: "southern_europe",
    asia: "",
    oceania: "",
  },
  {
    key: "BA",
    name: "Bosnia and Herzegovina",
    name_sc: "波斯尼亚和黑塞哥维那",
    name_tc: "波斯尼亞和黑塞哥維那",
    region: "",
    group: "southern_europe",
    asia: "",
    oceania: "",
  },
  {
    key: "HR",
    name: "Croatia",
    name_sc: "克罗地亚",
    name_tc: "克羅地亞",
    region: "",
    group: "southern_europe",
    asia: "",
    oceania: "",
  },
  {
    key: "GR",
    name: "Greece",
    name_sc: "希腊",
    name_tc: "希臘",
    region: "",
    group: "southern_europe",
    asia: "",
    oceania: "",
  },
  {
    key: "IT",
    name: "Italy",
    name_sc: "意大利",
    name_tc: "意大利",
    region: "",
    group: "southern_europe",
    asia: "",
    oceania: "",
  },
  {
    key: "MT",
    name: "Malta",
    name_sc: "马耳他",
    name_tc: "馬耳他",
    region: "",
    group: "southern_europe",
    asia: "",
    oceania: "",
  },
  {
    key: "ME",
    name: "Montenegro",
    name_sc: "黑山",
    name_tc: "黑山",
    region: "",
    group: "southern_europe",
    asia: "",
    oceania: "",
  },
  {
    key: "MK",
    name: "North Macedonia",
    name_sc: "北马其顿",
    name_tc: "北馬其頓",
    region: "",
    group: "southern_europe",
    asia: "",
    oceania: "",
  },
  {
    key: "PT",
    name: "Portugal",
    name_sc: "葡萄牙",
    name_tc: "葡萄牙",
    region: "",
    group: "southern_europe",
    asia: "",
    oceania: "",
  },
  {
    key: "SM",
    name: "San Marino",
    name_sc: "圣马力诺",
    name_tc: "聖馬力諾",
    region: "",
    group: "southern_europe",
    asia: "",
    oceania: "",
  },
  {
    key: "CS",
    name: "Serbia",
    name_sc: "塞尔维亚",
    name_tc: "塞爾維亞",
    region: "",
    group: "southern_europe",
    asia: "",
    oceania: "",
  },
  {
    key: "SI",
    name: "Slovenia",
    name_sc: "斯洛文尼亚",
    name_tc: "斯洛文尼亞",
    region: "",
    group: "southern_europe",
    asia: "",
    oceania: "",
  },
  {
    key: "ES",
    name: "Spain",
    name_sc: "西班牙",
    name_tc: "西班牙",
    region: "",
    group: "southern_europe",
    asia: "",
    oceania: "",
  },
  {
    key: "VA",
    name: "Vatican City",
    name_sc: "梵蒂冈城",
    name_tc: "梵蒂岡城",
    region: "",
    group: "southern_europe",
    asia: "",
    oceania: "",
  },

  {
    key: "BJ",
    name: "Benin",
    name_sc: "贝宁",
    name_tc: "貝寧",
    region: "",
    group: "western_africa",
    asia: "",
    oceania: "",
  },
  {
    key: "BF",
    name: "Burkina Faso",
    name_sc: "布基纳法索",
    name_tc: "布基納法索",
    region: "",
    group: "western_africa",
    asia: "",
    oceania: "",
  },
  {
    key: "CV",
    name: "Cape Verde",
    name_sc: "佛得角",
    name_tc: "佛得角",
    region: "",
    group: "western_africa",
    asia: "",
    oceania: "",
  },
  {
    key: "CI",
    name: "Côte d’Ivoire",
    name_sc: "科特迪瓦",
    name_tc: "科特迪瓦",
    region: "",
    group: "western_africa",
    asia: "",
    oceania: "",
  },
  {
    key: "GM",
    name: "Gambia",
    name_sc: "冈比亚",
    name_tc: "岡比亞",
    region: "",
    group: "western_africa",
    asia: "",
    oceania: "",
  },
  {
    key: "GH",
    name: "Ghana",
    name_sc: "加纳",
    name_tc: "加納",
    region: "",
    group: "western_africa",
    asia: "",
    oceania: "",
  },
  {
    key: "GN",
    name: "Guinea",
    name_sc: "几内亚",
    name_tc: "幾內亞",
    region: "",
    group: "western_africa",
    asia: "",
    oceania: "",
  },
  {
    key: "GW",
    name: "Guinea-Bissau",
    name_sc: "几内亚比绍",
    name_tc: "幾內亞比紹",
    region: "",
    group: "western_africa",
    asia: "",
    oceania: "",
  },
  {
    key: "LR",
    name: "Liberia",
    name_sc: "利比里亚",
    name_tc: "利比里亞",
    region: "",
    group: "western_africa",
    asia: "",
    oceania: "",
  },
  {
    key: "ML",
    name: "Mali",
    name_sc: "马里",
    name_tc: "馬里",
    region: "",
    group: "western_africa",
    asia: "",
    oceania: "",
  },
  {
    key: "MR",
    name: "Mauritania",
    name_sc: "毛里塔尼亚",
    name_tc: "毛里塔尼亞",
    region: "",
    group: "western_africa",
    asia: "",
    oceania: "",
  },
  {
    key: "NE",
    name: "Niger",
    name_sc: "尼日尔",
    name_tc: "尼日爾",
    region: "",
    group: "western_africa",
    asia: "",
    oceania: "",
  },
  {
    key: "NG",
    name: "Nigeria",
    name_sc: "尼日利亚",
    name_tc: "尼日利亞",
    region: "",
    group: "western_africa",
    asia: "",
    oceania: "",
  },
  {
    key: "SN",
    name: "Senegal",
    name_sc: "塞内加尔",
    name_tc: "塞內加爾",
    region: "",
    group: "western_africa",
    asia: "",
    oceania: "",
  },
  {
    key: "SL",
    name: "Sierra Leone",
    name_sc: "塞拉利昂",
    name_tc: "塞拉利昂",
    region: "",
    group: "western_africa",
    asia: "",
    oceania: "",
  },
  {
    key: "TG",
    name: "Togo",
    name_sc: "多哥",
    name_tc: "多哥",
    region: "",
    group: "western_africa",
    asia: "",
    oceania: "",
  },

  {
    key: "BI",
    name: "Burundi",
    name_sc: "布隆迪",
    name_tc: "布隆迪",
    region: "",
    group: "eastern_africa",
    asia: "",
    oceania: "",
  },
  {
    key: "KM",
    name: "Comoros",
    name_sc: "科摩罗",
    name_tc: "科摩羅",
    region: "",
    group: "eastern_africa",
    asia: "",
    oceania: "",
  },
  {
    key: "DJ",
    name: "Djibouti ",
    name_sc: "吉布提",
    name_tc: "吉布提",
    region: "",
    group: "eastern_africa",
    asia: "",
    oceania: "",
  },
  {
    key: "ER",
    name: "Eritrea",
    name_sc: "厄立特里亚",
    name_tc: "厄立特里亞",
    region: "",
    group: "eastern_africa",
    asia: "",
    oceania: "",
  },
  {
    key: "ET",
    name: "Ethiopia",
    name_sc: "埃塞俄比亚",
    name_tc: "埃塞俄比亞",
    region: "",
    group: "eastern_africa",
    asia: "",
    oceania: "",
  },
  {
    key: "KE",
    name: "Kenya",
    name_sc: "肯尼亚",
    name_tc: "肯尼亞",
    region: "",
    group: "eastern_africa",
    asia: "",
    oceania: "",
  },
  {
    key: "MG",
    name: "Madagascar",
    name_sc: "马达加斯加",
    name_tc: "馬達加斯加",
    region: "",
    group: "eastern_africa",
    asia: "",
    oceania: "",
  },
  {
    key: "MW",
    name: "Malawi",
    name_sc: "马拉维",
    name_tc: "馬拉維",
    region: "",
    group: "eastern_africa",
    asia: "",
    oceania: "",
  },
  {
    key: "MU",
    name: "Mauritius",
    name_sc: "毛里求斯",
    name_tc: "毛里求斯",
    region: "",
    group: "eastern_africa",
    asia: "",
    oceania: "",
  },
  {
    key: "MZ",
    name: "Mozambique",
    name_sc: "莫桑比克",
    name_tc: "莫桑比克",
    region: "",
    group: "eastern_africa",
    asia: "",
    oceania: "",
  },
  {
    key: "RW",
    name: "Rwanda",
    name_sc: "卢旺达",
    name_tc: "盧旺達",
    region: "",
    group: "eastern_africa",
    asia: "",
    oceania: "",
  },
  {
    key: "SC",
    name: "Seychelles",
    name_sc: "塞舌尔",
    name_tc: "塞舌爾",
    region: "",
    group: "eastern_africa",
    asia: "",
    oceania: "",
  },
  {
    key: "SO",
    name: "Somalia",
    name_sc: "索马里",
    name_tc: "索馬里",
    region: "",
    group: "eastern_africa",
    asia: "",
    oceania: "",
  },
  {
    key: "SS",
    name: "South Sudan",
    name_sc: "南苏丹",
    name_tc: "南蘇丹",
    region: "",
    group: "eastern_africa",
    asia: "",
    oceania: "",
  },
  {
    key: "TZ",
    name: "Tanzania",
    name_sc: "坦桑尼亚",
    name_tc: "坦桑尼亞",
    region: "",
    group: "eastern_africa",
    asia: "",
    oceania: "",
  },
  {
    key: "UG",
    name: "Uganda",
    name_sc: "乌干达",
    name_tc: "烏干達",
    region: "",
    group: "eastern_africa",
    asia: "",
    oceania: "",
  },
  {
    key: "ZM",
    name: "Zambia",
    name_sc: "赞比亚",
    name_tc: "贊比亞",
    region: "",
    group: "eastern_africa",
    asia: "",
    oceania: "",
  },
  {
    key: "ZI",
    name: "Zimbabwe",
    name_sc: "索马里",
    name_tc: "索馬里",
    region: "",
    group: "eastern_africa",
    asia: "",
    oceania: "",
  },

  {
    key: "AO",
    name: "Angola",
    name_sc: "安哥拉",
    name_tc: "安哥拉",
    region: "",
    group: "central_africa",
    asia: "",
    oceania: "",
  },
  {
    key: "CM",
    name: "Cameroon",
    name_sc: "喀麦隆",
    name_tc: "喀麦隆",
    region: "",
    group: "central_africa",
    asia: "",
    oceania: "",
  },
  {
    key: "CD",
    name: "Democratic Republic of the Congo",
    name_sc: "刚果民主共和国",
    name_tc: "剛果民主共和國",
    region: "",
    group: "central_africa",
    asia: "",
    oceania: "",
  },
  {
    key: "CF",
    name: "Central African Republic",
    name_sc: "中非共和国",
    name_tc: "中非共和國",
    region: "",
    group: "central_africa",
    asia: "",
    oceania: "",
  },
  {
    key: "TD",
    name: "Chad",
    name_sc: "乍得",
    name_tc: "乍得",
    region: "",
    group: "central_africa",
    asia: "",
    oceania: "",
  },
  {
    key: "GQ",
    name: "Equatorial Guinea",
    name_sc: "赤道几内亚",
    name_tc: "赤道幾內亞",
    region: "",
    group: "central_africa",
    asia: "",
    oceania: "",
  },
  {
    key: "GA",
    name: "Gabon",
    name_sc: "加蓬",
    name_tc: "加蓬",
    region: "",
    group: "central_africa",
    asia: "",
    oceania: "",
  },
  {
    key: "CG",
    name: "Republic of the Congo",
    name_sc: "刚果共和国",
    name_tc: "剛果共和國",
    region: "",
    group: "central_africa",
    asia: "",
    oceania: "",
  },
  {
    key: "ST",
    name: "Sao Tome and Principe",
    name_sc: "圣多美和普林西比",
    name_tc: "聖多美和普林西比",
    region: "",
    group: "central_africa",
    asia: "",
    oceania: "",
  },

  {
    key: "BW",
    name: "Botswana",
    name_sc: "博茨瓦纳",
    name_tc: "博茨瓦納",
    region: "",
    group: "southern_africa",
    asia: "",
    oceania: "",
  },
  {
    key: "NA",
    name: "Namibia",
    name_sc: "纳米比亚",
    name_tc: "納米比亞",
    region: "",
    group: "southern_africa",
    asia: "",
    oceania: "",
  },
  {
    key: "ZA",
    name: "South Africa",
    name_sc: "南非",
    name_tc: "南非",
    region: "",
    group: "southern_africa",
    asia: "",
    oceania: "",
  },
  {
    key: "LS",
    name: "Lesotho",
    name_sc: "莱索托",
    name_tc: "萊索托",
    region: "",
    group: "southern_africa",
    asia: "",
    oceania: "",
  },
  {
    key: "SZ",
    name: "Swaziland",
    name_sc: "斯威士兰",
    name_tc: "斯威士蘭",
    region: "",
    group: "southern_africa",
    asia: "",
    oceania: "",
  },
];
let siteKeyAll = {
  mu: {
    sc: "'the Republic of Mauritius'",
    tc: "'the Republic of Mauritius'",
    en: "'the Republic of Mauritius'",
    kr: "'the Republic of Mauritius'",
    jp: "'the Republic of Mauritius'",
    th: "'the Republic of Mauritius'",
    vn: "'the Republic of Mauritius'",
    es: "'the Republic of Mauritius'",
    ar: "'the Republic of Mauritius'",
  },
  default: {
    sc: "'the Republic of Vanuatu'",
    tc: "'the Republic of Vanuatu'",
    en: "'the Republic of Vanuatu'",
    kr: "'the Republic of Vanuatu'",
    jp: "'the Republic of Vanuatu'",
    th: "'the Republic of Vanuatu'",
    vn: "'the Republic of Vanuatu'",
    es: "'the Republic of Vanuatu'",
    ar: "'the Republic of Vanuatu'",
  },
  sc: {
    sc: "'the Republic of Seychelles'",
    tc: "'the Republic of Seychelles'",
    en: "'the Republic of Seychelles'",
    kr: "'the Republic of Seychelles'",
    jp: "'the Republic of Seychelles'",
    th: "'the Republic of Seychelles'",
    vn: "'the Republic of Seychelles'",
    es: "'the Republic of Seychelles'",
    ar: "'the Republic of Seychelles'",
  },
};
let checkCountryGroupValidationList = [
  "northern_europe",
  "eastern_europe",
  "western_europe",
  "southern_europe",
];
let checkCountryKeyValidationList = ["HK"];

export const decodeCountry = (countryCode: String) => {
  let countryInfo = {
    key: "undefine" as string,
    name: "undefine" as string,
    name_sc: "undefine" as string,
    name_tc: "undefine" as string,
    region: "undefine" as string,
    group: "undefine" as string,
    asia: "undefine" as string,
    oceania: "undefine" as string,
  };

  let lang = "sc";
  let siteKey = "";
  let groupEU = ["gb", "au"];
  let countryName = "mifid";
  let countryGroup = "default";

  for (const item of countryList) {
    if (item.key === countryCode) {
      countryInfo = item;
    }
  }
  let matches = window.location.href.match(
    /(\/(sc|tc|en|kr|th|vn|jp|ar|pt|es)$)|(\/(sc|tc|en|kr|th|vn|jp|ar|pt|es)\/)/
  );
  
  if (matches && matches.length > 0) {    
    lang = matches[0].replace("/", "");
  }
  if(window.location.pathname == "/kol"){
    lang = "en"
  }

  siteKey = siteKeyAll["default"][lang];

  if (groupEU.includes(countryInfo.group.toLowerCase())) {
    countryName = countryInfo.group;
  }

  if (checkCountryGroupValidationList.includes(countryInfo.group)) {
    countryGroup = "europe";
  } else if (checkCountryKeyValidationList.includes(countryInfo.key)) {
    countryGroup = "hk";
  } else if (countryInfo.key === "LOCAL") {
    countryGroup = "LOCAL";
  } else if (countryInfo.key === "CN") {
    countryGroup = "CN";
  }

  return getDisclaimerSubtext(
    countryInfo,
    countryName.toLowerCase(),
    siteKey,
    lang,
    countryGroup
  );
};

export const getDisclaimerSubtext = (
  country: any,
  countryName: string,
  siteKey: string,
  lang: string,
  countryGroup: string
) => {
  let legislativeList = {
    from: {
      mifid: {
        sc: `受MiFID II立法框架管制的${country.name_sc}辖区`,
        tc: `受MiFID II立法框架管制的${country.name_tc}轄區`,
        en: `a jurisdiction: ${country.name} regulated under the MiFID II legislative framework`,
        kr: `관할권: ${country.name} MiFID II 입법 체계`,
        jp: `管轄: ${country.name} MiFID II の法的枠組み`,
        th: `เขตอำนาจศาล: ${country.name} ควบคุมภายใต้กรอบกฎหมาย MiFID II`,
        vn: `khu vực tài phán: ${country.name} được quy định theo khuôn khổ pháp lý MiFID II`,
        es: `una jurisdicción: ${country.name} regulada bajo el marco legislativo MiFID II`,
        ar: `سلطة قضائية:  ${country.name} منظمة بموجب الإطار التشريعي MiFID II`,
      },
      gb: {
        sc: `${country.name_sc}`,
        tc: `${country.name_tc}`,
        en: `${country.name}`,
        kr: `${country.name}`,
        jp: `${country.name}`,
        th: `${country.name}`,
        vn: `${country.name}`,
        es: `${country.name}`,
        ar: `${country.name}`,
      },
      au: {
        sc: `澳大利亚`,
        tc: `澳大利亞`,
        en: `${country.name}`,
        kr: `호주에 따라 규제되는 ${country.name}`,
        jp: `オーストラリア`,
        th: `ออสเตรเลีย`,
        vn: `Úc`,
        es: `${country.name}`,
        ar: `المملكة المتحدة`,
      },
      hk: {
        sc: `澳大利亚`,
        tc: `澳大利亞`,
        en: `${country.name}`,
        kr: `호주에 따라 규제되는 ${country.name}`,
        jp: `オーストラリア`,
        th: `ออสเตรเลีย`,
        vn: `Úc`,
        es: `${country.name}`,
        ar: `المملكة المتحدة`,
      },
    },
    services: {
      mifid: {
        sc: `MiFID II立法框架`,
        tc: `MiFID II立法框架`,
        en: `MiFID II legislative framework`,
        kr: `MiFID II 입법 체계`,
        jp: `MiFID II の法的枠組み`,
        th: `กรอบกฎหมายของ MiFID II`,
        vn: `khuôn khổ pháp luật MiFID II`,
        es: `marco legislativo MiFID II`,
        ar: `إطار العمل التشريعي MiFID II`,
      },
      gb: {
        sc: `英国金融监管框架`,
        tc: `英國金融監管框架`,
        en: `United Kingdom's financial regulatory framework`,
        kr: `영국의 금융 규제 프레임워크`,
        jp: `英国の金融規制の枠組み`,
        th: `การเงินของสหราชอาณาจักร กรอบการกำกับดูแล`,
        vn: `cơ quan tài chính của Vương quốc Anh khung pháp lý`,
        es: `el marco regulatorio financiero del Reino Unido`,
        ar: `إطار العمل التنظيمي المالي في أستراليا`,
      },
      au: {
        sc: `澳大利亚金融监管框架`,
        tc: `澳大利亞金融監管框架`,
        en: `Australia's financial regulatory framework`,
        kr: `호주의 금융 규제 프레임워크`,
        jp: `オーストラリアの金融規制の枠組み`,
        th: `กรอบการกำกับดูแลทางการเงินของออสเตรเลีย`,
        vn: `Khung pháp lý tài chính của Úc`,
        es: `marco regulatorio financiero de Australia`,
        ar: `الشؤون المالية في المملكة المتحدة الإطار التنظيمي`,
      },
      hk: {
        sc: `澳大利亚金融监管框架`,
        tc: `澳大利亞金融監管框架`,
        en: `Australia's financial regulatory framework`,
        kr: `호주의 금융 규제 프레임워크`,
        jp: `オーストラリアの金融規制の枠組み`,
        th: `กรอบการกำกับดูแลทางการเงินของออสเตรเลีย`,
        vn: `Khung pháp lý tài chính của Úc`,
        es: `marco regulatorio financiero de Australia`,
        ar: `الشؤون المالية في المملكة المتحدة الإطار التنظيمي`,
      },
    },
  };

  let content = {
    default: {
      sc: `（我们检测到您可能来自我们不提供服务的司法管辖区：${country.name_sc}。您的权利将不受您所在的司法管辖区中的法律和法规的保护。您应受 ${siteKey} 的法律管辖，您在此承认遵守 ${siteKey} 法律。）`,
      tc: `（我們檢測到您可能來自我們不提供服務的司法管轄區：${country.name_sc}。您的權利將不受您所在的司法管轄區中的法律和法規的保護。您應受 ${siteKey} 的法律管轄，您在此承認遵守 ${siteKey} 法律。）`,
      en: `(We have detected that you may be accessing this website from a jurisdiction: ${country.name} where we do not provide services. Your legal rights shall not be protected under the laws and regulations of your jurisdiction. You shall be governed by the laws of ${siteKey}, and you hereby acknowledge in complying with the laws of ${siteKey}.)`,
      kr: `(우리는 귀하가 서비스를 제공하지 않는 관할권 ${country.name} 에서 이 웹사이트에 액세스할 수 있음을 감지했습니다. 귀하의 법적 권리는 귀하의 관할권의 법률 및 규정에 따라 보호되지 않습니다. 귀하는 ${siteKey}, 귀하는 이로써 ${siteKey}.)`,
      jp: `(このホームページをご覧になっていらっしゃるお客様は、私たちがサービスを提供していない国・地域からの閲覧である可能性を検知しました。${country.name} お客様の権利は、お客様の居住されている国・地域の法律や規制によって保護されません。 お客様は ${siteKey} の法律に準拠するものとし、お客様はここに ${siteKey} の法律に準拠するリスクを認めるものとします）`,
      th: `(เราตรวจพบว่าคุณอาจเข้าถึงเว็บไซต์นี้จากเขตอำนาจศาล: ${country.name} ซึ่งเราไม่ได้ให้บริการ สิทธิ์ตามกฎหมายของคุณจะไม่ได้รับการคุ้มครองภายใต้กฎหมายและระเบียบข้อบังคับในเขตอำนาจศาลของคุณ คุณจะอยู่ภายใต้กฎหมายของ ${siteKey} ปฏิบัติตามกฎหมายของ ${siteKey}.)`,
      vn: `(Chúng tôi đã phát hiện ra rằng bạn có thể đang truy cập trang web này từ khu vực tài phán:  ${country.name}  nơi chúng tôi không cung cấp dịch vụ. Các quyền hợp pháp của bạn sẽ không được bảo vệ theo luật và quy định tại khu vực tài phán của bạn. Bạn sẽ chịu sự điều chỉnh của luật pháp của ${siteKey}, và bạn xác nhận tại đây tuân thủ luật pháp của ${siteKey}.)`,
      es: `(Hemos detectado que puede estar accediendo a este sitio web desde una jurisdicción  ${country.name}  donde no brindamos servicios. Sus derechos legales no estarán protegidos por las leyes y reglamentos de su jurisdicción. Se regirá por las leyes de ${siteKey}, y por la presente reconoce en cumplir con las leyes de ${siteKey}.)`,
      ar: `(لقد اكتشفنا أنك ربما تدخل إلى موقع الويب هذا من دائرة قضائية:  ${country.name}  حيث لا نقدم خدمات. لن تكون حقوقك القانونية محمية بموجب قوانين ولوائح نطاق السلطة التي تتبعها. يجب أن تخضع لقوانين ${siteKey} ليتم إدراجه هنا. بالنسبة إلى, وأنت تقر بموجب هذا في الامتثال لقوانين ${siteKey}.)`,
    },
    europe: {
      sc: `（我们检测到您可能正在从【${legislativeList["from"]["mifid"][lang]}】访问此网站。我们的服务不在【${legislativeList["services"][countryName][lang]}】的范围内。您的权利将不受【${legislativeList["services"][countryName][lang]}】的保护，并且您应受${siteKey}法律约束。)`,
      tc: `（我們檢測到您可能正在從【${legislativeList["from"]["mifid"][lang]}】訪問此網站。我們的服務不在【${legislativeList["services"][countryName][lang]}】的範圍內。您的權利將不受【${legislativeList["services"][countryName][lang]}】的保護，並且您應受${siteKey}法律約束。)`,
      en: `(We have detected that you may be accessing this website from [${legislativeList["from"]["mifid"][lang]}]. Our services are not within the territorial limits of the [${legislativeList["services"][countryName][lang]}]. Your rights will not be protected under the [${legislativeList["services"][countryName][lang]}] and you shall be governed by the laws of ${siteKey}.)`,
      kr: `（우리는 귀하가 [${legislativeList["from"]["mifid"][lang]}] 에서 이 웹사이트에 액세스할 수 있음을 감지했습니다. 우리 서비스는 [${legislativeList["services"][countryName][lang]}]. 귀하의 권리는 [${legislativeList["services"][countryName][lang]}]에 따라 보호되지 않으며 ${siteKey} 의 법률에 의해 규율됩니다.）`,
      jp: `（[${legislativeList["from"]["mifid"][lang]}] からこのウェブサイトにアクセスしている可能性があることを検出しました。当社のサービスは、[${legislativeList["services"][countryName][lang]}]. あなたの権利は [${legislativeList["services"][countryName][lang]}] の下では保護されず、${siteKey}の法律に準拠するものとします .)`,
      th: `(เราตรวจพบว่าคุณอาจเข้าถึงเว็บไซต์นี้จาก [${legislativeList["from"]["mifid"][lang]}] บริการของเราไม่อยู่ในขอบเขตอาณาเขตของ [${legislativeList["services"][countryName][lang]}] สิทธิ์ของคุณจะไม่ได้รับการคุ้มครองภายใต้ [${legislativeList["services"][countryName][lang]}] และคุณจะต้องอยู่ภายใต้กฎหมายของ ${siteKey}.)`,
      vn: `(Chúng tôi đã phát hiện ra rằng bạn có thể đang truy cập trang web này từ [${legislativeList["from"]["mifid"][lang]}]. Các dịch vụ của chúng tôi không nằm trong giới hạn lãnh thổ của [${legislativeList["services"][countryName][lang]}]. Các quyền của bạn sẽ không được bảo vệ theo [${legislativeList["services"][countryName][lang]}] và bạn sẽ chịu sự điều chỉnh của pháp luật của ${siteKey}.)`,
      es: `(Hemos detectado que puede estar accediendo a este sitio web desde [${legislativeList["from"]["mifid"][lang]}]. Nuestros servicios no se encuentran dentro de los límites territoriales del [${legislativeList["services"][countryName][lang]}]. Sus derechos no estarán protegidos por el [${legislativeList["services"][countryName][lang]}] y se regirá por las leyes de${siteKey}.)`,
      ar: `(لقد اكتشفنا أنك ربما تقوم بالوصول إلى هذا الموقع من [${legislativeList["from"]["mifid"][lang]}]. لا تقع خدماتنا ضمن الحدود الإقليمية لـ [${legislativeList["services"][countryName][lang]}]. لن تتم حماية حقوقك بموجب [${legislativeList["services"][countryName][lang]}] وستكون محكومًا بقوانين ${siteKey}.)`,
    },
    hk: {
      sc: `（我们检测到您可能来自香港。您的权利将不受您所在的司法管辖区中的法律和法规的保护。您应受${siteKey}的法律管辖，您在此承认遵守${siteKey}法律。)`,
      tc: `（我們檢測到您可能來自香港。您的權利將不受您所在的司法管轄區中的法律和法規的保護。您應受${siteKey}的法律管轄，您在此承認遵守${siteKey}法律。)`,
      en: `(We have detected that you may be accessing this website from Hong Kong. Your legal rights shall not be protected under the laws and regulations of your jurisdiction. You shall be governed by the laws of ${siteKey}, and you hereby acknowledge in complying with the laws of ${siteKey}.)`,
      kr: `（귀하가 홍콩에서 이 웹사이트에 액세스할 수 있음을 감지했습니다. 귀하의 법적 권리는 귀하의 관할권의 법률 및 규정에 따라 보호되지 않습니다. 귀하는 ${siteKey} 법률이 적용됩니다. 이로써 귀하는 ${siteKey}.）`,
      jp: `（あなたが香港からこのウェブサイトにアクセスしている可能性があることを検出しました。あなたの法的権利は、あなたの管轄区域の法律および規制の下では保護されないものとします。あなたは、${siteKey} 管轄区域。)`,
      th: `(เราตรวจพบว่าคุณอาจเข้าถึงเว็บไซต์นี้จากฮ่องกง สิทธิ์ทางกฎหมายของคุณจะไม่ได้รับการคุ้มครองภายใต้กฎหมายและข้อบังคับในเขตอำนาจศาลของคุณ คุณจะอยู่ภายใต้กฎหมายของ ${siteKey} และคุณรับทราบในการปฏิบัติตามกฎหมายของ ${siteKey} ここに挿入します。.)`,
      vn: `(Chúng tôi đã phát hiện ra rằng bạn có thể đang truy cập trang web này từ Hồng Kông. Các quyền hợp pháp của bạn sẽ không được bảo vệ theo luật pháp và quy định của khu vực tài phán của bạn. Bạn sẽ chịu sự điều chỉnh của luật pháp của ${siteKey}, và bạn xác nhận tuân thủ luật pháp của ${siteKey}.)`,
      es: "",
      ar: "",
    },
    LOCAL: {
      sc: `（我们检测到您可能来自我们不提供服务的司法管辖区：<span>TESTING</span>。您的权利将不受您所在的司法管辖区中的法律和法规的保护。您应受 ${siteKey} 的法律管辖，您在此承认遵守 ${siteKey} 法律的风险。）`,
      tc: `（我们检测到您可能来自我们不提供服务的司法管辖区：<span>TESTING</span>。您的权利将不受您所在的司法管辖区中的法律和法规的保护。您应受 ${siteKey} 的法律管辖，您在此承认遵守 ${siteKey} 法律的风险。）`,
      en: `（我们检测到您可能来自我们不提供服务的司法管辖区：<span>TESTING</span>。您的权利将不受您所在的司法管辖区中的法律和法规的保护。您应受 ${siteKey} 的法律管辖，您在此承认遵守 ${siteKey} 法律的风险。）`,
      kr: `（我们检测到您可能来自我们不提供服务的司法管辖区：<span>TESTING</span>。您的权利将不受您所在的司法管辖区中的法律和法规的保护。您应受 ${siteKey} 的法律管辖，您在此承认遵守 ${siteKey} 法律的风险。）`,
      jp: `（我们检测到您可能来自我们不提供服务的司法管辖区：<span>TESTING</span>。您的权利将不受您所在的司法管辖区中的法律和法规的保护。您应受 ${siteKey} 的法律管辖，您在此承认遵守 ${siteKey} 法律的风险。）`,
      th: `（我们检测到您可能来自我们不提供服务的司法管辖区：<span>TESTING</span>。您的权利将不受您所在的司法管辖区中的法律和法规的保护。您应受 ${siteKey} 的法律管辖，您在此承认遵守 ${siteKey} 法律的风险。）`,
      vn: `（我们检测到您可能来自我们不提供服务的司法管辖区：<span>TESTING</span>。您的权利将不受您所在的司法管辖区中的法律和法规的保护。您应受 ${siteKey} 的法律管辖，您在此承认遵守 ${siteKey} 法律的风险。）`,
      es: "",
      ar: "",
    },
    CN: {
      sc: `（您的权利将不受您所在的司法管辖区中的法律和法规的保护。您应受 ${siteKey} 的法律管辖，您在此承认遵守 ${siteKey} 法律的风险。）`,
      tc: `（您的權利將不受您所在的司法管轄區中的法律和法規的保護。您應受 ${siteKey} 的法律管轄，您在此承認遵守 ${siteKey} 法律的风险。）`,
      en: `(Your legal rights shall not be protected under the laws and regulations of your jurisdiction. You shall be governed by the laws of ${siteKey}, and you hereby acknowledge in complying with the laws of ${siteKey}.)`,
      kr: `(에서 이 웹사이트에 액세스할 수 있음을 감지했습니다. 귀하의 법적 권리는 귀하의 관할권의 법률 및 규정에 따라 보호되지 않습니다. 귀하는 ${siteKey}, 귀하는 이로써 ${siteKey}.)`,
      jp: `(お客様の権利は、お客様の居住されている国・地域の法律や規制によって保護されません。 お客様は ${siteKey} の法律に準拠するものとし、お客様はここに ${siteKey} の法律に準拠するリスクを認めるものとします）`,
      th: `(ซึ่งเราไม่ได้ให้บริการ สิทธิ์ตามกฎหมายของคุณจะไม่ได้รับการคุ้มครองภายใต้กฎหมายและระเบียบข้อบังคับในเขตอำนาจศาลของคุณ คุณจะอยู่ภายใต้กฎหมายของ ${siteKey} ปฏิบัติตามกฎหมายของ ${siteKey}.)`,
      vn: `(nơi chúng tôi không cung cấp dịch vụ. Các quyền hợp pháp của bạn sẽ không được bảo vệ theo luật và quy định tại khu vực tài phán của bạn. Bạn sẽ chịu sự điều chỉnh của luật pháp của ${siteKey}, và bạn xác nhận tại đây tuân thủ luật pháp của ${siteKey}.)`,
      es: `(donde no brindamos servicios. Sus derechos legales no estarán protegidos por las leyes y reglamentos de su jurisdicción. Se regirá por las leyes de ${siteKey}, y por la presente reconoce en cumplir con las leyes de ${siteKey}.)`,
      ar: `(حيث لا نقدم خدمات. لن تكون حقوقك القانونية محمية بموجب قوانين ولوائح نطاق السلطة التي تتبعها. يجب أن تخضع لقوانين ${siteKey} ليتم إدراجه هنا. بالنسبة إلى, وأنت تقر بموجب هذا في الامتثال لقوانين ${siteKey}.)`,
    },
    MM: {
      sc: "",
      tc: "",
      en: "",
      kr: "",
      jp: "",
      th: "",
      vn: "",
      es: "",
      ar: "",
    },
  };

  return content[countryGroup][lang];
};
