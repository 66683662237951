<template>
    <Teleport to="head">
        <title>
            {{ $t('common.tdk.title') }}
        </title>
        <meta name="description" :content="$t('common.tdk.description')">
        <meta name="keyword" :content="$t('common.tdk.keyword')">
    </Teleport>
    <div class="jpDooTravel">
        <Header></Header>
        <WhereToTravel></WhereToTravel>
        <Timer></Timer>
        <HowToJoin></HowToJoin>
        <RewardLevels></RewardLevels>
        <IbForm></IbForm>
        <TncTabs></TncTabs>
        <Footer :withWebView="true" :countryCode="'jp'" />
    </div>
</template>

  
<script lang="ts">
import Header from '@/components/jpdootravel/Header.vue';
import WhereToTravel from '@/components/jpdootravel/WhereToTravel.vue';
import Timer from '@/components/jpdootravel/Timer.vue';
import HowToJoin from '@/components/jpdootravel/HowToJoin.vue';
import RewardLevels from '@/components/jpdootravel/RewardLevels.vue';
import Footer from "@/components/jpdootravel/Footer.vue";
import IbForm from '@/components/jpdootravel/IbForm.vue';
import TncTabs from '@/components/jpdootravel/TncTabs.vue';


export default {
    name: 'jpDooTravel',
    components: {
        Header,
        WhereToTravel,
        Timer,
        HowToJoin,
        RewardLevels,
        Footer,
        IbForm,
        TncTabs
    },
}
</script>

<style lang="scss" scoped>

@media screen and (min-width: 1001px) {
    :deep(.mobile) {
        display: none !important;
    }
}

:deep(.web) {
    display: none !important;

    @media screen and (min-width: 1001px) {
        display: initial !important;
    }
}

main {
    max-width: 2560px !important;
}

.jpDooTravel {
    background-color: #e5eff8;
    font-family: NotoSansCJKJP ;
}

</style>
