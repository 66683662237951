<template>
    <TimeoutMessage :timeEnded="this.timeEnded" @close-pop-up="this.closePopUp()"></TimeoutMessage>
    <section class="bg content">
        <p :class="this.$i18n.locale" class="title" v-t="'jpDooTravel.joinNow'"></p>
        <div class="wrapper">
            <div class="container-line">
                <img 
                    :src="`${this.imageUrl}airplane-divider-${isWebView ? 'big' : 'small'}.png`"
                    alt="plane">
            </div>
        </div>
        <p :class="this.$i18n.locale" class="sub-title" v-t="'jpDooTravel.timeLeft'"></p>
        <div class="container-songkran">
            <div id="countdown">
                <ul style="position:relative;">
                    <li><span class="time" id="days"></span> <span :class="this.$i18n.locale" class="timer-txt days"
                            v-t="'jpDooTravel.days'"></span> </li>
                    <li class="colon"><span class="colons">:</span></li>
                    <li><span id="hours" class="time"></span><span :class="this.$i18n.locale" class="timer-txt hours"
                            v-t="'jpDooTravel.hrs'"></span></li>
                    <li class="colon"><span class="colons">:</span></li>
                    <li><span id="minutes" class="time"></span><span :class="this.$i18n.locale" class="timer-txt minutes"
                            v-t="'jpDooTravel.min'"></span></li>
                    <li class="colon"><span class="colons">:</span></li>
                    <li><span id="seconds" class="time"></span><span :class="this.$i18n.locale" class="timer-txt seconds"
                            v-t="'jpDooTravel.sec'"></span>
                    </li>
                </ul>
            </div>
        </div>
    </section>
</template>

<script lang="ts">
import { ref,  onMounted } from 'vue'
import TimeoutMessage from './TimeoutMessage.vue'

export default {
    components: {
        TimeoutMessage
    },
    data() {
        return {
            imageUrl: "https://cdn.doogroup.com/jp-doo-travel/deco/",
            timeEnded: false
        };
    },
    setup() {
        const isWebView = ref(window.innerWidth > 1000);

        const calculateScreenWidth = async () => {
            isWebView.value = window.innerWidth > 1000;
        }
        onMounted(() => {
            window.addEventListener("resize", () => { calculateScreenWidth() });
        });
        return {
            isWebView
        }
    },
    methods: {
        async countdownFunction() {
            //time in milliseconds
            const second = 1000,
                minute = second * 60,
                hour = minute * 60,
                day = hour * 24;

            let countDown = 1734274800000;
            const prop = this;

            const x = setInterval(function () {
                const now = Date.parse(new Date().toUTCString());
                const distance = countDown - now;

                if (distance <= 0) {
                    prop.timeEnded = true;
                    document.getElementById("days").innerText = "0";
                    document.getElementById("hours").innerText = "0";
                    document.getElementById("minutes").innerText = "0";
                    document.getElementById("seconds").innerText = "0";
                    clearInterval(x);
                } else {
                    document.getElementById("days").innerText = String(Math.floor(distance / (day))).padStart(2, "0"),
                    document.getElementById("hours").innerText = String(Math.floor((distance % (day)) / (hour))).padStart(2, "0"),
                    document.getElementById("minutes").innerText = String(Math.floor((distance % (hour)) / (minute))).padStart(2, "0"),
                    document.getElementById("seconds").innerText = String(Math.floor((distance % (minute)) / second)).padStart(2, "0");
                }
            }, 1000)
        },
        closePopUp() {
            this.timeEnded = false
        }
    },
    created() {
        this.countdownFunction()
    }
}



</script>

<style lang="scss" scoped >
p,
h1 {
    margin: 0;
}

ul {
    padding-left: 0;
    margin: 32px;
    
    @media screen and (max-width: 1000px) {
        margin: 22px 0 32px;
    }
}

.bg {
    background-image: linear-gradient(to bottom, #e7f2fb, #fff);
}

.content {
    display: flex;
    flex-direction: column;

    .title {
        margin-top: 64px;
        font-size: 36px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: #de2026;
        margin-bottom: 4px;

        @media screen and (max-width:1000px) {
            font-size: 24px;
        }
    }

    .sub-title {
        font-size: 24px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: #36383B;
        margin-top: 12px;

        @media screen and (max-width: 1000px) {
            margin-top: 24px;
            font-size: 16px;
        }

    }


    .wrapper {
        display: flex;
        justify-content: center;

        .container-line {
            display: flex;
            justify-content: center;
            align-items: center;
            padding-left: 13px;
            width: 262px;
        }
    }


}

/* general styling */
:root {
    --smaller: .75;
}

.container-songkran {
    position: relative;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 35px;
}



ul {
    display: flex;
    align-items: baseline
}

.time {
    position: relative;
    width: 150px;
    height: 200px;
    padding: 40px 10.5px;

    @media screen and (max-width: 1000px) {
        width: 70px;
        height: 112px;
        padding: 20px 10.5px;
    }
}

.time::before {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 10px;
    border: 1.5px solid transparent;
    background: linear-gradient(148deg, #f2f1c5 12%, #cab79c 97%) border-box;
    -webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
    box-shadow: 0 6px 14px 0 #c0bdbd;

}

li {

    display: inline-block;
    list-style-type: none;


    .timer-txt {
        font-size: 32px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: #000;
        position: absolute;
        bottom: 17px;

        @media only screen and (max-width: 1000px) {
            font-size: 12px;
        }
    }

    .days {
        left: 60px;
        bottom: 46px;

        @media only screen and (max-width: 1000px) {
            left: 30px;
            bottom: 24px;
        }
    }

    .hours {
        left: 288px;
        bottom: 46px;

        @media only screen and (max-width: 1000px) {
            left: 124px;
            bottom: 24px;
        }
    }

    .minutes {
        left: 520px;
        bottom: 46px;

        @media only screen and (max-width: 1000px) {
            left: 217px;
            bottom: 24px;
        }
    }

    .seconds {
        left: 704px;
        right: 10px;
        bottom: 46px;

        @media only screen and (max-width: 1000px) {
            left: 288px;
            right: 5px;
            bottom: 24px;
        }
    }

    @supports (-webkit-touch-callout: none) {

        .hours {
            left: 105px;
            right: 192px;
        }

        .minutes {
            left: 188px;
            right: 100px;
        }

        .seconds {
            left: 260px;
            right: -5px;
        }

    }

}

.colon {
    border: none;
    border-radius: 0;
    margin: 0 32px 0;
    font-size: 42px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #e1c89c !important;
    align-self: center;
    margin-top: -16px;

    @media only screen and (max-width: 1000px) {
        margin: 0 4px;
    }
}

li span {
    display: block;
    font-size: 64px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #d91d22;
    text-decoration: none;

    @media only screen and (max-width: 1000px) {
        font-size: 42px;
    }
}

.colons {
    color: #e1c89c
}
</style>