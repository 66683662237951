<template>
    <section id='whereToTravel'>
        <div class="section-box">
            <p class='title' v-t="'jpDooTravel.whereTitle'"></p>
            <p class='desc' v-t="'jpDooTravel.whereDesc'"></p>
            <div class="country-list">
                <div v-for="(country) in this.countryList" class="country">
                    <img :src="this.imageUrl + country + '.png'" :alt="`${country} image`" />
                    <span class='desc' v-t="`jpDooTravel.${country}`"></span>
                </div>
            </div>
        </div>
    </section>
</template>

<script lang="ts">
import { ref } from 'vue'
const countryList = ref([
    'th',
    'hk',
    'aus',
    'uae'
]);

export default {
    data() {
        return {
            imageUrl: "https://cdn.doogroup.com/jp-doo-travel/country/",
            countryList
        };
    },
};
</script>

<style lang="scss" scoped>
section {
    font-family: NotoSansCJKJP;
    margin-bottom: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-image: linear-gradient(to bottom, #c2dcf5, #c2dcf5, rgba(229, 239, 248, 0) 67%);

    .section-box {
        max-width: 1360px;
        justify-self: center;

        @media screen and (max-width: 1000px) {
            max-width: 1000px;
        }

        .title {
            margin-top: 32px;
            font-size: 36px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: center;
            color: #e51c1c;
            margin-bottom: 0;

            @media screen and (max-width: 1000px) {
                font-size: 24px;
            }
        }
    
        .desc {
            margin-top: 8px;
            font-size: 24px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: center;
            color: #000;
            margin-bottom: 32px;

            @media screen and (max-width: 1000px) {
                font-size: 16px;
                margin-top: 4px;
            }
        }
    
        .country-list {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            flex-wrap: wrap;
            // gap: 16px;
    
            .country {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                flex-basis: 25%;
    
                @media only screen and (max-width: 1000px) {
                    flex-basis: 50%;
                }
    
                img {
                    width: 100%;
                    max-width: 336px;
                    max-height: 454px;
    
                    @media only screen and (max-width: 1000px) {
                        width: 153.6px;
                        height: 210.4px;
                    }
                }
    
                .desc {
                    font-family: NotoSansCJKJP;
                    font-size: 24px;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: normal;
                    letter-spacing: normal;
                    text-align: center;
                    color: #000;

                    @media only screen and (max-width: 1000px) {
                        font-size: 16px;
                    }
                }
            }
        }
    }

}
</style>