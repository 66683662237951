<template>
    <div class="selectedPrize">
        <template v-if="(ib4Store.selectedPrize as GiftType).type == 0">
            <img :src="imageUrl + 'level_coin.png'" :alt="(ib4Store.selectedPrize as GiftType).code as string"
                class="image" />
            <p>{{ (ib4Store.selectedPrize as GiftType).name }}</p>
        </template>
        <template v-else>
            <template
                v-if="(ib4Store.selectedPrize as GiftType).code == '1005' || (ib4Store.selectedPrize as GiftType).code == '1007' || (ib4Store.selectedPrize as GiftType).code == '1009'">
                <img :src="imageUrl + 'level_' + ib4Store.agentLevel + '_2' + '.png'"
                    :alt="(ib4Store.selectedPrize as GiftType).code as string" />
                <p v-html="$t('ib5.level.100' + ib4Store.agentLevel + '_2')"></p>
            </template>
            <template v-else>
                <img :src="imageUrl + 'level_' + ib4Store.agentLevel + '.png'"
                    :alt="(ib4Store.selectedPrize as GiftType).code as string" />
                <p v-html="$t('ib5.level.100' + ib4Store.agentLevel)"></p>
            </template>
        </template>
    </div>
</template>

<script lang="ts">
import { useIB4Store } from '@/stores/ib4-0'

interface GiftType {
    type: number | string;
    code: number | string;
    name: string;
}

export default {
    setup() {
        const ib4Store = useIB4Store()
        return {
            ib4Store
        }
    },

    data() {
        return {
            imageUrl: "http://doo-prime-static.oss-cn-hongkong.aliyuncs.com/ib_5/images/",
        }
    },
    methods: {
    },

    mounted() {
    },
}
</script>

<style lang="scss">
.selectedPrize {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: left;
    padding: 11.2px;
    gap: 6px;
    border-radius: 8px;
    border: solid 1px #ee0a24;
    background-color: #fee7ea;
    margin-bottom: 24px;
    padding: 12px 28px;

    img {
        width: 76px;
        height: auto;
        margin-right: 6px;
        display: inline-block;
    }

    p {
        font-size: 16px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.25;
        letter-spacing: normal;
        text-align: left;
        color: #14142a;
        display: inline-block;
        max-width: 175px;
        width: 100%;
        margin: 0;
    }
}
</style>