import api from "./config";

const baseUrl = "/auth";

export const signIn = async (token: string) => {
  try {
    const payload = await api({
      method: "GET",
      url: `${baseUrl}/redirectSignIn`,
      params: { token },
      withCredentials: true,
    });
    return payload;
  } catch (e) {
    return e;
  }
};

export const checkSession = async () => {
  try {
    const payload = await api({
      method: "GET",
      url: `${baseUrl}/session`,
      withCredentials: true,
    });

    return payload;
  } catch (e) {
    return e;
  }
};

export const logout = async () => {
  try {
    const payload = await api({
      method: "GET",
      url: `${baseUrl}/logout`,
      withCredentials: true,
    });
    return payload;
  } catch (e) {
    return e;
  }
};

export const login = async (data: any) => {
  try {
    const payload = await api({
      method: "post",
      url: `${baseUrl}/login`,
      data: data,
    });
    return payload;
  } catch (e) {
    return e;
  }
};

export const verificationCodeImg = async () => {
  try {
    const payload = await api({
      method: "GET",
      url: `${baseUrl}/verificationCodeImg`,
    });
    return payload;
  } catch (e) {
    return e;
  }
};

export const crm = async (token: string) => {
  try {
    const payload = await api({
      method: "GET",
      url: `https://v5-crm-ib-dev.finpoints.tech/v2/user/auth/checkSignIn`,
      headers: {
        JSID: token,
      },
    });
    return payload;
  } catch (e) {
    return e;
  }
};
