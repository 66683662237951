<template>
    <section class="bg">
        <div class="content">
            <p :class="this.$i18n.locale" class="title" v-t="'jpDooTravel.rewardLevel'"></p>
            <div class="wrapper">
                <div class="container-line">
                    <img 
                        :src="`${this.imageUrl}deco/airplane-divider-${isWebView ? 'big' : 'small'}.png`"
                        alt="plane">
                </div>
            </div>
            <p :class="this.$i18n.locale" class="sub-title" v-t="'jpDooTravel.rewardLevelDesc'"></p>

            <div class="level-row" v-if="isWebView">
                <div class="level" v-for="(level, index) in this.levelList" :key="index">
                    <img class="ticket-bg" :src="`${this.imageUrl}ticket/ticket-bg-plain.png`" alt="level ticket bg" />
                    <div class="ticket-info">
                        <p class="level-title" v-t="`jpDooTravel.level${index + 1}`"></p>
                        <div class="ticket-top">                            
                            <img class="destination-ticket" :src="`${this.imageUrl}ticket/${level.destination}.png`" alt="level ticket" />
                            <p class="destination" v-t="`jpDooTravel.destination${index + 1}`"></p>
                            <p class="travel-period" v-t="`jpDooTravel.${level.period}`"></p>
                        </div>
                        <div class="ticket-bottom">
                            <p class="amount" v-t="level.netDeposit"></p>
                            <p class="item" v-t="`jpDooTravel.netDepositUsd`"></p>
                            <p class="amount" v-t="level.lot"></p>
                            <p class="item" v-t="`jpDooTravel.lot`"></p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="level-column" v-else>
                <div :class="this.currentLevel === index + 1 ? '' : 'collapse'" class="level" v-for="(level, index) in this.levelList" :key="index">
                    <img class="ticket-bg" :src="`${this.imageUrl}ticket/ticket-bg-plain.png`" alt="level ticket bg" />
                    <a :id="`ticket-info-${index + 1}`" class="ticket-info" @click="selectLevel(index + 1)">
                        <p :class="this.currentLevel === index + 1 ? 'red' : 'white'" class="level-title" v-t="`jpDooTravel.level${index + 1}`"></p>
                        <div class="ticket-top">                            
                            <img class="destination-ticket" :src="`${this.imageUrl}ticket/${level.destination}.png`" alt="level ticket" />
                            <p class="destination" v-t="`jpDooTravel.destination${index + 1}`"></p>
                            <p class="travel-period" v-t="`jpDooTravel.${level.period}`"></p>
                        </div>
                        <div class="ticket-bottom">
                            <p class="amount" v-t="level.netDeposit"></p>
                            <p class="item" v-t="`jpDooTravel.netDepositUsd`"></p>
                            <p class="amount" v-t="level.lot"></p>
                            <p class="item" v-t="`jpDooTravel.lot`"></p>
                        </div>
                    </a>
                </div>
            </div>

            <a class="joinNowBtn" :id="isWebView ? 'button_joinnow3' : 'button_joinnow6'" href="https://my.dooprime.com/ja/login?sl=1" v-t="'jpDooTravel.joinNow'"></a>
        </div>

    </section>
</template>

<script lang="ts">
import { ref,  onMounted } from 'vue'

const levelList = ref([
    {period: "3day2night", netDeposit: '180,000', lot: '2,800', destination: 'th'},
    {period: "4day3night", netDeposit: '250,000', lot: '3,800', destination: 'hk'},
    {period: "5day4night", netDeposit: '320,000', lot: '5,000', destination: 'au'},
    {period: "5day4night", netDeposit: '400,000', lot: '6,000', destination: 'uae'},
]);
const currentLevel = ref(4)

export default {
    data() {
        return {
            imageUrl: "https://cdn.doogroup.com/jp-doo-travel/",
            levelList,
            currentLevel
        };
    },
    setup() {
        const isWebView = ref(window.innerWidth > 1000);

        const calculateScreenWidth = async () => {
            isWebView.value = window.innerWidth > 1000;
        }

        onMounted(() => {
            window.addEventListener("resize", () => { calculateScreenWidth() });
        });
        return {
            isWebView
        }
    },
    methods: {
        selectLevel(level: number) {
            if (currentLevel.value === level) {
                currentLevel.value = 0;

                return;
            }
            currentLevel.value = level
        },
    }
}
</script>

<style lang="scss" scoped>
p,
h1 {
    margin: 0;
}

ul {

    padding-left: 0;
}

.bg {
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;

    .content {
        padding-bottom: 45px !important;
        max-width: 1360px;
        display: flex;
        flex-direction: column;
        padding: 0 16px;
        
        
        @media screen and (max-width:1000px) {
            max-width: 1000px;
        }

        .title {
            padding-top: 32px;
            font-size: 36px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: center;
            color: #de2026;
            margin-bottom: 4px;

            @media screen and (max-width:1000px) {
                font-size: 24px;
            }
        }

        .sub-title {
            font-size: 24px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: center;
            color: #36383B;
            margin-top: 12px;
            margin-bottom: 32px;

            @media only screen and (max-width: 1000px) {
                font-size: 16px;
                margin-top: 24px;
            }
        }


        .wrapper {
            display: flex;
            justify-content: center;

            .container-line {
                display: flex;
                justify-content: center;
                align-items: center;
                padding-left: 13px;
                width: 262px;
            }
        }

        .level-row {
            display: flex;
            flex-direction: row-reverse;
            
            .level {
                position: relative;

                .ticket-bg {
                    position: relative;
                    width: 100%;
                    max-width: 340px;
                    height: 440px;
                }

                .ticket-info {
                    position: absolute;
                    padding: 12px;
                    max-width: 340px;
                    height: 440px;
                    width: 100%;
                    top: 0;
                    left: 0;
                    display: flex;
                    flex-direction: column;

                    .level-title {
                        font-size: 20px;
                        font-weight: bold;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: normal;
                        letter-spacing: normal;
                        text-align: center;
                        color: #fff;
                        padding: 8px 0;
                        background-image: linear-gradient(to bottom, #f46661, #e51c1c);
                        border-radius: 8px 8px 0 0;
                        width: 100%;
                    }

                    .ticket-top {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        flex: 1;
                        margin-top: 12px;

                        .destination-ticket {
                            width: 70%;
                            max-width: 260px;
                            margin-top: 8px;
                        }

                        .destination {
                            margin-top: 6px;
                            font-size: 16px;
                            font-weight: normal;
                            font-stretch: normal;
                            font-style: normal;
                            line-height: normal;
                            letter-spacing: normal;
                            text-align: center;
                            color: #000;
                        }

                        .travel-period {
                            font-size: 16px;
                            font-weight: bold;
                            font-stretch: normal;
                            font-style: normal;
                            line-height: normal;
                            letter-spacing: normal;
                            text-align: center;
                            color: #000;
                        }

                    }

                    .ticket-bottom {
                        margin-top: 20px;
                        margin-bottom: 16px;

                        .amount {
                            font-size: 20px;
                            font-weight: bold;
                            font-stretch: normal;
                            font-style: normal;
                            line-height: normal;
                            letter-spacing: normal;
                            text-align: center;
                            color: #000;
                            margin-bottom: 8px;
                        }

                        .item {
                            font-size: 14px;
                            font-weight: normal;
                            font-stretch: normal;
                            font-style: normal;
                            line-height: normal;
                            letter-spacing: normal;
                            text-align: center;
                            color: #000;
                            margin-bottom: 8px;
                        }
                    }
                }
            }
        }

        .level-column {
            display: flex;
            flex-direction: column-reverse;
            gap: 42px;

            .level {
                position: relative;
                transition: height 0.2s ease-out;
                overflow: hidden;
                margin-bottom: -40px;
                // aspect-ratio: 340 / 440;

                .ticket-bg {
                    position: relative;
                    width: 100%;
                    max-width: 600px;
                }

                .ticket-info {
                    position: absolute;
                    padding: 14px 4.2%;
                    max-width: 600px;
                    aspect-ratio: 340 / 440;
                    width: 100%;
                    top: 0;
                    left: 0;
                    display: flex;
                    flex-direction: column;

                    .level-title {
                        font-size: 20px;
                        font-weight: bold;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: normal;
                        letter-spacing: normal;
                        text-align: center;
                        padding: 8px 0;
                        border-radius: 8px 8px 0 0;
                        width: 100%;
                    }
                    
                    .red {
                        color: #fff;
                        background-image: linear-gradient(to bottom, #f46661, #e51c1c);
                        border-radius: 8px 8px 0 0;
                    }

                    .white {
                        border-radius: 8px;
                        background-color: #fff;
                        color: #e51c1c;
                        box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.1);
                    }

                    .ticket-top {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        flex: 1;
                        margin-top: 12px;

                        .destination-ticket {
                            width: 80%;
                            max-width: 460px;
                            margin-top: 8px;
                        }

                        .destination {
                            margin-top: 4%;
                            font-size: 16px;
                            font-weight: normal;
                            font-stretch: normal;
                            font-style: normal;
                            line-height: normal;
                            letter-spacing: normal;
                            text-align: center;
                            color: #000;
                        }

                        .travel-period {
                            font-size: 16px;
                            font-weight: bold;
                            font-stretch: normal;
                            font-style: normal;
                            line-height: normal;
                            letter-spacing: normal;
                            text-align: center;
                            color: #000;
                        }

                    }

                    .ticket-bottom {
                        margin-top: 20px;
                        margin-bottom: 16px;
                        margin-bottom: 10%;


                        @media only screen and (max-width: 1000px) {
                            margin-bottom: 15%;
                        }

                        @media only screen and (max-width: 520px) {
                            margin-bottom: 13%;
                        }

                        @media only screen and (max-width: 520px) {
                            margin-bottom: 11%;
                        }

                        @media only screen and (max-width: 460px) {
                            margin-bottom: 8%;
                        }

                        @media only screen and (max-width: 390px) {
                            margin-bottom: 5%;
                        }


                        .amount {
                            font-size: 20px;
                            font-weight: bold;
                            font-stretch: normal;
                            font-style: normal;
                            line-height: normal;
                            letter-spacing: normal;
                            text-align: center;
                            color: #000;
                            margin-bottom: 8px;
                        }

                        .item {
                            font-size: 14px;
                            font-weight: normal;
                            font-stretch: normal;
                            font-style: normal;
                            line-height: normal;
                            letter-spacing: normal;
                            text-align: center;
                            color: #000;
                            margin-bottom: 8px;
                        }
                    }
                }
            }

            .collapse {
                height: 60px;
            }
        }

        .joinNowBtn {
            font-size: 20px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #fff;
            background-image: linear-gradient(to bottom, #f46661 0%, #e51c1c);
            padding: 8px 40px;
            width: fit-content;
            margin-top: 56px;
            display: flex;
            border-radius: 4px;
            align-self: center;

            @media only screen and (max-width: 1000px) {
                margin-top: 76px;
            }
        }
    }
}
</style>