import { log } from "console";
import api from "./config";

const baseUrl = "/mail";

export const mailer = async (formValues: {
  url: string;
  surname: string;
  name: string;
  email: string;
  number: string;
  comment: string;
}) => {
  try {
    const payload = await api({
      method: "POST",
      url: `${baseUrl}/send`,
      data: {
        url: formValues.url,
        surname: formValues.surname,
        name: formValues.name,
        email: formValues.email,
        number: formValues.number,
        comment: formValues.comment,
      },
    });

    return payload;
  } catch (e) {
    return e;
  }
};
