<template>
  <div :id="'tab_' + id" class="event_container scroll-top">
    <div class="event_header">
      <img class="bg_mask" :src="imageUrl + 'assist_bg.png'" />
      <h1>{{ title }}</h1>

      <EventDetailBtn :href="btnDetailLink" :btnId="btnId" />
    </div>
    <div class="event_description">
      <slot></slot>
    </div>
  </div>
</template>

<script lang="ts">
import EventDetailBtn from '@/components/button/EventDetailBtn.vue';

export default {
  data() {
    return {
      imageUrl: "https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/ib_event/ib6/images/",
    }
  },
  props: {
    id: String,
    title: String,
    btnDetailLink: String,
    btnId: {
      default: 'subpagerule_1'
    }
  },
  components: {
    EventDetailBtn
  },
};
</script>

<style lang="scss">
.scroll-top {
  scroll-margin-top: 80px;
}

.event_container {

  margin: 24px 0;

  .bg_mask {
    height: 48px;
    width: 100%;
    position: absolute;
    z-index: -1;
    left: 0;
  }

  .event_header {
    position: relative;
    z-index: 9;
    height: 68px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 16px 28px 16px;
    border-radius: 12px;
    box-shadow: 0 2px 8px 0 rgba(151, 151, 151, 0.3);
    border: solid 1px #fff;
    background-image: linear-gradient(6deg,
        #e3cfa1 50%,
        #fff9eb 100%,
        #fff3d8 62%,
        #dfc894 5%);

    p {
      font-size: 14px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #000;
      margin: 0;
    }

    h1 {
      font-family: AlibabaPuHuiTi;
      font-size: 20px;
      font-weight: 900;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #644415;
      margin: 0;
    }
  }

  .event_description {
    position: relative;
    z-index: 10;
    margin-top: -20px;
    border-radius: 12px;
    height: 100%;
    width: 100%;
    border: solid 1px #fff;
    background-color: #fff;
  }
}
</style>
