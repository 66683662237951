<template>
    <div id="event_time_table">
        <div class="title_content">
            <p class="title">{{ $t(`${eventDataList.eventTitle}`) }}</p>
            <p v-if="eventStatus == 'status2' || eventStatus == 'status1'" class="sub_title">{{
                $t(`${eventDataList.eventPeriodDate}`) }}</p>
            <p v-if="eventStatus == 'status2' || eventStatus == 'status1'" class="content">{{ $t('ib6.eventDate.countryUTC')
            }}</p>
        </div>
        <div v-if="eventStatus == 'status2' || eventStatus == 'status1'" class="count_down_wrapper">
            <div class="title">
                <img :src="imageUrl + 'time.png'">
                <p>{{ $t('ib6.eventDate.countDownTitle') }}</p>
            </div>
            <!-- <CountdownNoFlip endDate="2024-05-08T07:30:38.179Z" :show="`show`" /> -->
            <CountdownNoFlip :deadlineAP="dateToCount" :countDownType="countDownType" />
        </div>
        <div v-if="eventStatus == 'status3'" class="wrapper">
            <p class="title">{{ $t(`${eventDataList.pendingTitle}`) }}</p>
            <p class="content">{{ $t(`${eventDataList.pendingContent}`) }}</p>
        </div>
        <div v-if="eventStatus == 'status4' || eventStatus == 'status5'" class="wrapper">
            <p class="title">{{ $t(`${eventDataList.eventEndTitle}`) }}</p>
            <p class="sub_title">{{ $t(`${eventDataList.eventEndSubTitle}`) }}</p>
            <p class="content">{{ $t(`${eventDataList.eventEndContent}`) }}</p>
        </div>
        <div class="event_timeline">
            <template v-for="n in 4">
                <div :class="`event_timeline_container${countDownType}`">
                    <img :src="imageUrl + 'progress_indicator.png'" alt="">
                    <div class="wrapper">
                        <!-- <span v-if="n == 1 && countDownType == 'prize'" class="event_timeline_child_title">{{
                            $t(`${eventDataList.eventTimelineStatus}`)
                        }}</span> -->
                        <!-- <span v-if="n == 1 && countDownType == 'prize'" class="event_timeline_child_content">{{
                            $t(`${eventDataList.eventPeriodInGold}`)
                        }}</span> -->
                        <span class="event_timeline_title">{{ $t(`${eventDataList.eventTimelineStatus}${n}`) }}</span>
                        <span class="event_timeline_content">{{ $t(`${eventDataList.eventPeriod}${n}`) }}</span>
                    </div>
                </div>
            </template>
            <div :class="`line${countDownType}`"></div>
        </div>
    </div>
</template>

<script lang="ts">
import CountdownNoFlip from "@/components/CountdownNoFlip.vue";

export default {
    components: {
        CountdownNoFlip,
    },
    props: {
        dateToCount: {
            default: '',
        },
        eventStatus: {
            default: '',
        },
        eventDataList: {
            default: {
                eventTitle: 'ib6.eventDate.title',
                eventPeriodDate: 'ib6.eventDate.period',
                eventTimelineStatus: 'ib6.eventDate.timelineStatus',
                eventPeriodInGold: 'ib6.eventDate.periodInGold',
                eventPeriod: 'ib6.eventDate.period',
                pendingTitle: 'ib6.eventDate.pendingTitle',
                pendingContent: 'ib6.eventDate.pendingContent',
                eventEndTitle: 'ib6.eventDate.eventEndTitle',
                eventEndSubTitle: 'ib6.eventDate.eventEndSubTitle',
                eventEndContent: 'ib6.eventDate.eventEndContent',

            }
        },
        countDownType: {
            default: '',
        }
    },
    data() {
        return {
            imageUrl: "https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/ib_event/ib6/images/",
            list: {
                test: this.$t('ib6.eventDate.title'),
            }
        }
    },
}
</script>

<style lang="scss">
#event_time_table {
    margin: 48px auto 0;

    p {
        margin: 0;
    }

    .title_content {


        .title {
            font-family: MicrosoftYaHeiSemibold;
            font-size: 24px;
            font-weight: 900;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: center;
            color: #000;
        }

        .sub_title {
            margin: 22px auto 8px;
            font-size: 14px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.57;
            letter-spacing: normal;
            text-align: center;
            color: #252525;
        }

        .content {
            margin-bottom: 14px;
            font-size: 12px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.83;
            letter-spacing: normal;
            text-align: center;
            color: #979797;
        }

    }

    .count_down_wrapper {
        margin: auto;
        max-width: 289px;
        border-radius: 12px;
        -webkit-backdrop-filter: blur(4px);
        backdrop-filter: blur(4px);
        box-shadow: 0 2px 8px 0 rgba(151, 151, 151, 0.2);
        border: 1px solid transparent;
        background: linear-gradient(#fff, #fff) padding-box, linear-gradient(#d91d22, rgba(217, 29, 34, 0)) border-box;
        border-image-slice: 1;

        .title {
            margin: 16px 0 0;
            display: flex;
            justify-content: center;
            align-items: center;

            img {
                max-width: 42px;
                margin-right: 4px;
            }

            p {
                font-size: 16px;
                font-weight: 600;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                text-align: center;
                color: #252525;
            }
        }
    }

    .wrapper {

        .title {
            margin: 16px auto;
            font-size: 16px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: center;
            color: #14142a;
        }

        .sub_title {
            margin-bottom: 4px;
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: center;
            color: #ee0a24;
        }

        .content {
            font-size: 24px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: center;
            color: #ee0a24;
        }
    }

    .event_timeline {
        max-width: 250px;
        width: 100%;
        margin: 27px auto 43px;
        position: relative;
        // @media screen and (max-width: 400px) {
        //     margin: auto 42px auto 51px;
        // }

        p {
            margin: 0;
            font-size: 12px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.5;
            letter-spacing: normal;
            text-align: center;
            color: #878793;
        }



        .lineprize {
            margin: 5px 0 0 1px;
            display: block;
            height: 200px;
            width: 1px;
            background-image: linear-gradient(to bottom, rgba(217, 29, 34, 0.25) 100%, rgba(217, 29, 34, 0.25) 100%);
            position: absolute;
            top: 0;
            left: 0;
        }

        .lineannual {
            margin: 5px 0 0 1px;
            display: block;
            height: 200px;
            width: 1px;
            background-image: linear-gradient(to bottom, rgba(217, 29, 34, 0.25) 100%, rgba(217, 29, 34, 0.25) 100%);
            position: absolute;
            top: 0;
            left: 0;
        }

        div:nth-child(5)>.wrapper {
            margin-bottom: 8px;
        }

        div:nth-child(5)>img {
            top: -14px;
        }

        .event_timeline_containerprize {
            display: flex;

            img {
                display: block;
                width: 16.5px;
                position: relative;
                left: -7px;
                top: -23px;
                object-fit: contain;

            }

            &:first-child {
                img {
                    top: -28px;
                }
            }

            .wrapper {
                display: block;
                margin-bottom: 24px;

                .event_timeline_title {
                    display: block;
                    margin: 0 0 4px 0;
                    font-family: MicrosoftYaHeiSemibold;
                    font-size: 14px;
                    font-weight: 600;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.29;
                    letter-spacing: normal;
                    text-align: left;
                    color: #252525;
                }

                .event_timeline_child_title {
                    display: block;
                    margin: 0 0 4px 0;
                    font-family: MicrosoftYaHeiSemibold;
                    font-size: 14px;
                    font-weight: 600;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.29;
                    letter-spacing: normal;
                    text-align: left;
                    color: #252525;
                }

                .event_timeline_content {
                    display: block;
                    font-size: 12px;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.5;
                    letter-spacing: normal;
                    text-align: left;
                    color: #252525;
                }

                .event_timeline_child_content {
                    margin-bottom: 8px;
                    display: block;
                    font-size: 12px;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.5;
                    letter-spacing: normal;
                    text-align: left;
                    color: #252525;
                }
            }
        }

        .event_timeline_containerannual {
            display: flex;

            img {
                display: block;
                width: 16.5px;
                position: relative;
                left: -7px;
                top: -23px;
                object-fit: contain;

            }


            .wrapper {
                display: block;
                margin-bottom: 24px;

                .event_timeline_title {
                    display: block;
                    margin: 0 0 4px 0;
                    font-family: MicrosoftYaHeiSemibold;
                    font-size: 14px;
                    font-weight: 600;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.29;
                    letter-spacing: normal;
                    text-align: left;
                    color: #252525;
                }

                .event_timeline_child_title {
                    display: block;
                    margin: 0 0 4px 0;
                    font-family: MicrosoftYaHeiSemibold;
                    font-size: 14px;
                    font-weight: 600;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.29;
                    letter-spacing: normal;
                    text-align: left;
                    color: #252525;
                }

                .event_timeline_content {
                    display: block;
                    font-size: 12px;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.5;
                    letter-spacing: normal;
                    text-align: left;
                    color: #252525;
                }

                .event_timeline_child_content {
                    margin-bottom: 8px;
                    display: block;
                    font-size: 12px;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.5;
                    letter-spacing: normal;
                    text-align: left;
                    color: #252525;
                }
            }
        }
    }
}
</style>
