<template>
    <div id="claimedPrizeRecord" v-if="agentStore.isLogin == true">
        <Navbar />
        <div class="container">
            <Back :pageTitle="this.$t('ib6.claimedPrizeRecord.title')" />
            <div class="reward_type">
                <button v-for="(n, index) in 1" :key="n" :id="'reward_id' + n" :class="{ active: activeTab === index }"
                    @click="setActiveTab(index)">
                    <p>{{ $t(`ib6.claimedPrizeRecord.tab${n}`) }}</p>
                </button>
            </div>
            <div class="no_record" v-if="isNull">
                <img :src="imageUrl + 'no_record.png'" />
                <p>{{ $t('ib6.claimedPrizeRecord.noRecord') }}</p>
            </div>
            <div v-for="(value, key, index) in responseData" :key="index" v-if="!isNull">
                <div class="reward_box">
                    <div class="reward_description1">
                        <div class="reward_category">
                            <img :src="imageUrl + `${reward(value.giftType).path}.png`" />
                            <div>
                                <p>{{ $t(`ib6.claimedPrizeRecord.giftType.${reward(value.giftType).giftType}`) }}</p>
                                <p>USD {{ value.giftName }}</p>
                            </div>
                        </div>
                        <div class="reward_status">
                            <p>{{ $t(`ib6.claimedPrizeRecord.deposited`) }}</p>
                        </div>
                    </div>
                    <div class="reward_description2">
                        <div class="reward_label">
                            <p>{{ $t(`ib6.claimedPrizeRecord.collectTime`) }}</p>
                            <p>{{ $t(`ib6.claimedPrizeRecord.collectNumber`) }}</p>
                            <p>{{ $t(`ib6.claimedPrizeRecord.rewardSource`) }}</p>
                        </div>
                        <div class="reward_detail">
                            <p>{{ value.createTime }}</p>
                            <p>{{ value.trackingNumber }}</p>
                            <p>{{ $t(`ib6.claimedPrizeRecord.${value.eventType}`) }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { getClaimedPrizeRecord } from "@/api/getResult";
import Navbar from '@/components/Header/Navbar.vue'
import Back from "@/components/button/Back.vue";
import { claimedPrizeRecord } from "@/modal/ClaimedPrizeRecord";
import { useAgentStore } from "@/stores/user";
import test from "node:test";

export default {
    name: "ClaimedPrizeRecord",
    data() {
        return {
            activeTab: 0,
            imageUrl:
                "https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/ib_event/ib6/images/",
            responseData: [],
            isNull: true,
            agentStore: useAgentStore(),
        };
    },
    components: {
        Back,
        Navbar,
    },
    methods: {
        checkLoginStatus() {
            if (!this.agentStore.isLogin) {
                this.$router.replace('/');
            }
        },
        async getClaimedPrizeRecordList() {
            const response = await getClaimedPrizeRecord();
            const data: claimedPrizeRecord = response.data.data;
            const claimedData = Object.fromEntries(Object.entries(data).filter(([key, value]) =>
                value !== null && key !== 'apiActivityRedPacket'));

            if (this.isEmpty(claimedData)) {

                this.isNull = true;
            }
            else {
                this.isNull = false;
            }

            this.responseData = claimedData;
        },
        async getClaimedRedPacketList() {
            const response = await getClaimedPrizeRecord();
            const data: claimedPrizeRecord = response.data.data;
            const claimedData = Object.fromEntries(Object.entries(data).filter(([key, value]) =>
                value !== null && key === 'apiActivityRedPacket'))

            if (this.isEmpty(claimedData)) {

                this.isNull = true;
            }
            else {
                this.isNull = false;
            }

            this.responseData = claimedData.apiActivityRedPacket;
        },
        setActiveTab(index: number) {
            this.activeTab = index;
            switch (index) {
                case 0:
                    return this.getClaimedPrizeRecordList();
                case 1:
                    return this.getClaimedRedPacketList();
                default:
                    break;
            }
        },
        reward(item: string) {
            let path = '';
            let giftType = '';

            switch (item) {
                case '0':
                    path = 'level_reward';
                    giftType = 'level';
                    break;
                case '1':
                    path = 'gold_reward';
                    giftType = 'gold';
                    break;
                case '2':
                    path = 'ranking_reward';
                    giftType = 'eventInGold';
                    break;
                case '3':
                    path = 'ranking_reward';
                    giftType = 'tradingVolume';
                    break;
                case '4':
                    path = 'ranking_reward';
                    giftType = 'mib';
                    break;
                case '5':
                    path = 'redpacket_reward';
                    giftType = 'festival';
                    break;
                case '6':
                    path = 'redpacket_reward';
                    giftType = 'dob';
                    break;
                default:
                    path = '';
                    giftType = '';
                    break;
            }

            return { path, giftType }
        },
        isEmpty(data: any) {
            return Object.keys(data).length === 0;
        },
    },
    mounted() {
        this.checkLoginStatus();
        this.getClaimedPrizeRecordList();
    }
};
</script>

<style lang="scss">
#claimedPrizeRecord {
    background-color: #FFFFFF;
    height: 100vh;

    p {
        margin: 0;
    }

    header {
        .top {
            background-color: #FFFFFF;
            position: relative;
        }
    }

    .no_record {
        padding-top: 120px;
        padding-bottom: 325px;
        margin: auto;

        img {
            width: 144px;
            height: 144px;
            margin-bottom: 8px;
        }

        p {
            margin: 0;
            font-size: 14px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: center;
            color: #979797;
        }
    }

    .reward_type {
        margin: 16px 0 20px 0;
        display: flex;
        align-items: center;
        width: 100%;
        height: 32px;
        border-radius: 6px;
        border-style: solid;
        border-width: 2px;
        border-image-source: linear-gradient(96deg, #e0e0e0 6%, #fff 26%, #e0e0e0 53%, #fff 72%, #e0e0e0 87%);
        border-image-slice: 1;
        background-image: linear-gradient(to bottom, #fff, #fff), linear-gradient(96deg, #e0e0e0 6%, #fff 26%, #e0e0e0 53%, #fff 72%, #e0e0e0 87%);
        background-origin: border-box;
        background-clip: content-box, border-box;

        button {
            padding: 0;
            height: 32px;
            width: 100%;
            background: transparent;
            border-color: transparent;
            border-radius: 6px;
            cursor: pointer;
            padding: 6px 0;
        }

        p {
            margin: 0;
            margin-top: -2px;
            font-size: 14px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: center;
            color: #000;
        }

        .active {
            background-color: #d91d22;

            p {
                color: #fff;
            }
        }
    }

    .reward_box {
        // height: 135px;
        padding: 16px;
        border-radius: 8px;
        background-color: #f3f5f6;
        margin-bottom: 16px;

        .reward_description1 {
            margin-bottom: 16px;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .reward_category {
                display: flex;
                align-items: center;

                img {
                    width: 39px;
                    height: 39px;
                }

                p {
                    margin: 0;
                    margin-left: 9px;
                    font-size: 14px;
                    font-weight: 600;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: normal;
                    letter-spacing: normal;
                    text-align: left;
                    color: #252525;
                }
            }

            .reward_status {
                padding: 2px 8px;
                background-color: rgba(255, 223, 223, 0.96);

                p {
                    margin: 0;
                    font-size: 14px;
                    font-weight: 600;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: normal;
                    letter-spacing: normal;
                    text-align: left;
                    color: #d91d22;
                }


            }

        }

        .reward_description2 {
            display: flex;
            align-items: center;
            justify-content: start;

            .reward_label {
                margin-right: 16px;

                p {
                    margin-bottom: 8px;
                    font-size: 14px;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: normal;
                    letter-spacing: normal;
                    text-align: left;
                    color: #979797;

                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }

            .reward_detail {

                p {
                    margin-bottom: 8px;
                    font-size: 14px;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: normal;
                    letter-spacing: normal;
                    text-align: left;
                    color: #3a424b;

                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
        }

    }
}
</style>