<template>
    <section class="bg">
        <div class="content">
            <p :class="this.$i18n.locale" class="title" v-t="'jpDooTravel.ibFormTitle'"></p>
            <p :class="this.$i18n.locale" class="desc" v-html="this.$t('jpDooTravel.ibFormDesc')" ></p>
        </div>
        <Form></Form>
    </section>
</template>

<script lang="ts">
import { ref,  onMounted } from 'vue'
import Form from './Form.vue';

export default {
    data() {
        return {
            imageUrl: "https://cdn.doogroup.com/jp-doo-travel/",
        };
    },
    components: {
        Form
    },
    setup() {
        const isWebView = ref(window.innerWidth > 1000);

        const calculateScreenWidth = async () => {
            isWebView.value = window.innerWidth > 1000;
        }

        onMounted(() => {
            window.addEventListener("resize", () => { calculateScreenWidth() });
        });
        return {
            isWebView
        }
    },
}
</script>

<style lang="scss" scoped>
p,
h1 {
    margin: 0;
}

ul {

    padding-left: 0;
}

.bg {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    background-image: url('https://cdn.doogroup.com/jp-doo-travel/ib-form-bg.png');
    background-size: cover;
    background-repeat: no-repeat;
    padding: 64px 16px;

    @media only screen and (max-width: 1000px) {
        padding: 32px 16px;
    }


    .content {
        max-width: 1360px;
        display: flex;
        flex-direction: column;
        
        .title {
            font-size: 36px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: center;
            color: #e51c1c;

            @media only screen and (max-width: 1000px) {
                font-size: 24px;
            }
        }

        .desc {
            margin-top: 8px;
            font-size: 24px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: center;
            color: #000;

            @media only screen and (max-width: 1000px) {
                font-size: 14px;
            }
        }
    }
}

</style>