<template>
  <div class="eventThreeContainer" id="annual">
    <div v-for="(n, index) in 3" class="mg-btm-24">
      <img v-if="n == 1" :src="imageUrl + `annual_prize_${n}1.png`" class="prize" />
      <img v-else :src="imageUrl + `annual_prize_${n}.png`" class="prize" />
      <div class="eventThreeContentContainer">
        <p class="completeTask">
          {{ $t(`ib6.eventDescription.complete`) }}
        </p>
        <div class="eventThreeContent">
          <div class="eventThreeContent1">
            <p>{{ $t(`ib6.eventAnnual.deposit`) }}</p>
            <h4>{{ $t(`ib6.eventAnnual.depositAmount${n}`) }}</h4>
          </div>

          <img :src="imageUrl + 'add.svg'" class="add" />

          <div class="eventThreeContent1">
            <p>{{ $t(`ib6.eventAnnual.trading`) }}</p>
            <h4>{{ $t(`ib6.eventAnnual.tradingAmount${n}`) }}</h4>
          </div>

          <img :src="imageUrl + 'arrow_right.svg'" class="arrow_right" />
          <img :src="imageUrl + `trophy_${trophyType[index]}.png`" class="trophy" />
        </div>
        <p class="sub_title">{{ $t(`ib6.eventAnnual.validCustomerTitle1`) }} <span>{{
          $t(`ib6.eventAnnual.validCustomerAmount${n}`) }}</span> {{
    $t(`ib6.eventAnnual.validCustomerTitle2`) }}</p>
      </div>
    </div>
  </div>
</template>
  
<script lang="ts">


export default {
  data() {
    return {
      imageUrl:
        "https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/ib_event/ib6/images/",
      trophyType: ["gold", "sliver", "bronze"],
    };
  },
};
</script>
  
<style lang="scss">
.eventThreeContainer {
  .mg-btm-24 {
    margin-bottom: 24px;
  }

  .prize {
    scale: 1.1;
    width: 267px;
  }

  .eventThreeContentContainer {
    width: 100%;
    margin: -20px 0 0 0;
    padding: 8px 0 12px 10px;
    box-shadow: 0 2px 8px 0 rgba(151, 151, 151, 0.1);
    border-style: solid;
    border-width: 1px;
    border-image-source: linear-gradient(to bottom, #ffebc6 100%, #f2e0bf 4%);
    border-image-slice: 1;
    background-image: linear-gradient(to bottom,
        #fff5e3 0%,
        rgba(255, 245, 227, 0) 100%);

    .completeTask {
      font-size: 12px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: left;
      color: #c39654;
      margin: 16px 0 8px 0;
    }

    .eventThreeContent {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .eventThreeContent1 {
        max-width: 153px;
        min-width: 96px;
        width: 100%;
      }

      h4 {
        font-size: 14px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        color: #252525;
        margin: 8px 0 0 0;
      }

      p {
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        color: #3a424b;
        margin: 0;
      }

      .add {
        margin: 0 9px 0 3.5px;
      }

      .trophy {
        width: 66px;
        height: 61px;
      }
    }

    .sub_title {
      margin: 8px 0 0;
      font-size: 12px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: left;
      color: #979797;

      span {
        color: #c9984f;
      }
    }
  }

  @media screen and (min-width:600px) {
    & {
      .eventThreeContentContainer {
        .eventThreeContent {
          .add {
            margin: 0 39px 0 38px;
          }

          .arrow_right {
            margin: 0 11px;
          }
        }
      }
    }
  }

  @media screen and (max-width: 479px) {
    & {
      .eventThreeContentContainer {
        .eventThreeContent {
          .trophy {
            margin-left: -8px;
            margin-right: 6px;
          }
        }
      }
    }
  }

  @media screen and (max-width: 416px) {
    & {
      .eventThreeContentContainer {
        .eventThreeContent {
          .eventThreeContent1 {
            h4 {
              width: 50px;
            }
          }
        }
      }
    }
  }


}
</style>
  