<template>
    <div id="agentAnnualResult">
        <div class="container">
            <p v-if="eventStatus == 'status2' && annualResultApiData.target == '4' || eventStatus == 'status3' && annualResultApiData.target == '4' || eventStatus == 'status4' && annualResultApiData.target == '4'"
                class="title">{{
                    $t('ib6.monthlyStatistics.reachTopLevel') }}</p>
            <!-- fail -->
            <p v-else-if="eventStatus == 'status3' && annualResultApiData.target == '0' || eventStatus == 'status4' && annualResultApiData.target == '0'"
                class="title">
                {{ $t('ib6.monthlyStatistics.fail') }}
            </p>
            <!-- 当前挑战等级及奖励 -->
            <p v-else-if="eventStatus == 'status1' || eventStatus == 'status2'" class="title">{{
                $t('ib6.monthlyStatistics.annualSubTitle')
            }}</p>
            <!-- 已成功挑战 -->
            <p v-else-if="eventStatus == 'status3' || eventStatus == 'status4'" class="title">{{
                $t('ib6.monthlyStatistics.levelAchieve')
            }}</p>
            <AnnualPrizeCard
                :prizeLevel="Number(annualResultApiData.target) == 3 && (eventStatus == 'status3' || eventStatus == 'status4') ? annualResultApiData.target : annualResultApiData.annualPrizeInfo.target" />
            <div class="annual_wrapper">
                <ProgressBar :currentValue="annualResultApiData.totalQualifiedClient"
                    :totalValue="annualResultApiData.annualPrizeInfo.qualifiedCustomerNo" />
                <div>
                    <p>{{ $t('ib6.monthlyStatistics.validCustomer') }}</p>
                    <p>
                        <span>
                            <span class="active">{{ annualResultApiData.totalQualifiedClient }}</span> / {{
                                annualResultApiData.annualPrizeInfo.qualifiedCustomerNo }}
                        </span>
                    </p>
                </div>
                <ProgressBar :currentValue="annualResultApiData.totalInGold"
                    :totalValue="annualResultApiData.annualPrizeInfo.depositStandard" />
                <div>
                    <p>{{ $t('ib6.monthlyStatistics.topUp') }}<a
                            @click="openModal(); setGTMEvent(`${commonStore.currentEvent}_netdepositstatus2_logged`);"
                            id="Info_1"><img class="bg_help" :src="imageUrl + 'help.svg'"></a></p>
                    <p>
                        <span>
                            <span class="active">{{ annualResultApiData.totalInGold }}</span> / {{
                                annualResultApiData.annualPrizeInfo.depositStandard }}
                        </span>
                    </p>
                </div>
                <ProgressBar :currentValue="annualResultApiData.totalTransaVolume"
                    :totalValue="annualResultApiData.annualPrizeInfo.volumeStandard" />
                <div>
                    <p>{{ $t('ib6.monthlyStatistics.trade') }}</p>
                    <p>
                        <span>
                            <span class="active">{{ annualResultApiData.totalTransaVolume }}</span> / {{
                                annualResultApiData.annualPrizeInfo.volumeStandard }}
                        </span>
                    </p>
                </div>

            </div>
            <EventTimeTable :event-status="eventStatus" :dateToCount="dateToCount" :eventDataList="eventTimeTableDataList"
                countDownType="annual" />
            <EventInfoBtn :btn-info-title="$t('ib6.monthlyStatistics.btn_more_info_annual')"
                :btnInfoLink="`${this.$i18n.locale}/annualDetail`"
                :btn-id="`${commonStore.currentEvent}_annualdetail_logged`" />
            <EventRuleBtn :btnRulePath="`${this.$i18n.locale}/annualDetail#section_event_rule`"
                :btn-id="`${commonStore.currentEvent}_annualrule_logged`" />
        </div>
    </div>
</template>
<script lang="ts">
import { AnnualRewardData } from '@/modal/AnnualReward';
import ProgressBar from "@/components/ProgressBar.vue";
import EventTimeTable from "@/views/ib6/EventTimeTable.vue";
import EventInfoBtn from "@/components/button/EventInfoBtn.vue";
import EventRuleBtn from "@/components/button/EventRuleBtn.vue";
import { useCommonStore } from '@/stores/common';
import AnnualPrizeCard from "@/components/AnnualPrizeCard.vue";
import { setGTMEvent } from "@/service/gtm";

export default {
    props: {
        eventStatus: {
            default: "",
        },
        dateToCount: {
            default: '',
        },
        annualResultApiData: {
            default: {} as AnnualRewardData,
        }
    },
    components: {
        ProgressBar,
        EventTimeTable,
        EventInfoBtn,
        EventRuleBtn,
        AnnualPrizeCard,
    },
    data() {
        return {
            imageUrl: "https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/ib_event/ib6/images/",
            commonStore: useCommonStore(),
            eventTimeTableDataList: {
                eventTitle: 'ib6.eventDate.annualTitle',
                eventPeriodDate: 'ib6.eventDate.annualPeriod',
                eventTimelineStatus: 'ib6.eventDate.annualTimelineStatus',
                eventPeriod: 'ib6.eventDate.annualPeriod',
                pendingTitle: 'ib6.eventDate.annualPendingTitle',
                pendingContent: 'ib6.eventDate.annualPendingContent',
                eventEndTitle: 'ib6.eventDate.annualEventEndTitle',
                eventEndSubTitle: 'ib6.eventDate.annualEventEndSubTitle',
                eventEndContent: 'ib6.eventDate.annualEventEndContent',
                eventPeriodInGold: ''
            }
        }
    },
    methods: {
        setGTMEvent,
        openModal() {
            // this.IB4Store.setSelectedGift(type, code, name);
            this.commonStore.openModal('DepositInfo')
        },
    }
}
</script>
<style lang="scss">
#agentAnnualResult {
    .title {
        margin: 24px 0 16px;
        font-family: MicrosoftYaHeiSemibold;
        font-size: 16px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: #252525;
    }

    .annual_wrapper {
        margin-top: 24px;

        div {
            display: flex;
            justify-content: space-between;
            margin-bottom: 15px;

            p {
                display: flex;
                font-size: 12px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.67;
                letter-spacing: normal;
                text-align: left;
                color: #252525;

                span {
                    font-size: 12px;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.67;
                    letter-spacing: normal;
                    text-align: left;
                    color: #878793;

                    .active {
                        color: #ee0a24;
                    }
                }
            }
        }
    }
}
</style>