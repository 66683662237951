<template>
    <div class="marquee_wrapper">
        <Vue3Marquee :vertical="true" pauseOnHover="true" :gradient="true" :gradient-color="[254, 250, 250]"
            gradientLength="40%" duration="80">
            <div class="marquee_content" v-for="item in marqueeList">
                <p class="title">{{ item.clientName }}</p>
                <p v-if="item.marqueeType == 1 || item.marqueeType == 3" class="content">{{
                    $t('ib6.monthlyStatistics.prizeDepositTitle') }} <span>{{
        item.amount }}</span></p>
                <p v-if="item.marqueeType == 2 || item.marqueeType == 4" class="content">{{
                    $t('ib6.monthlyStatistics.prizeVolumeTitle1') }} <span>{{
        item.amount }}</span></p>
            </div>
        </Vue3Marquee>
    </div>
</template>
<script lang="ts">
import { Marquee } from "../../modal/Marquee";
import { getMarqueeList } from '../../api/getEventConfig';

export default {
    data() {
        return {
            marqueeList: <Marquee[]>[],
        }
    },
    methods: {
        async getMarquee() {
            const { data } = await getMarqueeList();
            this.marqueeList = data
        }
    },
    mounted() {
        this.getMarquee();
    },
}
</script>
<style lang="scss">
.marquee_wrapper {
    display: flex;
    justify-content: center;
    // width: 100%;
    height: 80px;

    .marquee_content {
        display: flex;
        align-items: center;

        .title {
            margin: 5px;
            font-size: 14px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: 1px;
            text-align: center;
            color: rgb(0, 0, 0);
        }

        .content {
            margin: 0 0 0 24px;
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: 1px;
            text-align: center;
            color: #979797;

            span {
                font-size: 16px;
                font-weight: bold;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                text-align: left;
                color: #d91d22;
            }
        }
    }
}
</style>
