export const checkCRMURL = () => {
  let crmHomeUrl: string = "";
  if (window.location.host.includes("stg")) {
    crmHomeUrl = "https://v5-crm-ib-stg.songmao.tech/home";
  } else if (window.location.host.includes("uat")) {
    crmHomeUrl = "https://v5-crm-ib-uat.zeusenv.com/home";
  } else {
    crmHomeUrl = "https://www.dooprimep1.com/home";
  }
  return crmHomeUrl;
};

export const subtractHours = (date: any, hours: any) => {
  const dateCopy = new Date(date);
  dateCopy.setHours(dateCopy.getHours() - hours);
  return dateCopy.toISOString();
};

export const numberWithCommas = (x: any) => {
  // detect decimal
  if (Number(x) % 1 !== 0) {
    x = Number(x).toFixed(2);
  }
  return String(x).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const removeNumberWithCommas = (x: any) => {
  return String(x).replace(/[^0-9\.-]+/g, "");
};

export const formatDate = (data: any) => {
  let date = new Date(data);

  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");

  return `${year}.${month}.${day}`;
};

export const padNumberWithZeros = (number: number) => {
  const pad = "000000000";
  const totalReward = (pad + number.toString())
    .slice(-pad.length)
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return totalReward;
};
