<template>
    <div class="modal-content" style="position:relative">
        <div class="RedeemFail">
            <div class="title">{{ $t("ib5.rewardHistory.failClaimTitle") }}</div>
            <p>{{ $t("ib5.redeemFail.message") }}</p>
            <a v-if="(selectedPrize as GiftType).type == '0'" @click="handleSubmit" class="btn-ib4 btn_normal">{{
                $t("ib5.redeemFail.redo") }}</a>
            <a v-else @click="commonStore.openModal('RedeemForm')" class="btn-ib4 btn_normal">{{ $t("ib5.redeemFail.redo")
            }}</a>
        </div>
        <div v-if="(selectedPrize as GiftType).type == '0' && isLoading" class="loading">
            <div class="load-container">
                <div class="outer-ring">
                    <div class="inner-ring"></div>
                    <div class="inner-ring"></div>
                    <div class="inner-ring"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { useCommonStore } from '@/stores/common'
import { useIB4Store } from '@/stores/ib4-0'
import { receiveBonus } from "@/api/ib4-0"
interface GiftType {
    type: string;
}
export default {
    name: "ModelRedeemFail",
    setup() {
        const commonStore = useCommonStore()
        const { selectedPrize } = useIB4Store()


        return {
            selectedPrize,
            commonStore
        }
    },
    data() {
        return {
            imageUrl: "http://doo-prime-static.oss-cn-hongkong.aliyuncs.com/8-year-anniversary/8-year-ib/",
            isLoading: false,
        }
    },
    methods: {
        hideModal() {
            setTimeout(() => {
                this.commonStore.closeModal()
            }, 4000);
        },
        async handleSubmit() {
            if (this.isLoading) {
                return;
            }

            this.isLoading = true;

            let values = {};
            values["giftType"] = this.selectedPrize.type;
            values["giftName"] = this.selectedPrize.type == 0 ? this.selectedPrize.name.split(" ")[1] : this.selectedPrize.name;
            values["giftCode"] = this.selectedPrize.code;

            const { data } = await receiveBonus(values)

            if (data.code == 0) {
                this.isLoading = false;
                this.commonStore.openModal('RedeemSuccess');
            } else {
                this.isLoading = false;
                this.commonStore.openModal('RedeemFail');
                this.errors = data.msg;
            }
        },
    },
    mounted() {
        //this.hideModal();
    },
}
</script>

<style lang="scss">
.loading {
    background: rgba(255, 255, 255, 0.5);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.load-container {
    position: absolute;
    margin: auto;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 80px;
    height: 80px;
}

.outer-ring {
    color: #fff;
    position: relative;
    border-radius: 50px;
    z-index: 1000;
    width: 80px;
    height: 80px;
    animation: spiner 1s infinite linear;

}

.inner-ring {
    position: relative;
    margin-top: 8px;
    background-color: #e00d28;
    width: 20px;
    height: 20px;
    z-index: 1001;
    border: 1px solid transparent;
    border-radius: 50%;

    &:nth-child(2) {
        transform: rotate(-24deg);
        transform-origin: 0 124%;
    }

    &:last-child {
        transform: rotate(-26deg);
        transform-origin: 0 6%;
    }
}

@keyframes spiner {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.RedeemFail {
    .title {
        font-size: 24px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.2;
        letter-spacing: normal;
        text-align: center;
        color: #14142a;
        margin-bottom: 32px;
    }

    p {
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.25;
        letter-spacing: normal;
        text-align: center;
        color: #14142a;
        margin-bottom: 48px;
    }
}</style>