<template>
    <div v-if="commonStore.modal.name == 'InvalidCountry'" id="main-modal"
        :class="commonStore.modal.isOpen ? 'show ' + commonStore.modal.name : commonStore.modal.name">
        <div id="doocnyiboffernotactivatedaccount_pageview_logged" class="modal-overlay"></div>
        <div class="modal-content-wrapper invalid-country">
            <div class="modal-content">
                <div class="">
                    <!-- <p>This promotion is only for Doo Prime's IB from China and Korea Regions.</p>
                    <p>Redirecting to the official website in <span>{{ timerCount }}</span> seconds.</p> -->
                    <p>{{ $t(`ib6.invalidCountry.title`) }}</p>
                    <p class="btn" @click="redirectToCrm">确定</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { useCommonStore } from '@/stores/common'
import { useAgentStore } from '@/stores/user'
export default {
    setup() {
        const commonStore = useCommonStore()
        const agentStore = useAgentStore()
        return {
            commonStore,
            agentStore,
        }
    },
    data() {
        return {
            timerCount: 5,
        }
    },
    methods: {
        // countDownTimer() {
        //     if (this.timerCount > 0) {
        //         setTimeout(() => {
        //             this.timerCount -= 1
        //             this.countDownTimer()
        //         }, 1000)
        //     }
        //     else {
        //         window.location.href = this.commonStore.crmHomeUrl;
        //     }
        // },
        redirectToCrm() {
            window.location.href = this.commonStore.crmHomeUrl;
        }


    },
    created() {
        // this.countDownTimer()
    }
}
</script>

<style lang="scss">
.invalid-country {
    width: 100%;
    max-width: 343px !important;
    padding: 24px 16px !important;
    margin: auto;


    p {
        margin: 0;
        font-family: MicrosoftYaHeiSemibold;
        font-size: 16px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.25;
        letter-spacing: normal;
        text-align: left;
        color: #14142a;
    }

    .btn {
        max-width: 124px;
        margin: 20px auto auto;
        cursor: pointer;
        font-family: MicrosoftYaHei;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        color: #fff;
        padding: 9px 48px;
        border-radius: 4px;
        background-color: #d91d22;

    }

}
</style>