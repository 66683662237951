<template>
    <div id="section_event_rule">
        <div class="container">
            <div class="activity-rules">
                <p>{{ $t(`ib6.${eventRuleTitle}.eventRule.title`) }}</p>

                <div class="deactivate expandContent">
                    <p class="sub_title">{{ $t(`ib6.${eventRuleTitle}.eventRule.subTitle`) }}</p>
                    <template v-for="n in ruleDescriptionLength">
                        <div v-if="n <= 3" class="rules">
                            <!-- <p class="rules_no">2.</p> -->
                            <!-- <p>{{ $t(`ib6.eventRule.activityRulesDesc${n}`) }}</p> -->
                            <p>{{ $t(`ib6.${eventRuleTitle}.eventRule.description${n}`) }}</p>
                        </div>
                        <div v-if="n > 3 && isExpand == true" class="rules">
                            <!-- <p class="rules_no">2.</p> -->
                            <!-- <p>{{ $t(`ib6.eventRule.activityRulesDesc${n}`) }}</p> -->
                            <p>{{ $t(`ib6.${eventRuleTitle}.eventRule.description${n}`) }}</p>
                        </div>
                    </template>
                    <div class="cover"></div>
                </div>

                <p v-if="!isExpand" class="expand_btn" @click="expandContent">
                    <span>{{
                        $t('ib6.eventRule.expandTitle') }}</span><img :src="imageUrl2 + 'icon_arrow_up.svg'"
                        class="arrow" />
                </p>
                <p v-else-if="isExpand" class="expand_btn" @click="expandContent"><span>{{
                    $t('ib6.eventRule.collapseTitle') }}</span><img :src="imageUrl2 + 'icon_arrow_up.svg'"
                        class="arrow" />
                </p>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { useAgentStore } from "@/stores/user";
import test from "node:test";
export default {
    props: {
        eventRuleTitle: {
            default: 'eventLevel',
        },
    },
    data() {
        return {
            agentStore: useAgentStore(),
            isExpand: false,
            imageUrl2: "http://doo-prime-static.oss-cn-hongkong.aliyuncs.com/ib_3.0/images/",
            eventRuleLength: 40,
            ruleDescriptionLength: 0,
        }
    },
    methods: {
        expandContent() {
            const howToJoin: HTMLElement = document.querySelector(".activity-rules");
            const howToJoinC: HTMLElement = document.querySelector(".activity-rules > div");

            howToJoin.classList.toggle("active");
            if (howToJoin.classList.contains('active')) {
                this.isExpand = true;
                // howToJoinC.style.maxHeight = howToJoinC.scrollHeight + "px";
            } else {
                this.isExpand = false;
                // howToJoinC.style.maxHeight = null;
            }
        },
        totalRuleLength() {

            if (this.eventRuleTitle == 'eventLevel') {
                this.ruleDescriptionLength = 19
            } else if (this.eventRuleTitle == 'eventRanking') {
                this.ruleDescriptionLength = 35
            }
            else if (this.eventRuleTitle == 'eventAnnual') {
                this.ruleDescriptionLength = 50
            }
        }
    },
    mounted() {
        this.totalRuleLength()
    }
}
</script>

<style lang="scss">
#section_event_rule {
    padding-bottom: 40px;

    .container {
        padding: 0;
    }

    p {
        margin: 0;
    }

    &.active {
        .cover {
            display: none;
        }
    }

    &:not(.active) {
        .arrow {
            transform: rotate(180deg)
        }
    }



    .activity-rules {
        position: relative;
        overflow: hidden;
        border-radius: 12px;
        padding: 24px 24px 0;
        border-radius: 12px;
        box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.1);
        border: solid 1px #fff;
        background-color: #fff;

        .title {
            font-family: AlibabaPuHuiTi;
            margin-bottom: 16px;
            font-size: 16px;
            font-weight: 900;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: center;
            color: #252525;
        }

        p {
            margin-bottom: 16px;
            font-size: 16px;
            font-weight: 900;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: center;
            color: #000;

        }

        >.active {
            overflow: hidden;
            transition: max-height 0.2s ease-out;
            position: relative;
            max-height: 100px;
        }

        &.active {
            .expand_btn {
                img {
                    transform: rotate(0deg) !important;
                }
            }
        }

        >.deactivate {
            overflow: hidden;
            transition: max-height 0.2s ease-out;
            position: relative;
            // max-height: 100px;
        }

        .cover {
            height: 100%;
            width: 100%;
            position: absolute;
            left: 0;
            bottom: 0;
            z-index: 1;
            // background-image: linear-gradient(to bottom, transparent 50%, #fafbff);
            background-image: linear-gradient(to bottom, transparent 100%, #fafbff);
        }

        .sub_title {
            opacity: 0.8;
            font-size: 12px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.5;
            letter-spacing: normal;
            text-align: left;
            color: #2a323c;
            margin: 0 0 8px;
        }

        .rules {

            display: flex;
            // flex-direction: row;
            // justify-content: flex-start;
            align-items: center;
            // margin-bottom: 16px;


            .rules_no {
                display: inline-block;
                align-self: baseline;
                margin: 0 4px 0 0;
                min-width: 20px;
            }

            span {
                font-size: 12px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.5;
                letter-spacing: normal;
                text-align: left;
                color: #2a323c;
                display: inline-block;
            }

            p {
                opacity: 0.8;
                font-size: 12px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.5;
                letter-spacing: normal;
                text-align: left;
                color: #2a323c;
                margin: 0;
            }
        }

        .expand_btn {
            margin: 19px auto 16px auto;
            cursor: pointer;
            position: relative;
            z-index: 1;


            span {
                padding-right: 4px;
                margin-bottom: 0px;
                font-size: 14px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.43;
                letter-spacing: normal;
                color: #ee0a24;
                display: inline-block;
            }

            img {
                max-height: 17px;
                width: auto;
                display: inline-block;
                vertical-align: middle;
            }
        }

    }
}
</style>