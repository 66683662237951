<template>
    <div class="modal-content DepositInfo">
        <div>
            <p class="title"><img class="bg_help" :src="imageUrl + 'help.svg'" />{{ $t('ib6.modal.depositInfoTitle') }}</p>
            <p class="content">{{ $t('ib6.modal.depositInfoContent') }}</p>
            <a class="btn" @click="commonStore.closeModal()"><span>{{ $t('ib6.modal.depositInfoBtnTitle') }}</span></a>
        </div>
    </div>
</template>

<script lang="ts">
import { useCommonStore } from '@/stores/common'
export default {

    data() {
        return {
            imageUrl: "https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/ib_event/ib6/images/",
            commonStore: useCommonStore()

        }
    },
    methods: {

    },

}
</script>

<style lang="scss">
.DepositInfo {
    p {
        margin: 0;
    }

    .title {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 14px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.57;
        letter-spacing: normal;
        text-align: left;
        color: #c39654;
    }

    .content {
        // max-width: 255px;
        margin: 8px 0 32px;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.57;
        letter-spacing: normal;
        text-align: left;
        color: #3a424b;
    }

    .btn {
        display: inline-block;
        max-width: 120px;
        margin: auto;
        border-radius: 21px;
        border-style: solid;
        border-width: 2px;
        border-image-source: linear-gradient(92deg, #f1353a 0%, #ffc1c2 23%, rgba(241, 180, 154, 0.57) 50%, #ffc1c2 72%, #f1353a 97%);
        border-image-slice: 0;
        background-image: linear-gradient(to bottom, #d91d22, #d91d22), linear-gradient(92deg, #f1353a 0%, #ffc1c2 23%, rgba(241, 180, 154, 0.57) 50%, #ffc1c2 72%, #f1353a 97%);
        background-origin: border-box;
        background-clip: content-box, border-box;

        span {
            display: inline-block;
            padding: 7px 16px;
            border-radius: 23px;
            font-size: 16px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: 1px;
            text-align: center;
            color: #fff;
        }
    }
}
</style>