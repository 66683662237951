import api from "./config";

const baseUrl = "/location";

// export const getLocation = async () => {
//   try {
//     const payload = await api({
//       method: "GET",
//       url: `${baseUrl}/`,
//     });
//     return payload;
//   } catch (e) {
//     return e;
//   }
// };
export const getLocation = async () => {
  try {
    const payload = await api({
      method: "GET",
      url: "https://frontapi.finpoints.tech",
      // withCredentials: true,
    });

    const data = {
      code: 0,
      data: {
        country: payload.data.data.iso_country_code,
        region: payload.data.data.region,
        city: payload.data.data.city,
        country_code: payload.data.data.iso_country_code,
      },
    };
    return data;
  } catch (e) {
    return e;
  }
};
