<template>
    <div class="eventThreeContainer">
        <img :src="imageUrl + `annual_prize_${trophyType[prizeLevel].prizeImage}.png`" class="prize" />
        <div class="eventThreeContentContainer">
            <p class="completeTask">
                {{ $t(`ib6.eventDescription.complete`) }}
            </p>
            <div class="eventThreeContent">
                <div class="eventThreeContent1">
                    <p>{{ $t(`ib6.eventAnnual.deposit`) }}</p>
                    <h4>{{ $t(`ib6.eventAnnual.depositAmount${trophyType[prizeLevel].textIndex}`) }}</h4>
                </div>

                <img :src="imageUrl + 'add.svg'" class="add" />

                <div class="eventThreeContent1">
                    <p>{{ $t(`ib6.eventAnnual.trading`) }}</p>
                    <h4>{{ $t(`ib6.eventAnnual.tradingAmount${trophyType[prizeLevel].textIndex}`) }}</h4>
                </div>

                <img :src="imageUrl + 'arrow_right.svg'" class="arrow_right" />
                <img :src="imageUrl + `trophy_${trophyType[prizeLevel].imageTrophy}.png`" class="trophy" />
            </div>
        </div>
    </div>
</template>

<script lang="ts">
export default {
    props: {
        prizeLevel: {
            default: '1'
        },

    },
    data() {
        return {
            imageUrl: "https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/ib_event/ib6/images/",
            // trophyType: ["bronze", "sliver", "gold"],
            trophyType: {
                1: {
                    imageTrophy: "bronze",
                    prizeImage: "3",
                    textIndex: "3",
                },
                2: {
                    imageTrophy: "sliver",
                    prizeImage: "2",
                    textIndex: "2",
                },
                3: {
                    imageTrophy: "gold",
                    prizeImage: "1",
                    textIndex: "1",
                }
            },
        }
    },
}
</script>
<style lang="scss">
.eventThreeContainer {
    .mg-btm-24 {
        margin-bottom: 24px;
    }

    .prize {
        scale: 1.1;
        width: 267px;
    }

    .eventThreeContentContainer {
        width: 100%;
        margin: -20px 0 0 0;
        padding: 8px 0 12px 10px;
        box-shadow: 0 2px 8px 0 rgba(151, 151, 151, 0.1);
        border-style: solid;
        border-width: 1px;
        border-image-source: linear-gradient(to bottom, #ffebc6 100%, #f2e0bf 4%);
        border-image-slice: 1;
        background-image: linear-gradient(to bottom,
                #fff5e3 0%,
                rgba(255, 245, 227, 0) 100%);

        .completeTask {
            font-size: 12px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: left;
            color: #c39654;
            margin: 16px 0 8px 0;
        }

        .eventThreeContent {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;

            h4 {
                font-size: 14px;
                font-weight: bold;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                text-align: left;
                color: #252525;
                margin: 8px 0 0 0;
            }

            p {
                font-size: 12px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                text-align: left;
                color: #3a424b;
                margin: 0;
            }

            .add {
                margin: 0 9px 0 3.5px;
            }

            .trophy {
                width: 66px;
                height: 61px;
            }
        }
    }
}
</style>