import api from "./config";

const baseUrl = "/result";

export const getPublishResults = async () => {
  try {
    const payload = await api({
      method: "get",
      url: `${baseUrl}/getPublishResults`,
      withCredentials: true,
    });
    return payload;
  } catch (e) {
    return e;
  }
};

export const getAnnualReward = async () => {
  try {
    const payload = await api({
      method: "get",
      url: `${baseUrl}/getAnnualReward`,
      withCredentials: true,
    });
    return payload;
  } catch (e) {
    return e;
  }
};

export const getAchievedPrizeDetails = async () => {
  try {
    const payload = await api({
      method: "get",
      url: `${baseUrl}/getAchievedPrizeDetails`,
      withCredentials: true,
    });
    return payload;
  } catch (e) {
    return e;
  }
};

export const getClaimedPrizeRecord = async () => {
  try {
    const payload = await api({
      method: "get",
      url: `${baseUrl}/getClaimedPrizeRecord`,
      withCredentials: true,
    });
    return payload;
  } catch (e) {
    return e;
  }
};

export const claimReward = async (data: any) => {
  try {
    const payload = await api({
      method: "post",
      url: `${baseUrl}/claimReward`,
      withCredentials: true,
      data: data,
    });
    return payload.data;
  } catch (e) {
    return e;
  }
};
