<template>
    <div id="pageLayout">
        <div class="pageContainer">
            <div class="eventTitle">
                <h1>{{ eventTitle }}</h1>
            </div>
            <div class="layoutTop">
                <img class="ribbon1" :src="imageUrl + 'ribbon1.png'" />
                        <img class="ribbon4" :src="imageUrl + 'ribbon4.png'" />
                        <img class="gold1" :src="imageUrl + 'gold1.png'" />
                        <img class="gold2" :src="imageUrl + 'gold2.png'" />
            </div>

            <div class="layoutContent">
                <slot></slot>
            </div>

            <div class="layoutBottom">
                <img class="ribbon3" :src="imageUrl + 'ribbon3.png'" />
                        <img class="ribbon7" :src="imageUrl + 'ribbon7.png'" />
                        <img class="gold3" :src="imageUrl + 'gold3.png'" />
            </div>

        </div>
    </div>
</template>

<script lang="ts">

export default {
    name: 'LevelDetail',
    props: {
        eventTitle: String,
    },
    data() {
        return {
            imageUrl: "https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/ib_event/ib6/images/",
        };
    },
};
</script>

<style lang="scss">
$cdn: 'https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/ib_event/ib6/images/';

#pageLayout {
    margin-bottom:24px;

    .pageContainer {
        border-radius: 12px;
        padding: 29px 23.5px 0 24px;
        box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.1);
        border: solid 1px #fff;
        background-color: #fff;
        margin-top: 24px;

        .eventTitle {
            background-image: url($cdn + 'title_bg.png');
            background-size: cover;
            background-repeat: no-repeat;
            height: 46px;
            width: 245px;
            margin: -45px auto 0 auto;
            padding: 10px 10px 10px 10px;
            border-radius: 35px;
            -webkit-backdrop-filter: blur(4px);
            backdrop-filter: blur(4px);

            h1 {
                font-family: AlibabaPuHuiTi;
                font-size: 20px;
                font-weight: 900;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                color: #644415;
                margin: 0;
            }

        }

        .layoutTop {

            position: relative;

            img {
                position: absolute;
            }

            .ribbon1 {
                transform: translate(-23%, -57%);
                left: 0;
                top: 0;
                width: 164px;
                height: 105px;
            }

            .ribbon4 {
                transform: translate(22%, -33%);
                right: 0;
                top: 0;
                width: 179px;
                height: 182.5px;
            }

            .gold1 {
                transform: translate(-95%, 300%);
                left: 0;
                top: 0;
                width: 41.6px;
                height: 46.2px;
            }

            .gold2 {
                transform: translate(150%, 165%);
                right: 0;
                top: 0;
                width: 21px;
                height: 31px;
            }

        }

        .layoutBottom {

            position: relative;

            img {
                position: absolute;
            }

            .ribbon3 {
                transform: translate(-26%, -80%);
                left: 0;
                top: 0;
                width: 156px;
                height: 142.8px;
            }

            .ribbon7 {
                transform: translate(22%, -93%);
                right: 0;
                top: 0;
                width: 169px;
                height: 159.6px;
            }

            .gold3 {
                transform: translate(-20%, 45%);
                right: 0;
                bottom: 0;
                width: 51.1px;
                height: 50.9px;
            }

        }

    }

}
</style>