import { defineStore } from "pinia";

export const useAgentStore = defineStore({
  id: "user",
  state: () => ({
    isLogin: false,
    isJoined: true,
    uuid: "",
    agentInfo: {},
    matchInfo: [],
  }),
  actions: {
    setIsJoined() {
      this.isJoined = true;
    },
    setIsLogin(data) {
      this.isLogin = data;
    },
    setUuid(info) {
      this.uuid = info;
    },
    setAgentInfo(info) {
      this.agentInfo = info;
      this.matchInfo = info?.agentMatchInfoList || [];
    },
  },
});
