<template>
  <div class="rankList" id="rank">
    <h3 class="title">{{ title }}</h3>
    <h4 class="subtitle">{{ subtitle }}</h4>
    <div class="rankLayout">
      <div v-for="(n, index) in 10">
        <div class="tier" :class="'tier' + n" :key="n" v-if="n <= 3">
          <img class="tier_img" :src="imageUrl + `no${n}.png`" />
          <div class="tier_desc">
            <h3 :class="'description' + n">
              {{ $t(`ib6.eventRanking.top${n}`) }}
            </h3>
            <h4 class="reward">
              {{ $t(`ib6.eventRanking.rewardUsd`) }} <span>{{ reward[index] }}</span>
            </h4>
          </div>
        </div>
        <div class="consolation" :key="n" v-if="n >= 4">
          <div class="consolation_desc">
            <h3 :class="'description'">{{ $t(`ib6.eventRanking.top${n}`) }}</h3>
            <h4 class="reward">{{ $t(`ib6.eventRanking.rewardUsd`) }}</h4>
            <p :style="rewardStyle(index)">
              {{ reward[index] }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
export default {
  props: {
    id: String,
    title: String,
    subtitle: String,
    reward: {
      type: Array,
      default: [],
    },
  },
  data() {
    return {
      imageUrl:
        "https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/ib_event/ib6/images/",
    };
  },
  methods: {
    rewardStyle(index: number) {
      const rewardLength = (this.reward[index] as string)?.replace(
        /,/g,
        ""
      ).length;
      return {
        "margin-right":
          index === 9 && rewardLength === 3
            ? "16px"
            : index !== 9 && rewardLength === 3
              ? "10px"
              : index === 9 && rewardLength === 2
                ? "24px"
                : index !== 9 && rewardLength === 2
                  ? "18px"
                  : "",
        "margin-left":
          index === 9 && rewardLength === 3
            ? "10px"
            : index !== 9 && rewardLength === 3
              ? "10px"
              : index === 9 && rewardLength === 2
                ? "10px"
                : index !== 9 && rewardLength === 2
                  ? "10px"
                  : "",
      };
    },
  },
  components: {},
};
</script>

<style lang="scss">
$cdn: 'https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/ib_event/ib6/images/';

.rankList {
  margin: 0 0 40px 0;

  h4,
  h3 {
    margin: 0;
  }

  .title {
    margin-bottom: 16px;
    font-family: AlibabaPuHuiTi;
    font-size: 16px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #252525;
  }

  .subtitle {
    margin-bottom: 20px;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: normal;
    text-align: center;
    color: #3a424b;
  }

  .rankLayout {
    p {
      margin: 0;
    }

    .tier1 {
      // background-image: linear-gradient(261deg, #fce8c5 91%, rgba(252, 232, 197, 0.27) 66%, rgba(252, 232, 197, 0.27) 35%, #fce8c5 10%);
      background-image: url($cdn + "top1bg.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
    }

    .tier2 {
      // background-image: linear-gradient(262deg, #e0e0e0 99%, rgba(224, 224, 224, 0.27) 70%, rgba(224, 224, 224, 0.27) 32%, #e0e0e0 2%);
      background-image: url($cdn + "top2bg.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
    }

    .tier3 {
      // background-image: linear-gradient(262deg, #eccacb 99%, rgba(236, 202, 203, 0.27) 70%, rgba(236, 202, 203, 0.27) 32%, #eccacb 2%);
      background-image: url($cdn + "top3bg.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      margin-bottom: 9px !important;
    }

    .tier {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 63px;
      padding: 8px 0;
      margin-bottom: 14px;

      .tier_img {
        height: 48px;
        width: 48px;
      }

      .tier_desc {
        width: 150px;
        margin-left: 24px;

        .description1,
        .description2,
        .description3 {
          font-size: 16px;
          font-weight: 600;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          text-align: left;
          margin-bottom: -8px;
        }

        .description1 {
          color: #c39654;
        }

        .description2 {
          color: #979797;
        }

        .description3 {
          color: #b94646;
        }

        .reward {
          font-size: 14px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          text-align: left;
          color: #000;
        }

        span {
          margin-left: 8px;
          font-size: 24px;
          font-weight: 900;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          text-align: left;
          color: #000;
        }
      }

      .tier1 {
        display: flex;
      }

      .tier2 {
        display: flex;
      }

      .tier3 {
        display: flex;
      }
    }

    .consolation {
      padding: 10px 0;
      background-color: #f8f8f8;
      border-bottom: 1px solid #e5e9ec;

      .consolation_desc {
        display: flex;
        justify-content: center;
        align-items: end;

        .reward {
          margin-left: 24px;
          margin-bottom: 2px;
        }

        h3 {
          font-size: 14px;
          font-weight: 600;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: -1.3px;
          text-align: left;
          color: #c39654;
        }

        h4 {
          font-size: 12px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          text-align: center;
          color: #252525;
        }

        p {
          margin-left: 8px;
          font-size: 16px;
          font-weight: 600;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          text-align: left;
          color: #000;
        }
      }
    }
  }
}
</style>
