<template>
    <vue3-flip-countdown :deadlineISO="this.endDate" :mainColor="mainColor" :secondFlipColor="secondFlipColor"
        :mainFlipBackgroundColor="mainFlipBackgroundColor" :secondFlipBackgroundColor="secondFlipBackgroundColor"
        :countdownSize="countdownSize" :labels="labels" :showHours="showHours" :showSeconds="showSeconds"
        :showMinutes="showMinutes" :showDays="showDays" :labelSize="labelSize" :labelColor="labelColor" />
</template>

<style>
.flip-card__back-bottom {
    border-top: solid 0px #fff !important;
}

.flip-card__bottom {
    border-top: solid 0px #fff !important;
}

.flip-card__bottom:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 1px;
    /* border-top: solid 1px #fff !important; */
}


.flip-clock {
    white-space: nowrap;
    max-width: 313px;
    display: flex;
    justify-content: space-between;
}

.flip-clock__piece {
    padding: 0;
    margin: 0 -0.8vw !important;
}

.flip-clock__slot {
    padding-top: 6px;

}

.flip-card__back-bottom,
.flip-card__bottom {
    top: 50% !important;
}

.flip-card__back-bottom,
.flip-card__back:after,
.flip-card__back:before,
.flip-card__bottom {
    padding: .20em .15em .65em !important;
}

.flip-card__top,
.flip-card__top:after,
.flip-card__top:before {
    padding: .25em .15em .2em !important;
}

.flip-card__back-bottom,
.flip-card__back:after,
.flip-card__back:before,
.flip-card__bottom,
.flip-card__top {
    width: 2.134em !important;
}

.flip-card__back-bottom-4digits:after,
.flip-card__back-bottom:after,
.flip-card__bottom-4digits:after,
.flip-card__bottom:after {
    margin-top: -0.66em !important;
}
</style>

<script lang="ts">
// import { useUserStore } from '@/stores/user'
// import { useCommonStore } from '@/stores/common'


export default {
    props: {
        endDate: {
            default: '2022-12-12T16:17:13.481Z',
        },
    },
    data() {
        return {
            mainFlipBackgroundColor: '#eeeeee',
            secondFlipBackgroundColor: '#eeeeee',
            mainColor: '#ee0a24',
            secondFlipColor: '#ee0a24',
            countdownSize: '30px',
            showDays: true,
            showHours: true,
            showSeconds: true,
            showMinutes: true,
            labelSize: '12px',
            labelColor: '#f69675',
            labels: {
                days: this.$t("time.day"),
                hours: this.$t("time.hours"),
                minutes: this.$t("time.minutes"),
                seconds: this.$t("time.seconds"),
            },
        }
    }

}
</script>
