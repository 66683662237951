<template>
    <section class="timeout-message" v-if="this.timeEnded">
        <div class="wrapper">
            <img class="image"
                src="http://doo-prime-static.oss-cn-hongkong.aliyuncs.com/8-year-anniversary/8-year-ib/TravelPage/pop_end.png"
                alt="success">
            <div class="box">
                <p :class="this.$i18n.locale" v-html="$t('jpDooTravel.end-msg')"></p>
                <a id="button_visitdp" href="https://www.dooprimejp.com/jp" v-t="'jpDooTravel.visit-dp'"></a>
            </div>
        </div>

        <button  @click="closeSection()" class="close-btn">
            <img src="http://doo-prime-static.oss-cn-hongkong.aliyuncs.com/8-year-anniversary/8-year-ib/TravelPage/close.png"
                alt="close">
        </button>
    </section>
</template>

<script lang="ts">

export default {
    props:{
        timeEnded:{
            type: Boolean,
            default: false,
        }
    },
    methods: {
        closeSection() {
            this.$emit('closePopUp', false)
        }
    }
}
</script>


<style scoped lang="scss">
section {
    max-width: none !important;
    padding-bottom: 0;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9999;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    background-image: none !important;;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .close-btn {
        padding-top: 26px;
        background-color: transparent;
        border: none;

        img {
            width: 45px;
            height: 45px;
        }
    }

    .wrapper {
        position: relative;

        .image {
            position: absolute;
            transform: translate(-50%, -50%);
            top: 15;
        }

        .box {
            width: 311px;
            height: 290px;
            border-radius: 6px;
            background-color: #fff;
            display: flex;
            flex-direction: column;

            p {
                padding-top: 93px;
                font-family: SegoeUI;
                font-size: 16px;
                font-weight: 600;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                text-align: center;
                color: #000;
            }

            a {
                font-size: 20px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                color: #fff;
                background-image: linear-gradient(to bottom, #f46661 0%, #e51c1c);
                padding: 8px 40px;
                width: fit-content;
                margin-top: 32px;
                display: flex;
                border-radius: 4px;
                align-self: center;
                margin-bottom: 35px;
            }
        }
    }

}</style>