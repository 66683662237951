<template>
    <div class="modal-content RedPacketBirthday">
        <div v-if="isClaimed == false">
            <img @click="(isLoading == false) ? claimRedPacket() : ''"
                :src="imageUrl + 'birthday_red_packet_pending_claim.png'">
            <p class="title">{{ $t('ib6.redPacket.pendingBirthdayTitle') }}</p>
        </div>
        <div v-else>
            <img :src="imageUrl + 'birthday_red_packet_claimed.png'">
            <p class="title">{{ $t('ib6.redPacket.claimedBirthdayTitle') }}</p>
            <p class="claim_title">{{ $t('ib6.redPacket.claimedTitle') }}</p>
            <p class="claim_amount">USD {{ totalAmount }}</p>
        </div>
        <div class="modal-btn-close"
            @click="(isClaimed == true) ? this.checkRedPacketFunction() : commonStore.closeModal()">
            <img :src="imageUrl2 + 'icon-close.svg'" />
        </div>
    </div>
</template>

<script lang="ts">
import { claimReward } from '@/api/getResult';
import { RewardClaimData, RewardClaimRoot } from '@/modal/RewardClaim';
import { numberWithCommas } from "@/service/general";
import { useCommonStore } from '@/stores/common'
export default {
    props: {
        getTotalRewardFunction: {},
        checkRedPacketFunction: {},
        isClaimedFunction: {},
    },
    data() {
        return {
            imageUrl: "https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/ib_event/ib6/images/",
            imageUrl2: "http://doo-prime-static.oss-cn-hongkong.aliyuncs.com/8-year-anniversary/8-year-ib/",
            commonStore: useCommonStore(),
            isLoading: false,
            isClaimed: false,
            totalAmount: '',
        }
    },
    methods: {
        async claimRedPacket() {
            try {
                const payload = {
                    redPacketType: 0,
                    claimType: 1,
                }
                this.isLoading = true;
                const data: RewardClaimRoot = await claimReward(payload);
                if (data.code == 0) {
                    this.totalAmount = numberWithCommas(data.data.redPacketVo.data.giftName);
                    this.isLoading = false;
                    this.isClaimed = true;
                    this.isClaimedFunction();
                    await this.getTotalRewardFunction();
                } else {
                    this.isLoading = false;
                }
            } catch (error) {
                this.isLoading = false;
            }
        }
    }
}
</script>

<style lang="scss" scoped>
p {
    margin: 0;
    position: absolute;
    left: 50%;
    transform: translate(-50%, calc(-50% - 34px));
}

.RedPacketBirthday {
    padding: 0 !important;
    background-image: inherit !important;
    box-shadow: inherit !important;

    img {
        width: 294px;
    }

    .title {
        font-family: AlibabaPuHuiTi;
        top: 72%;
        font-size: 16px;
        font-weight: 900;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 1px;
        text-align: center;
        color: #c39654;
    }

    .claim_title {
        top: 34%;
        font-size: 16px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 1px;
        text-align: center;
        color: #252525;
    }

    .claim_amount {
        top: 42%;
        font-size: 24px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 1px;
        text-align: center;
        color: #ee0a24;
    }
}
</style>
