<template>
    <section class="bg">

        <a class="title-box" @click="toggleFaqExpand(1)">
            <p class="title" v-t="`jpDooTravel.faqTitle1`"></p>
            <img class="icon" :src="this.imageUrl + 'icon-collapse.png'" />
        </a>

        <div :id="`faq-desc-view-1`" class="desc-view">
            <p class="desc" v-t="`jpDooTravel.faq1Question1`"></p>
            <p class="desc" v-t="`jpDooTravel.faq1Answer1`"></p>
            <p class="desc" v-t="`jpDooTravel.faq1Question2`"></p>
            <p class="desc" v-t="`jpDooTravel.faq1Answer2`"></p>
            <p class="desc" v-t="`jpDooTravel.faq1Question3`"></p>
            <p class="desc" v-t="`jpDooTravel.faq1Answer3`"></p>
        </div>

        <div class="divider"></div>

        <a class="title-box" @click="toggleFaqExpand(2)">
            <p class="title" v-t="`jpDooTravel.faqTitle2`"></p>
            <img class="icon" :src="this.imageUrl + 'icon-collapse.png'" />
        </a>

        <div :id="`faq-desc-view-2`" class="desc-view">
            <p class="desc" v-t="`jpDooTravel.faq2Question1`"></p>
            <p class="desc" v-t="`jpDooTravel.faq2Answer1`"></p>

            <p class="desc" v-t="`jpDooTravel.faq2Question2`"></p>
            <p class="desc" v-t="`jpDooTravel.faq2Answer2`"></p>

            <ul class="list">
                <li><p class="list-desc" v-t="`jpDooTravel.faq2Answer2Dot1`"></p></li>
                <li><p class="list-desc" v-t="`jpDooTravel.faq2Answer2Dot2`"></p></li>
            </ul>


            <p class="desc" v-t="`jpDooTravel.faq2Question3`"></p>
            <p class="desc" v-t="`jpDooTravel.faq2Answer3`"></p>

            <ul class="list">
                <li><p class="list-desc" v-t="`jpDooTravel.faq2Answer3Dot1`"></p></li>
                <li><p class="list-desc" v-t="`jpDooTravel.faq2Answer3Dot2`"></p></li>
                <li><p class="list-desc" v-t="`jpDooTravel.faq2Answer3Dot3`"></p></li>
                <li><p class="list-desc" v-t="`jpDooTravel.faq2Answer3Dot4`"></p></li>
                <li><p class="list-desc" v-t="`jpDooTravel.faq2Answer3Dot5`"></p></li>
            </ul>

            <p class="desc" v-t="`jpDooTravel.forMoreInfo`"></p>
        </div>

        <div class="divider"></div>

    </section>
</template>

<script lang="ts">
export default {
    data() {
        return {
            imageUrl: "https://cdn.doogroup.com/jp-doo-travel/",
        };
    },
    methods: {
        toggleFaqExpand(sectionNo: number) {
            const section = document.getElementById(`faq-desc-view-${sectionNo}`)
            const className = section.className.includes('collapse') ? 'desc-view' : 'desc-view collapse'
            section.className = className
        },
    }
}
</script>

<style lang="scss" scoped>
p,
h1 {
    margin: 0;
}

ul {

    padding-left: 0;
}

.bg {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 0;
    width: 100%;

    .title-box {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;

        .title {
            font-size: 20px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: left;
            color: #000;
        }

        .icon {
            width: 24px;
            height: 24px;
        }
    }

    .desc-view {
        margin-top: 22px;
        transition: max-height 0.2s ease-in, margin-top 0.2s ease-in;
        width: 100%;
        max-height: 2000px;
        height: auto;

        .desc {
            font-size: 16px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: left;
            color: #36383b;
            width: 100%;
            margin: 12px 0;
        }

        .list {
            margin: 4px 0;
            list-style-type: disc;
            margin-left: 28px;

            .list-desc {
                font-size: 16px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                text-align: left;
                color: #36383b;
                width: 100%;
                margin: 00;
            }
        }
    }

    .collapse {
        margin-top: 0;
        max-height: 0;
        transition: max-height 0.2s ease-out, margin-top 0.2s ease-out;
        overflow: hidden;
    }

    .divider {
        height: 1px;
        align-self: stretch;
        flex-grow: 0;
        opacity: 0.2;
        border: solid 1px #e1c89c;
        margin: 16px 0;
    }
}
</style>