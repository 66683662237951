<template>
  <div class="satisticTime">
    <p class="title">{{ $t(`${title}`) }}</p>
    <p>{{ $t(`${period}`) }}</p>
    <!-- <p v-if="period2" class="title">{{ $t(`${title2}`) }}</p>
    <p v-if="period2">{{ $t(`${period2}`) }}</p> -->
    <p>{{ $t(`${condition}`) }}</p>
  </div>
</template>

<script lang="ts">
export default {
  props: {
    period: String,
    period2: String,
    condition: String,
    title: {
      default: 'ib6.eventTime.satistic'
    },
    title2: {
      default: 'ib6.eventTime.satistic'
    }
  }
}
</script>

<style lang="scss">
.satisticTime {

  margin: 40px 0;

  .title {
    font-size: 14px;
    color: #c39654;

  }

  p {
    font-size: 14px;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    font-weight: 600;
    text-align: center;
    color: #252525;
    margin: 0;
    margin: 8px 0px;

    &:nth-child(3) {
      margin: 16px 0px 0px;
    }

    &:last-of-type {
      margin: 8px 0px;
      font-size: 12px;
      color: #979797;
    }

  }

}
</style>
