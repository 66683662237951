<template>
  <EventTabHeader :id="'1'" :title="this.$t('ib6.eventLevel.descriptionTitle')"
    :btnDetailLink="`${this.$i18n.locale}/levelDetail#section_event_rule`"
    :btnId="`${commonStore.currentEvent}_subpagerule1_visitor`">
    <div class="box_1">
      <img :src="imageUrl + '01.png'" />
      <div class="box_content">
        <p>
          {{ $t(`ib6.eventDescription.description1`) }}
        </p>
        <p v-html="$t('ib6.eventDescription.description10')"></p>
      </div>
    </div>

    <div class="box_2">
      <div class="box_content">
        <p>
          {{ $t(`ib6.eventDescription.description2`) }}
        </p>
        <p v-html="$t('ib6.eventDescription.description11')"></p>
      </div>
      <img :src="imageUrl + '02.png'" />
    </div>
    <EventSatisticTime period="ib6.eventLevel.periodInGold" period2="ib6.eventLevel.period"
      condition="ib6.eventLevel.conditionTimeZone" title="ib6.eventTime.inGoldStatistic"
      title2="ib6.eventTime.OutGoldStatistic" />
    <EventInfoBtn :btnInfoTitle="this.$t('ib6.eventInfo.level')" :btnInfoLink="`${this.$i18n.locale}/levelDetail`"
      :btn-id="`${commonStore.currentEvent}_subpage1_visitor`" />
  </EventTabHeader>
</template>

<script lang="ts">
import EventTabHeader from "@/views/ib6/home_event/EventTabHeader.vue";
import EventSatisticTime from "./EventSatisticTime.vue";
import EventInfoBtn from "@/components/button/EventInfoBtn.vue";
import { useCommonStore } from '@/stores/common'

export default {
  data() {
    return {
      imageUrl:
        "https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/ib_event/ib6/images/",
      commonStore: useCommonStore(),
    };
  },
  components: {
    EventTabHeader,
    EventSatisticTime,
    EventInfoBtn,
  },
};
</script>

<style lang="scss">
#tab_1 {
  scroll-margin-top: 200px !important;
  margin-top: 8px;

  .event_description {
    padding: 24px 22px 24px 23px;
  }

  .box_1,
  .box_2 {
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    box-shadow: 0 2px 8px 0 rgba(151, 151, 151, 0.1);
    border-style: solid;
    border-width: 1px;

    img {
      height: 72px;
      margin-bottom: -3px;
    }

    p {
      font-size: 14px;
      font-weight: 400;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #000;
      margin: 0;
    }
  }

  .box_1 {
    padding: 0 15px 0 10px;
    margin-bottom: 12px;
    box-shadow: 0 2px 8px 0 rgba(151, 151, 151, 0.1);
    border-style: solid;
    border-width: 1px;
    border-image-source: linear-gradient(to bottom, rgba(251, 195, 197, 0.96) 100%, rgba(245, 206, 207, 0.96) 4%);
    border-image-slice: 1;
    background-image: linear-gradient(to bottom, rgba(253, 209, 210, 0.96) 0%, rgba(255, 255, 255, 0) 100%);

    p {
      text-align: left;
    }

    span {
      color: #d91d22;
      font-weight: 600;
    }

    .box_content {
      margin-left: 24px;
    }
  }

  .box_2 {
    padding: 0 6px 0 0;
    box-shadow: 0 2px 8px 0 rgba(151, 151, 151, 0.1);
    border-style: solid;
    border-width: 1px;
    border-image-source: linear-gradient(to bottom, #ffebc6 100%, #f2e0bf 4%);
    border-image-slice: 1;
    background-image: linear-gradient(to bottom, #fff5e3 0%, rgba(255, 245, 227, 0) 100%);

    p {
      text-align: right;
    }

    span {
      color: #c39654;
      font-weight: 600;
    }

    .box_content {
      margin-right: 12px;
    }
  }
}
</style>
