<template>
    <section id='header' class="header">
        <div class="navigation-top">
            <div class="wrapper">
                <a href="https://www.dooprimejp.com/jp/" class="home-btn">
                    <img src="http://doo-prime-static.oss-cn-hongkong.aliyuncs.com/ib_3.0/images/Dooprime.svg" alt="dooprime logo" />
                </a>
            </div>
            <div class="right-components">
                <div class="login-container">
                    <a class="login-btn" :id="isWebView ? 'button_login' :'button_login2'" :class="this.$i18n.locale" href="https://my.dooprime.com/ja/login?sl=1" v-t="'jpDooTravel.login'"></a>
                </div>
            </div>
        </div>

        <div v-if="!isWebView" class="mobile-box">
            <div class="title">
                <img src="https://cdn.doogroup.com/jp-doo-travel/header-title-small.png" alt="header title" />
                <p class='headerDesc1' v-html="$t(`jpDooTravel.headerDesc1Mobile`)"></p>
                <p class='headerDesc2' v-t="'jpDooTravel.headerDesc2'"></p>
            </div>
            <div class="banner-mobile"></div>
            <div class="mobile-join-box">
                    <p class='period' v-t="'jpDooTravel.joinPeriod'"></p>
                    <p class='period' v-t="'jpDooTravel.joinPeriodDate'"></p>
                    <a id="button_joinnow4" href="https://my.dooprime.com/ja/login?sl=1" v-t="'jpDooTravel.joinNow'"></a>
            </div>
        </div>

        <div v-else class="banner">
            <div v-if="isWebView" class="banner-web-box">
                <img src='https://cdn.doogroup.com/jp-doo-travel/header-title.png' alt="header title" />
                <p class='headerDesc1' v-html="$t(`jpDooTravel.headerDesc1`)" ></p>
                <p class='headerDesc2' v-t="'jpDooTravel.headerDesc2'"></p>
                <p class='period' v-t="'jpDooTravel.joinPeriod'"></p>
                <p class='period' v-t="'jpDooTravel.joinPeriodDate'"></p>
                <a id="button_joinnow" href="https://my.dooprime.com/ja/login?sl=1" v-t="'jpDooTravel.joinNow'"></a>
            </div>

        </div>
    </section>
</template>

<script lang="ts">
import { ref,  onMounted } from 'vue'


export default {
    data() {
        return {
            dpImgUrl: "http://doo-prime-static.oss-cn-hongkong.aliyuncs.com/8-year-anniversary/8-year-ib/",
            imageUrl: "https://cdn.doogroup.com/jp-doo-travel/"
        };
    },
    setup() {
        const isWebView = ref(window.innerWidth > 1000);

        const calculateScreenWidth = async () => {
            isWebView.value = window.innerWidth > 1000;
        }
        onMounted(() => {
            window.addEventListener("resize", () => { calculateScreenWidth() });
        });
        return {
            isWebView
        }
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.header {
    position: relative;
    background-color: #e5eff8;
    font-family: NotoSansCJKJP;
    padding-bottom: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    p {
        margin-bottom: 0;
    }

    .navigation-top {
        position: relative;
        display: flex;
        justify-content: space-between;
        margin-top: 24px;
        width: 100%;
        max-width: 1360px;
        padding: 0 40px;

        @media screen and (max-width: 1000px) {
           max-width: 1000px;
           padding: 0 16px;
        }

        .wrapper {
            display: flex;

            .home-btn {
                img {
                    width: 238px;
                    height: 56px;

                    @media screen and (max-width: 1000px) {
                        width: 101px;
                        height: 24px;
                    }
                }
            }
        }

        .right-components {
            display: flex;
            border: none;
            padding-top: 4px;
            align-items: stretch;

            .login-container {

                .login-btn {
                    font-size: 14px;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: normal;
                    letter-spacing: normal;
                    text-align: center;
                    color: #fff;
                    background-image: linear-gradient(to bottom, #f46661 0%, #e51c1c);
                    padding: 8px 32px;
                    border-radius: 4px;
                }
            }
        }
    }

    .mobile-box {
        width: 100%;
        background-color: #e5eff8;
        display: flex;
        flex-direction: column;
        align-items: center;

        .title {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 100%;
            max-width: 600px;

            img {
                width: 375px;
            }

            .headerDesc1 {
                font-size: 16px;
                font-weight: bold;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                text-align: center;
                color: #000;
                margin-top: 16px;
            }

            .headerDesc2 {
                font-size: 10px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                text-align: center;
                color: #000;
                margin-top: 8px;
            }
        }

        .mobile-join-box {
            background-color: #c2dcf5;
            display: flex;
            flex-direction: column;
            align-items: center;
            padding-top: 16px;
            width: 100%;

            .period {
                font-size: 14px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                text-align: center;
                color: #000;
                margin-top: 0;
            }

            a {
                font-size: 16px;
                font-weight: bold;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                color: #fff;
                background-image: linear-gradient(to bottom, #f46661 0%, #e51c1c);
                padding: 8px 40px;
                margin-top: 16px;
                display: flex;
                margin-bottom: 32px;
                border-radius: 4px;
            }
        }

        .banner-mobile {
            background-image: url('https://cdn.doogroup.com/jp-doo-travel/banner/banner-mobile.png');
            height: 300px;
            background-size: cover;
            background-position: center;
            background-position-x: center;
            background-repeat: no-repeat;
            object-fit: cover;
            width: 100%;
        }
    }

    .banner {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 24px;
        position: relative;
        background-image: url('https://cdn.doogroup.com/jp-doo-travel/banner/banner.png');
        height: 900px;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        object-fit: cover;
        width: 100%;

        img {
            width: 100%;
        }

        .banner-web-box {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            width: 100%;
            max-width: 1360px;
            padding-left: 40px;
            
            img {
                width: 544px;
            }

            .headerDesc1 {
                font-size: 24px;
                font-weight: bold;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                text-align: left;
                color: #000;
                margin-top: 16px;
            }

            .headerDesc2 {
                font-size: 16px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                text-align: left;
                color: #000;
                margin-top: 8px;
                margin-bottom: 64px;
            }

            .period {
                flex-grow: 0;
                font-size: 14px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                text-align: left;
                color: #000;
                margin-top: 0;
            }
    
            a {
                font-size: 20px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                color: #fff;
                background-image: linear-gradient(to bottom, #f46661 0%, #e51c1c);
                padding: 8px 40px;
                width: fit-content;
                margin-top: 32px;
                border-radius: 4px;
            }
        }
    }


    .wrapper-fade-in {
        padding-top: 33px;
        transition: 0.2s linear all;

        &.wrapperHide {
            opacity: 0;
        }

        &.active {
            animation: 1.5s fade-in;
            transform: translateY(0px);
        }

        .top-txt {

            // padding-top: 49px;
            img {
                max-width: 363px;
                width: 100%;
                height: auto;
            }
        }
    }
}

img {
    display: block;
}

.content .btn-container:hover {
    color: #de2026;
}
</style>
