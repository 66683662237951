<template>
  <section id="level">
    <div class="container">
      <div class="level_title">
        <h2>{{ $t("ib6.eventLevel.subTitle") }}</h2>
      </div>
    </div>
    <div class="container list">
      <div style="position: relative">
        <div class="line"></div>
        <div v-for="(data, index) in levelPrizeApiData" v-if="levelPrizeApiData.length > 0" :key="index"
          class="each-level">
          <div class="dot"></div>
          <div class="wrapper">
            <div class="prize-wrapper">
              <div class="left">
                <img class="bg_lvl" :src="imageUrl + `LV${data.level}.png`" alt="" />
                <p class="title">
                  {{ $t("ib6.eventLevel.depositStandard") }}
                  <span>{{ $t('ib6.eventLevel.depositAmount') }} {{ data.depositStandard }}</span>
                </p>
                <p class="title">
                  {{ $t("ib6.eventLevel.volumeStandard1") }}
                  <span>{{ data.volumeStandard }} {{ $t('ib6.eventLevel.volumeStandard2') }} </span>
                </p>
              </div>
              <div class="right">
                <img class="bg_prize" :src="imageUrl + 'prize.png'" alt="" />
                <p class="title_red">
                  {{ $t("ib6.eventLevel.prizeTitle") }}
                  <span>{{ $t('ib6.eventLevel.prizeAmount') }} {{ data.gifts[0].giftName }}</span>
                </p>
              </div>
            </div>
          </div>

          <div class="bottom_container" v-if="data.goldPrize.length > 0">
            <div class="gold_wrapper">
              <img class="bg_gold" :src="imageUrl + 'gold_bar.png'" alt="">
              <div>
                <p class="title">
                  {{ $t("ib6.eventLevel.gold") }}
                  <span>{{ data.goldLot }} {{ $t('ib6.eventLevel.goldStandard') }} </span>
                </p>
                <p class="title">
                  {{ $t("ib6.eventLevel.subSubTitle") }}
                  <span class="title_gold">{{ $t('ib6.eventLevel.goldReward') }} {{ data.goldPrize[0].giftName }}</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import { useCommonStore } from '@/stores/common';
import { getAllLevel } from '@/api/ib4-0';
import { numberWithCommas } from "@/service/general";
import { LevelList } from "@/modal/Level";

export default {
  setup() {
    const commonStore = useCommonStore()

    return {
      commonStore,
    }
  },
  data() {
    return {
      imageUrl:
        "https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/ib_event/ib6/images/",
      levelPrizeApiData: <LevelList[]>[],
    };
  },
  methods: {
    async levelPrizeList() {
      const { data }: { data: LevelList } = await getAllLevel(this.commonStore.agentLocation);

      for (const index in data) {
        data[index].depositStandard = numberWithCommas(data[index].depositStandard);

        data[index].volumeStandard = numberWithCommas(data[index].volumeStandard);
        data[index].goldLot = numberWithCommas(data[index].goldLot);
        for (const key in data[index].gifts) {
          data[index].gifts[key].giftName = numberWithCommas(data[index].gifts[key].giftName)
        }
        for (const key in data[index].goldPrize) {

          data[index].goldPrize[key].giftName = numberWithCommas(data[index].goldPrize[key].giftName)
        }
      }

      this.levelPrizeApiData = data;

    },
  },
  mounted() {
    this.levelPrizeList();
  }

};
</script>

<style lang="scss">
$cdn: 'https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/ib_event/ib6/images/';

#level {
  padding-bottom: 39px;

  p {
    margin: 0;
  }

  .container {
    padding: 0 16px 0 12px;

    .level_title {
      margin: 40px 0 16px 0;

      h2 {
        font-family: AlibabaPuHuiTi;
        margin: 0;
        font-size: 16px;
        font-weight: 900;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: #252525;
      }
    }

    .line {
      display: block;
      height: 100%;
      width: 2px;
      background: rgb(238, 10, 36);
      background: linear-gradient(to bottom,
          rgba(238, 10, 36, 0) 0%,
          #ee0a24 19%,
          #ee0a24 39%,
          #ee0a24 65%,
          #ee0a24 82%,
          rgba(238, 10, 36, 0) 100%);
      position: absolute;
      left: 8px;
      top: 0;
    }

    .each-level {
      padding-left: 28px;
      position: relative;
      margin-bottom: 24px;
      max-height: 200px;

      .wrapper {
        padding: 25px 16px 20px 16px;
        border-radius: 12px;
        box-shadow: 0 9px 13px 0 rgba(197, 197, 212, 0.3), 0 2px 0 0 #dedeea;
        background-color: #fff;

        .prize-wrapper {
          display: flex;
          justify-content: space-between;

          .bg_lvl {
            display: block;
            max-width: 66px;
            width: 100%;
            margin-right: auto;
            margin-bottom: 7px;
          }

          .bg_prize {
            max-width: 120px;
            width: 100%;
          }

          .title {
            margin-top: 2px;
            font-size: 12px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.5;
            letter-spacing: normal;
            text-align: left;
            color: #252525;

            span {
              text-wrap: nowrap;
              font-family: MicrosoftYaHeiSemibold;
            }
          }

          .title_red {
            font-size: 10px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: center;
            color: #ee0a24;

            span {
              font-family: MicrosoftYaHeiSemibold;
              font-size: 20px;
              font-weight: 600;
            }
          }
        }
      }

      .bg_gold_bg {
        display: block;
        margin-top: -14px;
        width: 100%;
        max-height: 63px;
        max-width: 543px;
        object-fit: fill;
      }

      .bottom_container {
        margin-top: -10px;
        max-height: 60px;
        background-image: url($cdn + "level_bg.png");
        background-repeat: no-repeat;
        background-size: 100% 100%;
      }

      .gold_wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
        bottom: 5px;

        div {
          margin-top: 14px;
          margin-right: 24px;
        }

        img {
          width: 89px;
        }

        .title {
          font-size: 12px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.5;
          letter-spacing: normal;
          text-align: left;
          color: #252525;

          span {
            font-family: MicrosoftYaHeiSemibold;
          }

          .title_gold {
            font-family: MicrosoftYaHeiSemibold;
            font-size: 20px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 0.9;
            letter-spacing: normal;
            text-align: left;
            color: #c39654;
          }
        }
      }
    }
  }
}

html[lang="kr"] #level {
  .prize-wrapper {
    .title {
      span {
        font-family: NotoSansBold !important;
      }
    }

    .title_red {
      span {
        font-family: NotoSansBold !important;
      }
    }

    .title_gold {
      span {
        font-family: NotoSansBold !important;
      }
    }
  }
}
</style>
