<template>
    <section class="bg">
        <a class="title-box" @click="toggleExpand(this.sectionNo)">
            <p class="title" v-t="`jpDooTravel.tncTitle${this.sectionNo}`"></p>
            <img class="icon" :src="this.imageUrl + 'icon-collapse.png'" />
        </a>

        <div :id="`desc-view-${this.sectionNo}`" class="desc-view">
            <div v-for="desc in this.descNo" :key="`${desc}`">
                <p class="desc" v-t="`jpDooTravel.tnc${this.sectionNo}Desc${desc}`"></p>

                <table class="table" v-if="this.sectionNo === 4 && desc === 7">
                    <tr class="row" v-for="(list, index) in this.tableList" :key="`table-row-${index}`">

                            <th v-if="index === 0">
                                <p v-t="list.cell1"></p>
                            </th>
                            <td v-else v-t="`${list.cell1}`"></td>

                            <th v-if="index === 0">
                                <p v-t="list.cell2"></p>
                            </th>
                            <td v-else v-t="`${list.cell2}`"></td>

                            <th v-if="index === 0">
                                <p v-t="list.cell3"></p>
                            </th>
                            <td v-else v-t="`${list.cell3}`"></td>

                            <th v-if="index === 0">
                                <p v-t="list.cell4"></p>
                            </th>
                            <td v-else v-t="`${list.cell4}`"></td>
                    </tr>
                </table>

                <div class="reward-level" v-if="this.sectionNo === 5 && desc === 5">
                    <p class="desc-bold" v-t="`jpDooTravel.tnc5Desc5Level1`"></p>
                    <p class="desc" v-t="`jpDooTravel.tnc5Desc5Level1Desc`"></p>
                    <p class="desc-bold" v-t="`jpDooTravel.tnc5Desc5Level2`"></p>
                    <p class="desc" v-t="`jpDooTravel.tnc5Desc5Level2Desc`"></p>
                    <p class="desc-bold" v-t="`jpDooTravel.tnc5Desc5Level3`"></p>
                    <p class="desc" v-t="`jpDooTravel.tnc5Desc5Level3Desc`"></p>
                    <p class="desc-bold" v-t="`jpDooTravel.tnc5Desc5Level4`"></p>
                    <p class="desc" v-t="`jpDooTravel.tnc5Desc5Level4Desc`"></p>
                </div>
            </div>
        </div>

        <div class="divider"></div>
    </section>
</template>

<script lang="ts">
import { ref } from 'vue'

const tableList = ref([
    {cell1: 'jpDooTravel.level', cell2: 'jpDooTravel.netDepositUsd', cell3: 'jpDooTravel.tradingLot', cell4: 'jpDooTravel.prize'},
    {cell1: 'jpDooTravel.level1', cell2: '180,000', cell3: '2,800', cell4: 'jpDooTravel.prize1'},
    {cell1: 'jpDooTravel.level2', cell2: '250,000', cell3: '3,800', cell4: 'jpDooTravel.prize2'},
    {cell1: 'jpDooTravel.level3', cell2: '320,000', cell3: '5,000', cell4: 'jpDooTravel.prize3'},
    {cell1: 'jpDooTravel.level4', cell2: '400,000', cell3: '6,000', cell4: 'jpDooTravel.prize4'},
])

export default {
    props: {
        sectionNo: {
            default: 0
        },
        descNo: {
            default: 0
        },
    },
    data() {
        return {
            imageUrl: "https://cdn.doogroup.com/jp-doo-travel/",
            tableList
        };
    },
    methods: {
        toggleExpand(sectionNo: number) {
            const section = document.getElementById(`desc-view-${sectionNo}`)
            const className = section.className.includes('collapse') ? 'desc-view' : 'desc-view collapse'
            section.className = className
        },
    }
}
</script>

<style lang="scss" scoped>
p,
h1 {
    margin: 0;
}

ul {

    padding-left: 0;
}

.bg {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 0;

    .title-box {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;

        .title {
            font-size: 20px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: left;
            color: #000;

            @media screen and (max-width: 1000px) {
                font-size: 16px;
            }
        }

        .icon {
            width: 24px;
            height: 24px;
        }
    }

    .desc-view {
        margin-top: 22px;
        transition: max-height 0.2s ease-in, margin-top 0.2s ease-in;
        width: 100%;
        max-height: 2000px;
        height: auto;

        .desc {
            font-size: 16px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: left;
            color: #36383b;
            width: 100%;

            @media screen and (max-width: 1000px) {
                font-size: 14px;
            }
        }

        .table {
            width: 70%;
            margin: 24px 0;
            border: 1px solid black;
            border-collapse: collapse;

            @media screen and (max-width: 1000px) {
                width: 100%;
            }

            td {
                border: 1px solid black;
                font-size: 14px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                text-align: center;
                color: #000;

                @media screen and (max-width: 1000px) {
                    font-size: 12px;
                }
            }

            th {
                background-color: #dedede;
                border: 1px solid black;
                font-size: 14px;
                font-weight: bold;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                text-align: center;
                color: #000;

                @media screen and (max-width: 1000px) {
                    font-size: 12px;
                }
            }
        }

        .reward-level {
            margin: 24px 0;
            margin-left: 16px;

            .desc-bold {
                font-size: 16px;
                font-weight: bold;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                text-align: left;
                color: #36383b;
                width: 100%;
            }
        }
    }

    .collapse {
        margin-top: 0;
        max-height: 0;
        transition: max-height 0.2s ease-out, margin-top 0.2s ease-out;
        overflow: hidden;
    }

    .divider {
        height: 1px;
        align-self: stretch;
        flex-grow: 0;
        opacity: 0.2;
        border: solid 1px #e1c89c;
        margin: 16px 0;
    }
}

</style>