<template>
    <div class="modal-content RedeemFail2">
        <img :src="imageUrl + 'icon-redeem-fail.png'" />
        <p class="title">{{ $t("ib6.modal.failTitle") }}</p>
        <p class="sub_title">{{ $t("ib6.modal.failSubTitle") }}</p>
    </div>
</template>

<script lang="ts">
import { useCommonStore } from '@/stores/common'
export default {
    name: "ModelRedeemFail2",
    data() {
        return {
            imageUrl: "https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/ib_event/ib6/images/",
            commonStore: useCommonStore()
        }
    },
    methods: {
        hideModal() {
            setTimeout(() => {
                this.commonStore.closeModal()
            }, 3000);
        }
    },
    mounted() {
        this.hideModal();
    },
}
</script>

<style lang="scss">
.RedeemFail2 {
    .modal-content {
        max-width: 197px !important;

        img {
            max-width: 113px;
            max-height: 113px;
        }

        .title {
            margin: 0;
            font-family: MicrosoftYaHeiSemibold;
            font-size: 16px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: 1px;
            text-align: center;
            color: #252525;
        }

        .sub_title {
            margin: 8px 0;
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: center;
            color: #252525;
        }
    }
}
</style>
