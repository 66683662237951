<template>
    <div v-if="commonStore.modal.name == 'EventEnd'" id="main-modal"
        :class="commonStore.modal.isOpen ? 'show ' + commonStore.modal.name : ''">
        <div class="modal-overlay"></div>
        <div class="modal-content-wrapper event-end">
            <div class="modal-content">
                <div>
                    <p class="event_title">{{ $t('ib5.modal.eventEndTitle') }}</p>
                    <!-- <img :src="imageUrl + 'pop_over.png'" alt=""> -->
                    <p class="event_message">{{ $t('ib5.modal.eventEndMessage1') }}{{ timerCount }}{{
                        $t('ib5.modal.eventEndMessage2') }}
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>
3


<script lang="ts">
import { useCommonStore } from '@/stores/common'
export default {
    setup() {
        const commonStore = useCommonStore()
        return {
            commonStore
        }
    },
    data() {
        return {
            imageUrl: "http://doo-prime-static.oss-cn-hongkong.aliyuncs.com/ib_3.0/images/",
            timerCount: 5,
        }
    },
    methods: {
        countDownTimer() {
            if (this.timerCount > 0) {
                setTimeout(() => {
                    this.timerCount -= 1
                    this.countDownTimer()
                }, 1000)
            }
            else {
                window.location.href = 'https://www.dooprimeaffiliates.com/en';
            }
        }
    },
    created() {
        this.countDownTimer()
    }
}
</script>

<style lang="scss">
.event-end {
    width: 100%;
    max-width: 343px !important;
    margin: auto;
    padding: 32px 16px !important;
    border-radius: 12px !important;
    box-shadow: 2px 2px 15px 0 rgba(0, 0, 0, 0.15);
    background-image: linear-gradient(to bottom, #fff, #eee);

    img {
        padding: 0 60px;
    }

    .event_title {
        font-family: MicrosoftYaHeiSemibold;
        font-size: 24px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.2;
        letter-spacing: normal;
        text-align: center;
        color: #14142a;
        margin: 0 0 32px 0;
    }

    .event_message {
        font-family: MicrosoftYaHei;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.25;
        letter-spacing: normal;
        text-align: center;
        color: #14142a;
        // margin: 0;
        max-width: 311px;
        width: 100%;
        margin: auto;
        // margin: 0 36px;
        // padding-top: 12px;
        // padding: 12px 36px 0px 35px;

    }

    // p {
    //     font-family: MicrosoftYaHei;
    //     font-size: 14px;
    //     font-weight: normal;
    //     font-stretch: normal;
    //     font-style: normal;
    //     line-height: normal;
    //     letter-spacing: 1px;
    //     text-align: center;
    //     color: #4d4f59;
    // }

    // span {
    //     font-family: MicrosoftYaHei;
    //     font-size: 14px;
    //     font-weight: normal;
    //     font-stretch: normal;
    //     font-style: normal;
    //     line-height: normal;
    //     letter-spacing: 1px;
    //     color: #d91d22;
    // }
}
</style>