<template>
  <main class="main">
    <template v-if="isMask === true">
      <MaskComponent />
    </template>
    <template v-else>
      <router-view></router-view>
    </template>
  </main>
</template>

<script lang="ts">
import Mask from '@/components/Mask.vue'
import { useCommonStore } from '@/stores/common';
import { useAgentStore } from '@/stores/user';
import { useI18n } from 'vue-i18n';

export default {
  setup() {
    const commonStore = useCommonStore()
    const agentStore = useAgentStore()
    const i18n = useI18n();

    return {
      commonStore,
      agentStore,
      i18n,
    }
  },
  components: {
    MaskComponent: Mask
  },
  methods: {
    checkQQBrowser() {
      if (/MicroMessenger|QQBrowser/.test(navigator.userAgent)) {
        this.isMask = true;
        document.title = "请使用浏览器打开";
      }
    }
  },
  mounted() {
    this.checkQQBrowser();
  },
  data() {
    return {
      isMask: false
    }
  }

}
</script>