<template>
    <section id="total_reward_description">
        <div class="container">
            <!-- <div class="title_wrapper">
                <p><span>{{ $t("ib6.totalRewardDescription.title1") }} {{ $t("ib6.totalRewardDescription.title2") }}</span>
                </p>
            </div> -->
            <div class="content_wrapper">
                <div class="content">
                    <p class="title">{{ $t("ib6.totalRewardDescription.description") }}</p>
                    <img :src="imageUrl + 'USD_30,000,000.png'" alt="">
                </div>
                <img :src="imageUrl + 'total_reward.png'" alt="">
            </div>
        </div>
    </section>
</template>


<script lang="ts">
import { useAgentStore } from '@/stores/user'
import { useCommonStore } from '@/stores/common'
export default {
    data() {
        return {
            imageUrl: "https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/ib_event/ib6/images/",
            agentStore: useAgentStore(),
            commonStore: useCommonStore(),
        }
    },


}
</script>

<style lang="scss">
#total_reward_description {
    padding-bottom: 12px;

    p {
        margin: 0;
    }

    .container {
        padding: 0 32px;
    }

    .title_wrapper {
        max-width: 536px;
        border-radius: 35px;
        // -webkit-backdrop-filter: blur(4px);
        // backdrop-filter: blur(4px);
        // border-style: solid;
        // border-width: 1px;
        border-image-source: linear-gradient(60deg, #f5d7ca 27%, #fff 40%, #fff4e1 49%, #f5d7ca 68%);
        border-image-slice: 1;
        background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.4)), linear-gradient(60deg, #f5d7ca 27%, #fff 40%, #fff4e1 49%, #f5d7ca 68%);
        background-origin: border-box;
        background-clip: content-box, border-box;
        box-shadow: 0 0 0 1px #f5d7ca;

        p {
            background-color: #fff;
            border-radius: 35px;
            padding: 5px 4px;
        }

        span {
            display: block;
            text-shadow: 0 4px 12px rgba(0, 0, 0, 0.1), 0 2px 2px rgba(0, 0, 0, 0.25);
            font-family: MicrosoftYaHeiSemibold;
            font-size: 20px;
            font-weight: 900;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: 1px;
            text-align: center;
            color: #ee0a24;
            padding: 14px 22px 14px 19px;
            position: relative;
            border-image-source: linear-gradient(60deg, #f5d7ca 27%, #fff 40%, #fff4e1 49%, #f5d7ca 68%);
            // border-image-slice: 1;
            background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.4)), linear-gradient(60deg, #f5d7ca 27%, #fff 40%, #fff4e1 49%, #f5d7ca 68%);
            background-origin: border-box;
            border-radius: 35px;
            z-index: 1;


            &:after {
                content: '';
                width: 99%;
                height: 91%;
                position: absolute;
                z-index: -1;
                top: 50%;
                left: 50%;
                translate: -50% -50%;
                border-radius: 30px;
                background-image: linear-gradient(to bottom, #ffebc6 -11%, #fff 107%);
                border-color: #f5d7ca;
                border-width: 5px;



            }

        }
    }

    .content_wrapper {
        // max-width: 300px;
        margin: 15px auto 0 7px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .content {
            .title {
                font-family: MicrosoftYaHeiSemibold;
                font-size: 14px;
                font-weight: 600;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                text-align: left;
                color: #000;
            }

            img {
                max-width: 188px;
            }
        }

        img {
            max-width: 111px;
        }
    }
}
</style>
