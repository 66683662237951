<template>
    <div class="countdown-container">
        <div class="countdown-section">
            <div class="countdown-digit">
                <p class="digit"><span :id="`${countDownType}days-first`"></span></p>
                <p class="digit"><span :id="`${countDownType}days-second`"></span></p>
            </div>
            <div class="countdown-label">{{ $t('ib6.time.day') }}</div>
        </div>

        <div class="countdown-section">
            <div class="countdown-digit">
                <p class="digit"><span :id="`${countDownType}hours-first`"></span></p>
                <p class="digit"><span :id="`${countDownType}hours-second`"></span></p>
            </div>
            <div class="countdown-label">{{ $t('ib6.time.hours') }}</div>
        </div>

        <div class="countdown-section">
            <div class="countdown-digit">
                <p class="digit"><span :id="`${countDownType}minutes-first`"></span></p>
                <p class="digit"><span :id="`${countDownType}minutes-second`"></span></p>
            </div>
            <div class="countdown-label">{{ $t('ib6.time.minutes') }}</div>
        </div>

        <div class="countdown-section">
            <div class="countdown-digit">
                <p class="digit"><span :id="`${countDownType}seconds-first`"></span></p>
                <p class="digit"><span :id="`${countDownType}seconds-second`"></span></p>
            </div>
            <div class="countdown-label">{{ $t('ib6.time.second') }}</div>
        </div>
    </div>
</template>
  


<script lang="ts">
export default {
    props: {
        deadlineAP: {
            default: '',
        },
        countDownType: {
            default: 'prize',
        }
    },

    data() {
        return {
            keyTimer: 1,
            interval: null
        }
    },

    methods: {
        countdownFunction() {
            const second = 1000,
                minute = second * 60,
                hour = minute * 60,
                day = hour * 24;

            // Default to zero if no valid deadlineAP is provided or it's invalid
            let countDown = this.deadlineAP ? new Date(this.deadlineAP).getTime() : 0;

            if (isNaN(countDown) || !this.deadlineAP) {
                this.setZeroes(); // Set 00:00:00:00 if no valid deadline
                return;
            }

            let x = setInterval(() => {
                const now = Date.parse(new Date().toUTCString());
                const distance = countDown - now;

                if (distance > 0) {
                    this.updateTime(distance, day, hour, minute, second);
                } else {
                    this.setZeroes(); // Return 00:00:00:00 if time has passed
                    clearInterval(x);
                }
            }, 1000);
        },

        updateTime(distance: number, day: number, hour: number, minute: number, second: number) {
            const days = String(Math.floor(distance / day)).padStart(2, '0');
            const hours = String(Math.floor((distance % day) / hour)).padStart(2, '0');
            const minutes = String(Math.floor((distance % hour) / minute)).padStart(2, '0');
            const seconds = String(Math.floor((distance % minute) / second)).padStart(2, '0');

            this.updateDigit(`${this.countDownType}days`, days);
            this.updateDigit(`${this.countDownType}hours`, hours);
            this.updateDigit(`${this.countDownType}minutes`, minutes);
            this.updateDigit(`${this.countDownType}seconds`, seconds);
        },

        updateDigit(idPrefix: string, time: string) {
            document.getElementById(`${idPrefix}-first`).innerText = time.charAt(0);
            document.getElementById(`${idPrefix}-second`).innerText = time.charAt(1);
        },

        setZeroes() {
            this.updateDigit(`${this.countDownType}days`, '00');
            this.updateDigit(`${this.countDownType}hours`, '00');
            this.updateDigit(`${this.countDownType}minutes`, '00');
            this.updateDigit(`${this.countDownType}seconds`, '00');
        }
    },

    mounted() {
        this.countdownFunction();
    },
}
</script>



<style  lang="scss"  scoped>
.countdown-container {
    display: flex;
    justify-content: center;
    gap: 10px;
}

.countdown-section {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.countdown-digit {
    display: flex;
    // gap: 5px;
}

.digit {
    width: 32px;
    height: 50px;
    font-size: 30px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.47;
    letter-spacing: normal;
    text-align: center;
    color: #ee0a24;
    background-image: url('@/assets/images/timerCell.png');
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;

    span {
        margin-top: -6px;
    }
}

.countdown-label {
    font-size: 16px;
    color: #e1c89c;
    margin-top: 5px;
}
</style>
