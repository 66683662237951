import api from "./config";

const baseUrl =
  "https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/doo-prime-webiste";

export const leverage = async () => {
  try {
    const payload = await api({
      method: "get",
      url: `${baseUrl}/json/leverage.json?${Date.now()}`,
    });
    return payload;
  } catch (e) {
    return e;
  }
};
