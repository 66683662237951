<template>
    <a :id="btnId" class="btn_rule" :href="`${btnRulePath}`" @click="setGTMEvent(btnId)">{{ $t('ib6.eventInfo.rules') }}</a>
</template>

<script lang="ts">
import { setGTMEvent } from "@/service/gtm";
export default {
    props: {
        btnRulePath: {
            default: '',
        },
        btnId: {
            default: '',
        }
    },
    methods: {
        setGTMEvent
    }
}
</script>

<style lang="scss">
.btn_rule {
    display: block;
    margin-top: 16px;
    text-decoration: underline;
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 1px;
    text-align: center;
    color: #979797;

}
</style>