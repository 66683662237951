import { createI18n } from "vue-i18n";
import messages from '@intlify/unplugin-vue-i18n/messages';

export const i18n = createI18n({
    locale: import.meta.env.VITE_APP_I18N_LOCALE || 'sc',
    fallbackLocale: import.meta.env.VITE_APP_I18N_FALLBACK_LOCALE || 'sc',
    legacy: false,
    messages
});

export default i18n;
