<template>
    <div class="modal-content" style="position:relative">
        <div class="redeemConfirm">
            <div class="title">{{ $t('ib5.rewardHistory.title') }}</div>
            <SelectedPrize />
            <p>{{ $t('ib5.rewardHistory.description') }}</p>
            <FormKit id="formRedeem" class="form" type="form" @submit="handleSubmit" :actions="false"
                incomplete-message="Please fill up all the fields." :submit-label="$t('ib5.rewardClaim.claimRewardNow')"
                #default="{ state: { valid } }">
                <FormKit type="text" name="receiveName" :label="$t('ib5.rewardHistory.receiverName')" :classes="{
                    input: 'name',
                }" :placeholder="$t('ib5.rewardHistory.formName')" validation="required"
                    :validation-messages="{ required: $t('ib5.rewardHistory.formName') }" />
                <div class="input-group">
                    <FormKit type="select" name="receivePhoneCode" :label="$t('ib5.rewardHistory.receiverPhone')"
                        v-model="values" :options="phoneinit" :classes="{
                            outer: 'phone code',
                        }" validation="required" />
                    <FormKit type="number" name="receiveContact" :label="$t('ib5.rewardHistory.receiverPhone')" :classes="{
                        outer: 'phone',
                    }" :placeholder="$t('ib5.rewardHistory.formPhone')" validation="required"
                        :validation-messages="{ required: $t('ib5.rewardHistory.formPhone') }" />
                </div>
                <FormKit type="text" :label="$t('ib5.rewardHistory.receiverAddress')" name="receiveAddress" :classes="{
                    input: 'address',
                }" :placeholder="$t('ib5.rewardHistory.formAddress')" validation="required"
                    :validation-messages="{ required: $t('ib5.rewardHistory.formAddress') }" />
                <FormKit type="submit" :label="$t('ib5.rewardClaim.claimRewardNow')" :disabled="!valid" />
            </FormKit>
        </div>
        <div v-if="isLoading" class="loading">
            <div class="load-container">
                <div class="outer-ring">
                    <div class="inner-ring"></div>
                    <div class="inner-ring"></div>
                    <div class="inner-ring"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { ref, computed, onMounted } from 'vue'
import { useCommonStore } from '@/stores/common'
import { useAgentStore } from '@/stores/user'
import { useIB4Store } from '@/stores/ib4-0'
import { receiveBonus } from "@/api/ib4-0"
import phoneList_en from '@/js/phonecode_en.json'
import phoneList_cn from '@/js/phonecode_cn.json'
import phoneList_tw from '@/js/phonecode_tw.json'
import countryTelephoneCode, { countries } from "country-telephone-code";
import SelectedPrize from '@/components/Modals/component/SelectedPrizes.vue'
import { useI18n } from "vue-i18n";

interface GiftType {
    name: string;
}

export default {
    setup() {
        const commonStore = useCommonStore()
        const agentStore = useAgentStore()
        const { selectedPrize, agentLocation } = useIB4Store()
        const i18n = useI18n();

        return {
            commonStore,
            agentStore,
            selectedPrize,
            agentLocation,
            i18n
        }
    },
    props: {
        getLevelFunction: {}
    },
    components: {
        SelectedPrize
    },
    data() {
        return {
            values: ref('+886'),
            phoneinit: [],
            isLoading: false,
        }
    },
    methods: {
        async initPhoneCodeSelect() {

            let newarray = [];
            let currentPhoneList: any;

            const currentLang = window.location.pathname.split('/')[1];

            switch (currentLang) {
                case "sc":
                    currentPhoneList = phoneList_cn;
                    break;
                case "tc":
                    currentPhoneList = phoneList_tw;
                    break;
                default:
                    currentPhoneList = phoneList_en;
                    break;
            }

            // use countryTelephoneCode

            const capLocation = this.agentLocation.toUpperCase();
            const currentLocationCode = "+" + countryTelephoneCode(capLocation);

            for (let i = 0; i < currentPhoneList.length; i++) {

                const phone = currentPhoneList[i];

                newarray.push(phone.phoneCode);

                // phone select init
                if (phone.phoneCode == currentLocationCode) {
                    this.values = phone.phoneCode + ' ' + phone.label;
                }
            }

            this.phoneinit = newarray;
        },
        async handleSubmit(formValues: any) {

            if (this.isLoading) {
                return;
            }

            this.isLoading = true;

            if (this.selectedPrize) {
                formValues["giftName"] = this.i18n.t(`ib5.level.${this.selectedPrize.code}`, 1, { locale: 'sc' });
                formValues["receivePhoneCode"] = formValues["receivePhoneCode"].split(" ")[0];
                formValues["giftType"] = this.selectedPrize.type;
                formValues["giftCode"] = this.selectedPrize.code;
            }

            const { data } = await receiveBonus(formValues)

            if (data.code == 0) {
                this.isLoading = false;

                this.$formkit.reset('formRedeem');
                this.commonStore.openModal('RedeemSuccess');
                this.getLevelFunction();

            } else {
                this.isLoading = false;

                this.commonStore.openModal('RedeemFail');
                this.errors = data.msg;
            }

        },
    },
    mounted() {
        this.initPhoneCodeSelect();
    }
}
</script>

<style lang="scss">
.loading {
    background: rgba(255, 255, 255, 0.5);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.load-container {
    position: absolute;
    margin: auto;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 80px;
    height: 80px;
}

.outer-ring {
    color: #fff;
    position: relative;
    border-radius: 50px;
    z-index: 1000;
    width: 80px;
    height: 80px;
    animation: spiner 1s infinite linear;

}

.inner-ring {
    position: relative;
    margin-top: 8px;
    background-color: #e00d28;
    width: 20px;
    height: 20px;
    z-index: 1001;
    border: 1px solid transparent;
    border-radius: 50%;

    &:nth-child(2) {
        transform: rotate(-24deg);
        transform-origin: 0 124%;
    }

    &:last-child {
        transform: rotate(-26deg);
        transform-origin: 0 6%;
    }
}

@keyframes spiner {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.redeemConfirm {
    .title {
        font-size: 24px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.2;
        letter-spacing: normal;
        text-align: left;
        color: #14142a;
        margin-bottom: 32px
    }

    >p {
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.29;
        letter-spacing: normal;
        text-align: left;
        color: #575766;
        margin-bottom: 32px;
    }

    .modal-content-wrapper {
        max-width: 160px;
        width: 100%;
        height: 160px;
        background: rgba(0, 0, 0, 0.6);
        padding: 38px 0 24px 0;

        .modal-btn-close {
            bottom: -93px;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }

    .modal-content {

        p {
            font-family: MicrosoftYaHei;
            font-size: 16px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #fff;
        }
    }

    .input-group {
        position: relative;
        display: flex;
        align-items: baseline;

        .formkit-outer {
            width: 50%;

            &.phone {
                width: 70%;
                display: inline-block;

                &:first-child {
                    width: 72px;
                    margin-right: 8px;

                    .formkit-inner {
                        border-radius: 4px 0 0 4px;
                    }

                    select.formkit-input {
                        padding-right: 5px;
                        max-width: 80px;
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        display: inline-block;
                    }
                }

                &:last-child .formkit-inner {
                    border-radius: 0 4px 4px 0;
                }

                &:not(.code) {
                    .formkit-label {
                        visibility: hidden;
                    }
                }
            }

            .formkit-message {
                margin-left: -80px;
            }
        }
    }

    .formkit-message {
        text-align: left;
    }

    .formkit-label {
        text-align: left;
    }

    .formkit-inner {
        box-shadow: none;
        border: 1px solid #dfdfdf;
    }

    input[type='number'] {
        -moz-appearance: textfield;
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
    }

    button.formkit-input {
        width: 144px;
        height: 36px;
        background: #e00d28;
        position: relative;
        padding-top: 7px;

        &:hover,
        &:focus {
            background: #e00d28;
        }

        &:before {
            content: "" !important;
            position: absolute;
            left: -3px !important;
            top: -4px !important;
            width: 0 !important;
            height: 0 !important;
            transform: initial !important;
            border: 15px solid !important;
            border-top: 15px solid #fff !important;
            border-right: 15px solid transparent !important;
            border-bottom: 0 !important;
            border-left: 0 !important;
            display: block !important;
        }

        &:after {
            content: "";
            position: absolute;
            right: -2px;
            bottom: -2px;
            width: 0;
            height: 0;
            border-bottom: 13px solid #fff;
            border-left: 13px solid transparent;
        }
    }

    [data-type="button"][data-disabled] .formkit-input,
    [data-type="submit"][data-disabled] .formkit-input,
    [data-type="button"] .formkit-input[disabled],
    [data-type="submit"] .formkit-input[disabled] {
        background: #E9E9F0 !important
    }

    .formkit-form#formRedeem {
        >.formkit-outer:last-child {
            margin-top: 48px;
            margin-bottom: 0;
        }
    }
}</style>