import { defineStore } from "pinia";

export const useIB4Store = defineStore({
  id: "ib4-0",
  state: () => ({
    agentLevel: 0,
    agentLocation: "cn",
    selectedPrize: {},
    levelList: <levelList[]>[],
    status: ""
  }),
  actions: {
    setAgentLevel(data: number) {
      this.agentLevel = data;
    },
    setAgentLocation(data: string) {
      this.agentLocation = data.toLowerCase();
    },
    setSelectedGift(type: string, code: string, name: string) {
      this.selectedPrize = {
        type,
        code,
        name,
      };
    },
    setLevelList(data: any) {
      this.levelList = data;
    },
    setStatus(data: any) {
      this.status = data;
    },
  },
});

interface levelList {
  level: string;
  depositStandard: string;
  volumeStandard: string;
  gifts: [
    {
      giftType: number;
      giftCode: string;
      giftName: string;
    }
  ];
}
