<template>
    <div class="modal-content login">
        <div class="title">{{ $t("ib5.login.title") }}</div>
        <div v-if="showRequiredInfo" class="warn">
            <img src="http://doo-prime-static.oss-cn-hongkong.aliyuncs.com/ib_3.0/images/icon_warning.svg" />
            {{ $t("ib5.login.requiredWarn") }}
        </div>
        <div v-if="showErrorInfo" class="warn">
            <img src="http://doo-prime-static.oss-cn-hongkong.aliyuncs.com/ib_3.0/images/icon_warning.svg" />
            {{ $t("ib5.login.errorWarn") }}
        </div>
        <div v-if="showErrorVerificationCode" class="warn">
            <img src="http://doo-prime-static.oss-cn-hongkong.aliyuncs.com/ib_3.0/images/icon_warning.svg" />
            {{ $t("ib5.login.verificationWarn") }}
        </div>
        <div v-if="showErrorRemoteLogin" class="warn">
            <img src="http://doo-prime-static.oss-cn-hongkong.aliyuncs.com/ib_3.0/images/icon_warning.svg" />
            {{ $t("ib5.login.remoteLoginWarn") }}
        </div>
        <form @submit.prevent="login()">
            <div class="input-item">
                <label for="email">
                    <img src="http://doo-prime-static.oss-cn-hongkong.aliyuncs.com/ib_3.0/images/icon_user.svg" />
                </label>
                <input v-model="uname" type="text" id="email" :placeholder="$t('ib5.login.emailPlaceholder')">
            </div>
            <div class="input-item">
                <label for="pwd">
                    <img src="http://doo-prime-static.oss-cn-hongkong.aliyuncs.com/ib_3.0/images/icon_password.svg" />
                </label>
                <input v-model="password" type="password" id="pwd" :placeholder="$t('ib5.login.pwdPlaceholder')">
            </div>
            <div class="input-item">
                <label for="code">
                    <img src="http://doo-prime-static.oss-cn-hongkong.aliyuncs.com/ib_3.0/images/icon_Code.svg" />
                </label>
                <input v-model="verificationCode" type="text" id="code" :placeholder="$t('ib5.login.codePlaceholder')">
                <div class="code-img" @click="getVerificationCodeImg()">
                    <img :src="codeImgUrl" alt="">
                </div>
            </div>
            <div class="login-btn">
                <button :id="gtmButton.login2" class="btn-red" :disabled=disabled>{{ $t("ib5.login.loginBtn") }}</button>
            </div>
            <a :id="gtmButton.forgetPassword1" class="forget" :href="commonStore.crmResetPasswordUrl">{{
                $t("login.forgetPwd") }}</a>

            <p class="offsite-login">{{ $t("ib5.login.offsite") }}<a :href="commonStore.crmLoginUrl" target="_blank">{{
                $t("ib5.login.offsiteLink") }}</a>{{ $t("ib5.login.offsite2") }}</p>
        </form>
    </div>
</template>

<script lang="ts">
import { login, verificationCodeImg, crm } from '@/api/auth'
import { getCookie, setCookie } from '@/service/cookie'
import { useCommonStore } from '@/stores/common'
import { useAgentStore } from '@/stores/user'
export default {
    setup() {
        const commonStore = useCommonStore()
        const agentStore = useAgentStore()
        return {
            commonStore,
            agentStore
        }
    },

    props: {
        gtmButton: {
            default: {
                'login2': 'Login_2',
                'forgetPassword1': 'ForgetPassword_1',
            },
        }
    },

    data() {
        return {
            // uname: 'xj.hewxj@finpoints.com',
            // password: 'Testing@135',
            uname: '',
            password: '',
            verificationCode: '',
            showRequiredInfo: false,
            showErrorInfo: false,
            showErrorVerificationCode: false,
            showErrorRemoteLogin: false,
            disabled: false,

            codeImgUrl: '',

            geetest: this,
        }
    },
    methods: {
        async login() {
            // this.geetest.showBox()
            if (!this.uname || !this.password || !this.verificationCode) {
                this.showRequiredInfo = true
                return
            }
            let attempt = getCookie('attempt');
            this.showRequiredInfo = false
            this.disabled = true
            this.showErrorInfo = false
            this.showErrorVerificationCode = false
            this.showErrorRemoteLogin = false

            let data = {
                uname: this.uname.toLowerCase(),
                password: this.password,
                verificationCode: this.verificationCode
            }
            const apiData = await login(data);

            if (apiData.data.code === 0) {
                // login success，update uuid，save token
                this.agentStore.setUuid(apiData.data.data.uuid);
                this.agentStore.setIsLogin(true)
                this.commonStore.closeModal();
                location.reload();
            }
            else if (apiData.data.code === 2005) {
                this.showErrorInfo = true

            } else if (apiData.data.code === 2006) {
                this.showErrorVerificationCode = true
            } else if (apiData.data.code === 2922) {
                this.showErrorRemoteLogin = true
            }

            this.disabled = false
        },

        async getVerificationCodeImg() {
            const { data } = await verificationCodeImg();
            this.codeImgUrl = data;
        },


    },

    mounted() {
        this.getVerificationCodeImg();
        // 验证id:17f6a593be1d42322a35a2cf52f8f722  
        // 验证 Key:e34099fb81d6a64265138da9f1350a23
        let web = this;

        // initGeetest4(
        //     {
        //         // Omit required configuration parameters
        //         captchaId: "17f6a593be1d42322a35a2cf52f8f722",
        //         product: "bind",
        //     },
        //     function (captchaObj: any) {
        //         web.geetest = captchaObj;
        //         captchaObj
        //             .onReady(function () {
        //                 // please call the showCaptcha method to show the CAPTCHA when it is ready
        //             })
        //             .onSuccess(function () {
        //                 // your code, please reset the CAPTCHA based on you business
        //             })
        //             .onError(function () {
        //                 //your code
        //             });

        //         //press the button to submit event

        //         // button.click = function () {
        //         //     // some code

        //         //     // check if the CAPTCHA is ready, and if onReady is executed

        //         //     captchaObj.showCaptcha(); //show the CAPTCHA

        //         //     // some code
        //         // };
        //     }
        // );
    },
}
</script>

<style lang="scss">
.login {
    width: 100%;
    max-width: 320px !important;
    top: 15% !important;
    margin: auto;

    .title {
        font-family: MicrosoftYaHei;
        font-size: 24px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 2px;
        text-align: center;
        color: #14142a;
        margin-bottom: 24px;
    }

    .warn {
        height: 32px;
        font-size: 14px;
        color: #ee0a24;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 8px;
        padding: 6px 0;
        border-radius: 4px;
        background-color: rgba(238, 10, 36, 0.1);

        img {
            width: 16px;
            height: 16px;
        }
    }

    .input-item {
        margin-top: 16px;
        display: flex;
        align-items: center;

        label img {
            width: 24px;
            height: 24px;
            margin-right: 10px;
        }

        input {
            width: 0%;
            height: 40px;
            flex: 1;
            padding: 10px 0 10px 12px;
            border-radius: 4px;
            border: solid 1px #e5e5e5;

            &:focus {
                border-color: #4096ff;
                border-inline-end-width: 1px;
                outline: 0;
            }
        }

        ::placeholder {
            /* Chrome, Firefox, Opera, Safari 10.1+ */
            font-family: MicrosoftYaHei;
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.43;
            letter-spacing: normal;
            text-align: left;
            color: #c8c8cc;
            /* Firefox */
        }

        :-ms-input-placeholder {
            /* Internet Explorer 10-11 */
            font-family: MicrosoftYaHei;
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.43;
            letter-spacing: normal;
            text-align: left;
            color: #c8c8cc;
        }

        ::-ms-input-placeholder {
            /* Microsoft Edge */
            font-family: MicrosoftYaHei;
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.43;
            letter-spacing: normal;
            text-align: left;
            color: #c8c8cc;
        }

        .code-img {
            width: 112px;
            height: 38px;
            background-color: #fff;
            margin-left: 16px;
            border-radius: 4px;

            img {
                width: 100%;
                height: 100%;
            }
        }
    }

    .login-btn {
        margin-top: 24px;
    }

    .forget {
        margin: 16px 0;
        display: block;
        font-size: 14px;
        text-align: center;
        color: #ee0a24;
    }

    .offsite-login {
        margin: 0;
        font-size: 10px;

        a {
            text-decoration: underline;
            color: blue;
        }
    }
}
</style>