<template>
  <EventTabHeader :id="'2'" :title="this.$t('ib6.eventRanking.descriptionTitle')"
    :btnDetailLink="`${this.$i18n.locale}/rankDetail#section_event_rule`"
    :btnId="`${commonStore.currentEvent}_subpagerule2_visitor`">
    <div class="description">
      <p>{{ $t(`ib6.eventRanking.description1`) }} </p>
      <p>{{ $t(`ib6.eventRanking.rewardTitle`) }}</p>
    </div>
    <img :src="imageUrl + 'USD_50000.png'" class="pd-img" />
    <EventSatisticTime period="ib6.eventLevel.periodInGold" period2="ib6.eventLevel.period"
      condition="ib6.eventLevel.conditionTimeZone" title="ib6.eventTime.inGoldStatistic"
      title2="ib6.eventTime.OutGoldStatistic" />
    <EventInfoBtn :btnInfoTitle="this.$t('ib6.eventInfo.agent')" :btnInfoLink="`${this.$i18n.locale}/rankDetail`"
      :btn-id="`${commonStore.currentEvent}_subpage2_visitor`" />
  </EventTabHeader>
</template>

<script lang="ts">
import EventTabHeader from "@/views/ib6/home_event/EventTabHeader.vue";
import EventSatisticTime from "./EventSatisticTime.vue";
import EventInfoBtn from "@/components/button/EventInfoBtn.vue";
import { useCommonStore } from "@/stores/common";

export default {
  data() {
    return {
      imageUrl: "https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/ib_event/ib6/images/",
      commonStore: useCommonStore(),
    };
  },
  components: {
    EventTabHeader,
    EventSatisticTime,
    EventInfoBtn,
  },
};
</script>

<style lang="scss">
#tab_2 {

  .event_description {
    padding: 24px 22px 24px 23px;
  }

  .description {

    margin: 0 23px 16px;

    p {
      font-size: 14px;
      font-weight: 500;
      margin: 0;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #252525;
    }
  }

  .pd-img {
    width: 267px;
  }

  .satisticTime {
    margin: 35px 0;
  }
}
</style>
