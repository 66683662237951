<template>
    <div class="countdown">
        <div class="time-section" id="days">
            <div class="time-group">
                <div class="time-segment">
                    <div class="segment-display">
                        <div class="segment-display__top"></div>
                        <div class="segment-display__bottom"></div>
                        <div class="segment-overlay">
                            <div class="segment-overlay__top"></div>
                            <div class="segment-overlay__bottom"> </div>
                        </div>
                    </div>
                </div>
                <div class="time-segment">
                    <div class="segment-display">
                        <div class="segment-display__top"></div>
                        <div class="segment-display__bottom"></div>
                        <div class="segment-overlay">
                            <div class="segment-overlay__top"></div>
                            <div class="segment-overlay__bottom"></div>
                        </div>
                    </div>
                </div>
            </div>
            <p>{{ $t(`ib5.time.day`) }}</p>
        </div>
        <div class="colon">:</div>
        <div class="time-section" id="hours">
            <div class="time-group">
                <div class="time-segment">
                    <div class="segment-display">
                        <div class="segment-display__top"></div>
                        <div class="segment-display__bottom"></div>
                        <div class="segment-overlay">
                            <div class="segment-overlay__top"></div>
                            <div class="segment-overlay__bottom"></div>
                        </div>
                    </div>
                </div>
                <div class="time-segment">
                    <div class="segment-display">
                        <div class="segment-display__top"></div>
                        <div class="segment-display__bottom"></div>
                        <div class="segment-overlay">
                            <div class="segment-overlay__top"></div>
                            <div class="segment-overlay__bottom"></div>
                        </div>
                    </div>
                </div>
            </div>
            <p>{{ $t(`ib5.time.hours`) }}</p>
        </div>
        <div class="colon">:</div>
        <div class="time-section" id="minutes">
            <div class="time-group">
                <div class="time-segment">
                    <div class="segment-display">
                        <div class="segment-display__top"></div>
                        <div class="segment-display__bottom"></div>
                        <div class="segment-overlay">
                            <div class="segment-overlay__top"></div>
                            <div class="segment-overlay__bottom"></div>
                        </div>
                    </div>
                </div>
                <div class="time-segment">
                    <div class="segment-display">
                        <div class="segment-display__top"></div>
                        <div class="segment-display__bottom"></div>
                        <div class="segment-overlay">
                            <div class="segment-overlay__top"></div>
                            <div class="segment-overlay__bottom"></div>
                        </div>
                    </div>
                </div>
            </div>
            <p>{{ $t(`ib5.time.minutes`) }}</p>
        </div>
        <div :class="`colon seconds-colon ${show}`">:</div>
        <div :class="`time-section ${show}`" id="seconds" style="display:none;">
            <div class="time-group">
                <div class="time-segment">
                    <div class="segment-display">
                        <div class="segment-display__top"></div>
                        <div class="segment-display__bottom"></div>
                        <div class="segment-overlay">
                            <div class="segment-overlay__top"></div>
                            <div class="segment-overlay__bottom"></div>
                        </div>
                    </div>
                </div>
                <div class="time-segment">
                    <div class="segment-display">
                        <div class="segment-display__top"></div>
                        <div class="segment-display__bottom"></div>
                        <div class="segment-overlay">
                            <div class="segment-overlay__top"></div>
                            <div class="segment-overlay__bottom"></div>
                        </div>
                    </div>
                </div>
            </div>
            <p>{{ $t(`ib5.time.second`) }}</p>
        </div>
    </div>
</template>
<script lang="ts">
export default {
    props: {
        endDate: {
            type: String,
            required: true,
        },
        show: String
    },
    watch: {
        endDate: {
            handler(newValue, oldValue) {
                if (newValue == oldValue) {
                    return
                }
                this.countdownInit();
            },
            deep: true
        },
    },
    data() {
        return {
            targetDate: this.endDate,
            timeRemain: 0,
        }
    },
    methods: {
        getTimeSegmentElements(segmentElement: any) {
            const segmentDisplay = segmentElement.querySelector(
                '.segment-display'
            );
            const segmentDisplayTop = segmentDisplay.querySelector(
                '.segment-display__top'
            );
            const segmentDisplayBottom = segmentDisplay.querySelector(
                '.segment-display__bottom'
            );

            const segmentOverlay = segmentDisplay.querySelector(
                '.segment-overlay'
            );
            const segmentOverlayTop = segmentOverlay.querySelector(
                '.segment-overlay__top'
            );
            const segmentOverlayBottom = segmentOverlay.querySelector(
                '.segment-overlay__bottom'
            );

            return {
                segmentDisplayTop,
                segmentDisplayBottom,
                segmentOverlay,
                segmentOverlayTop,
                segmentOverlayBottom,
            };
        },
        updateSegmentValues(
            displayElement: any,
            overlayElement: any,
            value: any
        ) {

            displayElement.textContent = value;
            overlayElement.textContent = value;
        },
        updateTimeSegment(segmentElement: any, timeValue: any) {
            const updateSegmentValues = this.updateSegmentValues;
            const segmentElements =
                this.getTimeSegmentElements(segmentElement); // animation

            if (
                parseInt(
                    segmentElements.segmentDisplayTop.textContent,
                    10
                ) === timeValue
            ) {
                return;
            }

            segmentElements.segmentOverlay.classList.add('flip');

            this.updateSegmentValues(
                segmentElements.segmentDisplayTop,
                segmentElements.segmentOverlayBottom,
                timeValue
            );

            function finishAnimation() {
                segmentElements.segmentOverlay.classList.remove('flip');
                updateSegmentValues(
                    segmentElements.segmentDisplayBottom,
                    segmentElements.segmentOverlayTop,
                    timeValue
                );

                this.removeEventListener(
                    'animationend',
                    finishAnimation
                );
            }

            segmentElements.segmentOverlay.addEventListener(
                'animationend',
                finishAnimation
            );
        },
        updateTimeSection(sectionID: any, timeValue: any, getSeconds: any) {
            const firstNumber = Math.floor(timeValue / 10) || 0;
            const secondNumber = timeValue % 10 || 0;
            const sectionElement = document.getElementById(sectionID);
            const allColon = document.querySelectorAll<HTMLElement>(".colon");

            // if (this.timeRemain <= 60000) {
            //     getSeconds ? sectionElement.style.display = "" : sectionElement.style.display = "none";
            //     allColon.forEach(item => {
            //         item.style.display = "none";
            //     });
            // } else {
            //     getSeconds ? sectionElement.style.display = "none" : sectionElement.style.display = "";
            //     allColon.forEach(item => {
            //         item.style.display = "";
            //     });
            // }

            getSeconds ? sectionElement.style.display = "none" : sectionElement.style.display = "";
            allColon.forEach(item => {
                item.style.display = "";
            });

            if (sectionElement) {
                const timeSegments =
                    sectionElement.querySelectorAll('.time-segment');

                this.updateTimeSegment(timeSegments[0], firstNumber);
                this.updateTimeSegment(timeSegments[1], secondNumber);
            }

        },
        getTimeRemaining(tDate: any) {
            const nowTime = Date.now();
            this.timeRemain = tDate - nowTime;
            const complete = nowTime >= tDate;

            if (complete) {
                return {
                    complete,
                    seconds: 0,
                    minutes: 0,
                    hours: 0,
                    days: 0
                };
            }

            const days = Math.floor(this.timeRemain / (1000 * 60 * 60 * 24));
            const hours = Math.floor((this.timeRemain % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            const minutes = Math.floor((this.timeRemain % (1000 * 60 * 60)) / (1000 * 60));
            const seconds = Math.floor((this.timeRemain % (1000 * 60)) / 1000);

            return {
                complete,
                seconds,
                minutes,
                hours,
                days
            };

        },
        updateAllSegments(tDate: any) {

            let timeRemainingBits = { complete: false, seconds: 10, minutes: 10, hours: 0, days: 0 };
            if (tDate != undefined) {
                timeRemainingBits = this.getTimeRemaining(tDate);

                this.updateTimeSection('seconds', timeRemainingBits.seconds, "getSeconds");
                this.updateTimeSection('minutes', timeRemainingBits.minutes);
                this.updateTimeSection('hours', timeRemainingBits.hours);
                this.updateTimeSection('days', timeRemainingBits.days);
            }

            return timeRemainingBits.complete;
        },
        async countdownInit() {
            // Create Countdown
            this.targetDate = new Date(this.endDate).getTime();
            //const targetDate = new Date("Sep 30, 2023 23:59:59").getTime();
            //targetDate.setHours(targetDate.getHours() + 5);
            const countdownTimer = setInterval(async () => {
                const isComplete = await this.updateAllSegments(this.targetDate);

                if (isComplete) {
                    clearInterval(countdownTimer);
                }

            }, 1000);
            this.updateAllSegments();

        }

    },
    created() {
        this.countdownInit();
    },
    mounted() {
        //this.countdownInit();
    }
}
</script>

<style lang="scss">
.countdown {
    display: flex;
    justify-content: center;
    font-family: sans-serif;

    p {
        font-family: MicrosoftYaHeiBold;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.71;
        letter-spacing: normal;
        text-align: center;
        color: #757575;
        margin-top: 8px;
        margin-bottom: 40px;
    }
}

.colon {
    font-family: MicrosoftYaHeiBold;
    font-size: 28px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: normal;
    text-align: center;
    color: transparent;
    width: 16px;

    &.seconds-colon {
        display: none;

        &.show {
            display: block !important;
        }
    }
}

.time-section {
    text-align: center;
    font-size: 30px;

    &.show {
        display: block !important;
    }

    >p {
        margin-top: 6px !important;
    }

    .time-group {
        display: flex;
        gap: 4px;

        .time-segment {
            display: block;
            font-size: 28px;
            font-weight: 900;
            width: 32px;

            .segment-display {
                position: relative;
                height: 100%;
                box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
                border-radius: 2px;

                .segment-display__top,
                .segment-display__bottom {
                    overflow: hidden;
                    text-align: center;
                    width: 100%;
                    height: 50%;
                    position: relative;

                }

                .segment-display__top {
                    line-height: 1.5;
                    font-family: MicrosoftYaHeiBold;
                    font-size: 28px;
                    font-weight: 500;
                    font-stretch: normal;
                    font-style: normal;
                    color: #d91d22;
                    background-color: #fff;
                    border-radius: 5px 5px 0px 0px;
                }

                .segment-display__bottom {
                    line-height: 0;
                    font-family: MicrosoftYaHeiBold;
                    font-size: 28px;
                    font-weight: 500;
                    font-stretch: normal;
                    font-style: normal;
                    color: #d91d22;
                    background-color: #fff;
                    border-top: 1px solid #cdcdcd;
                    border-radius: 0px 0px 5px 5px;
                }

                .segment-overlay {
                    position: absolute;
                    top: 0;
                    perspective: 400px;
                    height: 100%;
                    width: 32px;
                }

                .segment-overlay__top,
                .segment-overlay__bottom {
                    position: absolute;
                    overflow: hidden;
                    text-align: center;
                    width: 100%;
                    height: 50%;
                }

                .segment-overlay__top {
                    top: 0;
                    line-height: 1.5;
                    font-family: MicrosoftYaHeiBold;
                    font-size: 28px;
                    font-weight: 500;
                    font-stretch: normal;
                    font-style: normal;
                    color: #d91d22;
                    background-color: #fff;
                    transform-origin: bottom;
                    border-radius: 5px 5px 0px 0px;
                }

                .segment-overlay__bottom {
                    bottom: 0;
                    line-height: 0;
                    font-family: MicrosoftYaHeiBold;
                    font-size: 28px;
                    font-weight: 500;
                    font-stretch: normal;
                    font-style: normal;
                    color: #d91d22;
                    background-color: #fff;
                    border-top: 1px solid #ebebeb;
                    transform-origin: top;
                    border-radius: 0px 0px 5px 5px;
                }

                .segment-overlay.flip .segment-overlay__top {
                    animation: flip-top 0.8s linear;
                }

                .segment-overlay.flip .segment-overlay__bottom {
                    animation: flip-bottom 0.8s linear;
                }

                @keyframes flip-top {
                    0% {
                        transform: rotateX(0deg);
                    }

                    50%,
                    100% {
                        transform: rotateX(-90deg);
                    }
                }

                @keyframes flip-bottom {

                    0%,
                    50% {
                        transform: rotateX(90deg);
                    }

                    100% {
                        transform: rotateX(0deg);
                    }
                }
            }
        }
    }
}
</style>