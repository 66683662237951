import api from "./config";

const baseUrl = "/footer";

export const footer = async (data: Object) => {
  try {
    const payload = await api({
      method: "get",
      url: baseUrl,
      params: data,
    });
    return payload;
  } catch (e) {
    return e;
  }
};
