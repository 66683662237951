<template>
    <div class="modal-content">
        <div v-if="rankData.removeRankId !== 0">
            Are you sure you want to remove this?
            <button @click="removeRank()" class="btn-remove-confirm">Yes</button>
            <button @click="closeRemoveModal()" class="btn-cancel">No</button>
        </div>
        <div v-else>
            <h2>
                <img v-if="formType == 'formUpdate'" src="@/assets/kol/icon-edit.png" class="icon-edit"/>
                <font v-else>Create New Rank</font>
            </h2>
            <FormKit id="formRank" class="form" type="form" @submit="handleSubmit"
                :submit-attrs="{
                    inputClass: 'btn red submit'
                }"
                submit-label="Submit"
                incomplete-message="Please fill up all the fields."
            >
                <FormKit
                    type="text"
                    name="ranking"
                    label="Ranking Title:"
                    :value="defaultContent[0]?.ranking"
                    :classes="{
                        input: 'ranking',
                    }"
                    placeholder="Ranking"
                    validation="required"
                />
                <FormKit
                    type="text"
                    name="rankName"
                    label="Rank Name:"
                    :value="defaultContent[0]?.name"
                    :classes="{
                        input: 'rankName',
                    }"
                    placeholder="rankName"
                    validation="required"
                />
            </FormKit>
            <div class="submit-message fail">
                Failed to update.
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import {ref, inject } from 'vue'
import { useCommonStore } from '@/stores/common'
import { updateRank } from "@/api/kol";
import { createNewRank } from "@/api/kol";
import { deleteRank } from "@/api/kol";
import "@formkit/themes/genesis";
import { useRankStore } from '@/stores/rankData'
export default {
    name: "RankModal",
    setup() {
        const commonStore = useCommonStore()
        const rankData = useRankStore()
        const rankGeneratorFunc = inject('rankGeneratorFunc')
        const defaultContent = rankData.selectedCardContent;
        const formType = rankData.formType;

        return {
            commonStore,
            rankData,
            rankGeneratorFunc,
            defaultContent,
            formType
        }
    },
    data() {
        return {
            formSubmit: false,
        }
    },
    methods: {
        async handleSubmit(formValues: any) {
            const messageSuccess = document.querySelector(".submit-message.success");
            const currentCardFail = document.querySelectorAll(".submit-message.fail");

            formValues["rankId"] = this.rankData.currentRankId;
            formValues["updated_by"] = this.rankData.user;

            const response = await ( this.formType ==  "formUpdate" ? updateRank(formValues) : createNewRank(formValues) )
            .then(() => {
                this.formSubmit = ref(true);
                this.rankGeneratorFunc();
                currentCardFail.forEach(item => {
                    item.classList.remove('active');
                })
                
                messageSuccess.classList.add("active");
                setTimeout(() => {
                    messageSuccess.classList.remove('active');
                    this.$formkit.reset('formRank');
                }, 5000);

                this.commonStore.closeModal();
            })
            .catch((error) => {
                this.errors = error.response.errors;
                currentCardFail.forEach(item => {
                    item.classList.add("active");
                })
                messageSuccess.classList.remove('active');
                setTimeout(() => {
                    currentCardFail.forEach(item => {
                    item.classList.add("active");
                })
                }, 500);
            });
            
        },
        async removeRank() {
            const messageSuccess = document.querySelector(".submit-message.success");
            await deleteRank(this.rankData.removeRankId);
            this.rankGeneratorFunc();
            this.commonStore.closeModal();
            messageSuccess.classList.add("active");
            setTimeout(() => {
                messageSuccess.classList.remove('active');
            }, 5000);
            setTimeout(() => {
                this.rankData.setRemoveRankId(0)
            }, 500);
        },
        closeRemoveModal() {
            this.commonStore.closeModal();
            setTimeout(() => {
                this.rankData.setRemoveRankId(0)
            }, 500);
        },
        setId(target:any) {
            target.addEventListener("click",  () => {
                setTimeout(() => {
                    this.rankData.setRemoveRankId(0)
                }, 500);
            });
        },
        modalOverlay() {
            const btnClose = document.querySelector(".modal-btn-close");
            const modalOverlay = document.querySelector(".modal-overlay");

            this.setId(btnClose);
            this.setId(modalOverlay);
            
        }
    },
    mounted() {
        this.modalOverlay();
    },
}
</script>

<style lang="scss" scoped>
    h2 {
        opacity: 0.6;
    }
    .icon-edit {
        width: 23px;
        display:inline-block;
        vertical-align: -2px;
        margin-right: 8px;
    }
    .btn-remove-confirm, .btn-cancel {
        font-size: 14px;
        padding: 11px 28px;
        border: 0;
        border-radius: 10px;
        margin: 10px 5px 0;
        width: 45%;
    }
    .btn-remove-confirm {
        background:#4e61ff;
        color:#fff;
    }
    .btn-cancel {
        border: 1px solid #afafaf;
    }
</style>