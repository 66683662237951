<template>
    <div id="kol-page">
        <section class="banner">
            <div class="top">
                <a href="https://www.dooprimeaffiliates.com/" class="logo"><img :src="imageUrl + 'Dooprime.svg'" alt="Doo Prime" data-aos="fade-up" data-aos-duration="800"></a>
                <a v-if="showJoinBtn" href="https://partner.dooprime.com/signup/L3164982" class="join">Join Now</a>
            </div>
            <div class="title">
                <img src="@/assets/kol/KOL_MONTHLY_REWARDS@3x.png" alt="KOL MONTHLY REWARDS"/>
            </div>
        </section>
        <section class="match_section">

            <div v-if="beforeMatch" class="titleTop">
                <h2>Upcoming Match</h2>
                <img src="@/assets/kol/red_packets.png"/>
            </div>
            <div v-else-if="matchOnGoing" class="titleTop">
                <h2>ON GOING MATCH</h2>
                <img src="@/assets/kol/red_packets.png"/>
            </div>
            <div v-else class="titleTop">
                <h2>Match Ended</h2>
                <img src="@/assets/kol/red_packets.png"/>
            </div>

            <div class="content">

                <p v-if="beforeMatch" style="margin:0;"></p>
                <p v-else-if="matchOnGoing">Every eligible customer can receive a Bonus by logging into this page during the specific time period.</p>
                <h3 v-else>Thank You for Participating!</h3>

                <div class="countdown" v-if="matchOnGoing" :class="`beforeMatch` + beforeMatch">
                    <flipCountDown :endDate="endDate" />
                </div>

                <ul class="matchList">
                    <li :class="`card ${match.status}`"  v-for="(match, index) in allMatch" :key="index">
                        <div class="match_teams">
                            <img src="@/assets/kol/logo-man-utd_new.png" /> VS <img :src=" getImageUrl(match.awayTeam)" />
                        </div>
                        <h3>{{match.matchTitle}}</h3>
                        <div class="match_date">
                            Match Date: <span>{{match.matchDate}}</span>
                        </div>
                        <div class="period">
                            {{match.timePeriod}}
                        </div>
                    </li>
                </ul>
            </div>
        </section>
        <section class="ranking" v-if="allRank.length > 0">

            <div class="titleTop">
                <h2>RANKING ON THE GO</h2>
                <img src="@/assets/kol/trophy.png"/>
            </div>

            <div class="content">
                <div  class="card flex-custom" v-for="(item, index) in allRank" :key="index">
                    <img src="@/assets/kol/gold_wreath-02.png" />
                    <p class="title" v-html="item.title">
                    </p>
                    <img src="@/assets/kol/gold_wreath-03.png" />
                </div>
            </div>
        </section>
        <section class="event_rules">
            <div class="container">
                <h3>EVENT RULES</h3>
                <ol>
                    <li>Data calculated until last day of each month, winners notified by or before the 15th of the following month.</li>
                    <li>Event runs from Aug 1, 2023, 00:00, to Dec 31, 2023, 23:59. Rewards given to KOL and customer/fan designated by KOL.</li>
                    <li>Valid customer: registers using KOL's referral link, deposits≥$100, and trades ≥ 0.5 lot.</li>
                    <li>Trading volume excludes cent accounts, PAMM main accounts, cryptocurrency, US stock, and HK stock trades.</li>
                    <li>KOLs can win multiple goals simultaneously.</li>
                    <li>Doo Prime audits determine customer trading volume.</li>
                    <li>Fairness ensured; Doo Prime reserves right to deduct rewards and disqualify participants in case of abuse, fraud, or inappropriate behavior.</li>
                    <li>Doo Prime has absolute right to change, modify, or revoke terms and conditions, including rules, duration, gifts, and bonus amounts.</li>
                    <li>Event may be canceled, terminated, postponed, or suspended without notice. Physical delivery depends on logistics in respective countries/regions.</li>
                    <li>Equivalent USD rewards provided if local purchase not possible, subject to Doo Prime's value. Customer responsible for import duties or taxes, if applicable.</li>
                </ol>
                <div v-if="viewMore" class="more less" @click="expand" >View Less <i></i></div>
                <div v-else class="more" @click="expand" >View More <i></i></div>
            </div>
        </section>
        <section class="top_notch_trading_enviroment">
            <h2>Top-Notch<br>Trading Environment</h2>
            <p>Conquer Global Investment Transactions With Doo Prime</p>
            <div class="carousel-container">
                <swiper style="--swiper-pagination-color: #de2026" :slidesPerView="1" :autoplay="(true as any)" :centeredSlides="(true as any)"
                    :pagination="(pagination as any)" :spaceBetween="2" :loop="(true as any)" :modules="modules" class="mySwiper">
                    <swiper-slide>
                        <img src="http://doo-prime-static.oss-cn-hongkong.aliyuncs.com/mid-year/Image_regulations.png">
                        <h4>Multiple Strict Regulations</h4>
                        <p>Doo Prime is strictly regulated by multinational financial institutions such as Seychelles FSA, Mauritius FSC and Vanuatu FSC.</p>
                    </swiper-slide>
                    <swiper-slide>
                        <img src="http://doo-prime-static.oss-cn-hongkong.aliyuncs.com/mid-year/Image_cfd.png">
                        <h4>> 10,000 Trading Products</h4>
                        <p>Enjoy endless trading opportunities with access to more than 10,000 CFDs</p>
                    </swiper-slide>
                    <swiper-slide>
                        <img src="http://doo-prime-static.oss-cn-hongkong.aliyuncs.com/mid-year/Image_spread.png">
                        <h4>Ultra-Low Spreads</h4>
                        <p>We offer minimized transactions costs with tight spreads as low as 0.1 pips.</p>
                    </swiper-slide>
                    <swiper-slide>
                        <img src="http://doo-prime-static.oss-cn-hongkong.aliyuncs.com/mid-year/Image_leverage.png">
                        <h4>1:1000 Leverage</h4>
                        <p>Start trading with minimal capital and maximize your trading profits with flexible leverage of up to 1:1000.</p>
                    </swiper-slide>
                    <swiper-slide>
                        <img src="http://doo-prime-static.oss-cn-hongkong.aliyuncs.com/mid-year/Image_speed.png">
                        <h4>Ultra Low Latency</h4>
                        <p>Robust IT infrastructure and network by Equinix, enabling 99.5% of trade transactions done in 50 ms.</p>
                    </swiper-slide>
                    <swiper-slide>
                        <img src="http://doo-prime-static.oss-cn-hongkong.aliyuncs.com/mid-year/Image_support.png">
                        <h4>24/7/365<br>Professional Support</h4>
                        <p>Doo Prime boasts a dedicated 500+ strong team of trained professionals to provide 365 days of top-of-the-line support</p>
                    </swiper-slide>

                </swiper>
            </div>
        </section>
        <section class="email-section">
            <h5>Contact Us</h5>
            <a href = "mailto: info.kol@dooprime.net"><img src="@/assets/kol/Email.svg"/></a>
        </section>
        <Footer></Footer>
    </div>
</template>
  
<script lang="ts">
    import { getRankList } from "@/api/kol";
    import { getLocation } from '@/api/location'
    import { ref, computed, onMounted } from 'vue'
    // Import Swiper Vue.js components
    import { Swiper, SwiperSlide } from 'swiper/vue';
    // import required modules
    import { Navigation, Pagination, Autoplay, Controller } from 'swiper';

    // Import Swiper styles
    import 'swiper/css';

    import 'swiper/css/navigation';
    import 'swiper/css/pagination';
    import 'swiper/css/controller';
    import 'swiper/css/autoplay';

    import Countdown from '@/components/Countdown.vue'
    import flipCountDown from '@/components/flipCountDown.vue'

    import { useRankStore } from '@/stores/rankData'

    import Footer from '@/components/Footer/Footer.vue';
    
    // @ is an alias to /src
    
    export default {
        name: 'Kol',
        components: {
            Swiper,
            SwiperSlide,
            Countdown,
            flipCountDown,
            Footer,
        },
        computed: {
            errorCode() {
                return this.$store.getters.getError
            }
        },
        setup() {
            const rankData = useRankStore();
            const allRank = ref([]);
            
            const beforeMatch = ref(false);
            const matchOnGoing = ref(true);
            const endDate = ref("");
            const allMatch = [
                {
                    status: "default",
                    awayTeam: "logo-crystal-palace",
                    matchTitle: "Manchester United vs Crystal Palace",
                    matchDate: "30 Sept 2023, 15:00",
                    timePeriod: "1 - 31 AUG 2023, 23:59:59 UTC+0",
                    endDate: "AUG 31, 2023 23:59:59"
                },
                {
                    status: "default",
                    awayTeam: "logo-man-city_new",
                    matchTitle: "Manchester United vs Man City",
                    matchDate: "28 OCT 2023, 15:00",
                    timePeriod: "1 - 30 SEPT 2023, 23:59:59 UTC+0",
                    endDate: "SEP 30, 2023 23:59:59"
                },
                {
                    status: "default",
                    awayTeam: "logo-chelsea_new",
                    matchTitle: "Manchester United vs Chelsea",
                    matchDate: "06 Dec 2023, 20:00",
                    timePeriod: "1 - 31 OCT 2023, 23:59:59 UTC+0",
                    endDate: "OCT 31, 2023 23:59:59"
                },
                {
                    status: "default",
                    awayTeam: "logo-tottenham_new",
                    matchTitle: "Manchester United vs Tottenham",
                    matchDate: "13 JAN 2024, 15:00",
                    timePeriod: "1 - 30 NOV 2023, 23:59:59 UTC+0",
                    endDate: "NOV 30, 2023 23:59:59"
                },
                {
                    status: "default",
                    awayTeam: "logo-westham_new",
                    matchTitle: "Manchester United vs Westham",
                    matchDate: "03 Feb 2024, 15:00",
                    timePeriod: "1 - 31 DEC 2023, 23:59:59 UTC+0",
                    endDate: "DEC 31, 2023 23:59:59"
                }
            ];

            function checkMatchStatus() {

                if( new Date("Jul 31, 2023 23:59:59").getTime() > Date.now() ) {
                    beforeMatch.value = true;
                    return
                } else {
                    beforeMatch.value = false;
                }

                if(matchOnGoing.value == false){
                    return
                }

                for(let i=0; i<allMatch.length; i++){
                    let currentDate = Date.now();

                    const eachDate = new Date(allMatch[i].endDate);
                    if(endDate.value == ""){
                        allMatch[i].status = "onGoing";
                        endDate.value = "AUG 31, 2023 23:59:59";
                        matchOnGoing.value = true;
                    }

                    if( eachDate.getTime() >= currentDate) {
                        allMatch[i].status = "onGoing";
                        endDate.value = allMatch[i].endDate;
                        matchOnGoing.value = true;
                        break;
                    } else {
                        allMatch[i].status = "end";
                        matchOnGoing.value = false;
                    }
                }
            }

            const rankGenerator = async () => {
                await rankData.getAllRankData();
                allRank.value = rankData.allRank;
            }

            onMounted(() => {
                checkMatchStatus(); 
                setInterval(checkMatchStatus, 1000);
            });
            
            return {
                modules: [Navigation, Pagination, Autoplay, Controller],
                endDate,
                allMatch,
                matchOnGoing,
                beforeMatch,
                rankGenerator,
                allRank
            };
        },
        data() {
            return {
                showJoinBtn:true,
                imageUrl: "http://doo-prime-static.oss-cn-hongkong.aliyuncs.com/ib_3.0/images/",
                pagination: { clickable: true as any},
                listHeightCustom:0,
                viewMore: false,
            }
        },
        methods: {
            async hideLoginBtn() {
                const { data } = await getLocation();
                const location = data?.data.country_code;

                if(location == "CN"){
                    this.showJoinBtn = false;
                }
            },
            getImageUrl(name : any) {
                return new URL(`../assets/kol/${name}.png`, import.meta.url).href
            },
            heightInit() {
                const listwrapper: Element | null = document.querySelector(".event_rules ol");
                const listHeightNodes: NodeListOf<HTMLElement> = document.querySelectorAll(".event_rules ol > li");

                if (listwrapper instanceof HTMLElement) {
                    let listHeightCustom = 0;
                    for (let i = 0; i < Math.min(3, listHeightNodes.length); i++) {
                        listHeightCustom += listHeightNodes[i].offsetHeight;
                    }
                    this.listHeightCustom = listHeightCustom;
                    listwrapper.style.maxHeight = listHeightCustom + "px";
                }
            },
            expand() {
                const list : Element | null = document.querySelector(".event_rules ol");
                let moreBtn : Element | null = document.querySelector(".more");

                this.viewMore = !this.viewMore;
                moreBtn.classList.toggle("active");

                if (list instanceof HTMLElement) {
                    if (list.style.maxHeight !== this.listHeightCustom + "px"){
                        list.style.maxHeight = this.listHeightCustom + "px";
                    } else {
                        list.style.maxHeight = list.scrollHeight + 24 + "px";
                    } 
                }
            },
            async getRankList(data: string, amount: string) {
                const listData = await getRankList();

                for(let i=0; i<listData.data.length; i++){
                    let dataFormat = {
                        title: `${listData.data[i].ranking} <span class='name'>${listData.data[i].name}</span>`,
                    }
                    this.allRank.push(dataFormat)
                }
            },
        },
        mounted() {
            this.heightInit();
            this.hideLoginBtn();
            this.getRankList();
            this.rankGenerator()
        }
    }
</script>
  
  
<style lang="scss">
    body {
        margin: 0;
        background-color: #eff1f3;
    }
    #kol-page {
        max-width: 600px;
        margin: auto;
        background-color: #eff1f3;
        overflow-x: hidden;
        position: relative;

        

        .doo-carousel {
            background:#fff;
            padding: 64px 0 80px;
        }

        .banner {
            background:url('../assets/kol/Header_Temp_with_coin_new.png') no-repeat center top;
            background-size:640px;
            height:680px;
            padding-bottom:0;
            .top {
                position: absolute;
                inset: inherit;
                width: 100%;
                display: flex;
                justify-content: space-between;
                padding:22px 16px;
                .logo {
                    max-width:135px;
                    width:100%;
                }
                .join {
                    background:#d91d22;
                    font-family: SegoeUI;
                    font-size: 14px;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.71;
                    letter-spacing: normal;
                    text-align: center;
                    color: #fff;
                    padding:5px 8px;
                    border-radius: 4px;
                }
            }
            .title {
                padding-top:136px;
                img {
                    max-width: 480px;
                    width: 100%;
                    padding: 0 45px;
                }
            }
        }

        .titleTop {
            position: relative;
            h2 {
                font-family: Oswald;
                font-size: 28px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.29;
                letter-spacing: normal;
                color: #fff;
                padding:6px 16px;
                border-radius:4px;
                max-width: 230px;
                background: rgb(217,29,34);
                background: linear-gradient(0deg, rgba(217,29,34,1) 0%, rgba(253,87,96,1) 100%);
                position:relative;
                z-index:0;
                &:after {
                    content:'';
                    width:30px;
                    height:100%;
                    position:absolute;
                    right:0;
                    top:0;
                    transform-origin: top right;
                    transform: skew(26deg, 0deg);
                    background: rgb(217,29,34);
                    background: linear-gradient(0deg, rgba(217,29,34,1) 0%, rgba(253,87,96,1) 100%);
                    border-radius:4px;
                    z-index:-1;
                }
            }
            img {
                max-width:99px;
                position: absolute;
                right: 0;
                bottom: -17px;
            }

            ~ .content {
                background:#fff;
                padding:24px 16px;
                position: relative;
                z-index:1;
                border-radius:4px;
            }
        }

        .card {
            padding: 16px 16px 20px;
            background: #fff;
            box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
            border-radius: 5px;
            border:1px solid transparent;
            margin-bottom:8px;
            &:last-child{
                margin-bottom:0;
            }
        }

        /*** Sections ***/

        .match_section {
            padding:0 16px;
            margin-bottom:64px;
            .content {
                > p {
                    margin:0;
                    font-family: SegoeUI;
                    font-size: 16px;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.5;
                    letter-spacing: normal;
                    text-align: center;
                    color: var(--3-36383-b);
                    margin-bottom:24px;
                }
                h3 {
                    margin-top: 0;
                }
                .beforeMatchtrue {display:none;}
            }
            
            .matchList {
                padding:0;
                margin:0;
                h3 {
                    text-transform: uppercase;
                    display:none;
                }

                .onGoing {
                    border-color: #ff0000;
                    box-shadow: 0 4px 16px 0 rgba(217, 29, 34, 0.25);
                    h3 {
                        display:block;
                    }
                    .match_teams {
                        img {
                            width:64px;
                            height:64px;
                        }
                    }
                }

                .end {
                    -webkit-filter: grayscale(100%);
                    filter: grayscale(100%);
                    background: #e7e7e7;
                    box-shadow: inset 0 0 16px 0 rgba(0, 0, 0, 0.1);
                    background-color: #eeeeef;
                    .period {
                        background:#a5a5a7;
                    }
                }

                .match_teams {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    gap: 12px;
                    margin-bottom:12px;
                    font-family: Oswald;
                    font-size: 14px;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.71;
                    letter-spacing: normal;
                    text-align: center;
                    color: #757575;
                    img {
                        width:48px;
                        height:48px;
                    }
                }
                h3 {
                    font-family: Oswald;
                    font-size: 18px;
                    font-weight: 500;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.44;
                    letter-spacing: normal;
                    text-align: center;
                    color: #000018;
                    margin-bottom:4px;
                }
                .match_date {
                    font-family: SegoeUI;
                    font-size: 14px;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.71;
                    letter-spacing: normal;
                    color: #757575;
                    margin-bottom:8px;
                    span{
                        font-family: Oswald;
                    }
                }
                .period {
                    font-family: Oswald;
                    font-size: 14px;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.43;
                    letter-spacing: normal;
                    text-align: center;
                    color: #fff;
                    padding:4px 14px;
                    background: rgb(217,29,34);
                    background: linear-gradient(0deg, rgba(217,29,34,1) 0%, rgba(253,87,96,1) 100%);
                    border-radius:20px;
                    max-width:220px;
                    margin:auto;
                }
            }
        }

        .ranking {
            padding:0 16px;
            margin-bottom:64px;
            -display:none;
            .titleTop {
                h2 {
                    max-width: 260px;
                }
                img {
                    bottom: -25px;
                }
            }
            .flex-custom {
                display:flex;
                justify-content: space-around;
                align-items: center;
                padding: 16px 32px;
                img {
                    max-width: 36px;
                }
            }
            
            p.title {
                font-family: SegoeUI;
                font-size: 16px;
                font-weight: bold;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.5;
                letter-spacing: normal;
                text-align: center;
                color: #d91d22;
                margin:0;
                max-width:260px;
                width:100%;
                .name {
                    font-family: Oswald;
                    font-size: 18px;
                    font-weight: 500;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.44;
                    letter-spacing: normal;
                    text-align: center;
                    color: #000018;
                    display: block;
                    margin-top:4px;
                    text-transform:uppercase;
                }
            }
        }

        .event_rules {
            padding:0 16px;
            margin-bottom:64px;
            h3 {
                font-family: Oswald;
                margin-top:0
            }
            .container {
                background:#fff;
                padding:24px 16px 20px;
                border-radius: 4px;
            }
            ol {
                text-align: left;
                padding-left: 25px;
                max-height:100px;
                transition: all 0.2s linear;
                overflow:hidden;
                li {
                    font-family: SegoeUI;
                    font-size: 14px;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.43;
                    letter-spacing: normal;
                    color: var(--3-36383-b);
                    padding-left: 10px;
                }
            }
            .more {
                margin-top:16px;
                padding-top:16px;
                border-top:1px solid #e4e4e4;
                font-family: SegoeUI;
                font-size: 14px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.43;
                letter-spacing: normal;
                text-align: center;
                color: #d91d22;
                cursor:pointer;
                &:hover, &:focus {
                    -webkit-tap-highlight-color: transparent;
                    -webkit-touch-callout: none;
                    -webkit-user-select: none;
                    -khtml-user-select: none;
                    -moz-user-select: none;
                    -ms-user-select: none;
                    user-select: none;
                    outline:none;
                }
                &.less {
                    i {
                        transform: rotate(180deg);
                    }
                }
                i {
                    width:16px;
                    height:16px;
                    border: 1px solid #ee0a24;
                    border-radius:20px;
                    display:inline-block;
                    vertical-align: -2px;
                    position:relative;
                    margin-left:8px;
                    &:after {
                        content:'';
                        width:4px;
                        height:4px;
                        border:1px solid #ee0a24;
                        border-top:transparent;
                        border-left:transparent;
                        transform:rotate(45deg);
                        position:absolute;
                        top:50%;
                        left:50%;
                        margin-left: -2.5px;
                        margin-top: -3px;
                    }
                }
            }
        }

        .top_notch_trading_enviroment {
            padding: 64px 32px 80px;
            background-color: #fff;
            h2 {
                font-family: Oswald;
                font-size: 28px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.29;
                letter-spacing: normal;
                text-align: center;
                color: #000018;
                padding:0;
                margin: 0 0 16px;
                text-transform: uppercase;
            }
            > p {
                font-family: SegoeUI;
                font-size: 16px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.5;
                letter-spacing: normal;
                text-align: center;
                color: var(--3-36383-b);
                margin-bottom:24px;
            }
            .carousel-container {
                img{
                    max-width:280px;
                    margin-bottom:8px;
                }
                h4{
                    font-family: SegoeUI;
                    font-size: 20px;
                    font-weight: bold;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.5;
                    letter-spacing: normal;
                    text-align: center;
                    color: #000018;
                    margin: 0 0 16px;
                }
                p{
                    font-family: SegoeUI;
                    font-size: 16px;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.5;
                    letter-spacing: normal;
                    text-align: center;
                    color: var(--3-36383-b);
                    margin-bottom:32px;
                }
                .swiper-pagination {
                    bottom:-3px !important
                }
                .swiper-pagination-bullet {
                    width:16px;
                    height:2px;
                    border-radius:2px;
                }

            }
        }

        .email-section {
            padding:40px 24px;
            text-align: left;
            background:#f7f8f9;
            h5 {
                font-family: SegoeUI;
                font-size: 16px;
                font-weight: bold;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.5;
                letter-spacing: normal;
                margin:0;
                margin-bottom:16px;
            }
            img {
                width:19px;
            }
        }

        #footer {
            .container {
                padding:0;
            }
            #footer-top {
                background-color: transparent;
                display: none;
            }
            #footer-middle {
                padding: 24px 24px 40px;
            }

            #footer-bottom {
                padding: 24px 24px;
                text-align: left;
            }
        }        
    }
    
    
</style>