<template>
  <div class="comingSoonContainer">
    <div class="comingSoonTemplate">
      <div class="comingSoonHeader"></div>
      <div class="comingSoonContent"></div>
    </div>
    <div class="comingSoonDescription">
      <p class="description10" v-html="$t(`ib6.eventInfo.comingsoon`)"></p>
    </div>
  </div>
</template>

<script lang="ts">
import EventTabHeader from "@/views/ib6/home_event/EventTabHeader.vue";

export default {
  data(){
    return{
      imageUrl: "https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/ib_event/ib6/images/",
    }
  },
  components: {
    EventTabHeader,
  },
};
</script>

<style lang="scss">
.comingSoonContainer {
  position: relative;

  .comingSoonTemplate {

    filter: blur(3px);
    
    .comingSoonHeader {
      position: relative;
      z-index: 9;
      height: 68px;
      width: 100%;
      border-radius: 12px;
      box-shadow: 0 2px 8px 0 rgba(151, 151, 151, 0.3);
      border: solid 1px #fff;
      background-image: linear-gradient(
        6deg,
        #e3cfa1 50%,
        #fff9eb 100%,
        #fff3d8 62%,
        #dfc894 5%
      );
    }

    .comingSoonContent {
      position: relative;
      z-index: 10;
      margin-top: -20px;
      border-radius: 12px;
      width: 100%;
      background-color: rgb(246, 241, 232);
      height: 133px;
    }
  }

  .comingSoonDescription {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    .description10 {
      width: 105px;
      height: 44px;
    }
    
    p {
      font-size: 16px;
      font-weight: 900;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #644415;
    }
  }
}
</style>