<template>
    <div id="agentResult">

        <div class="result">
            <div v-if="eventStatus == 'status2'">
                <div class="title_wrapper">
                    <img :src="imageUrl + 'countdown.png'" alt="">
                    <p>{{ $t('ib6.eventDate.countDownTitle') }}</p>
                </div>
                <CountdownNoFlipWithBackground :deadlineAP="dateToCount" />
            </div>
            <div v-if="eventStatus == 'status3'" class="time_wrapper">
                <p class="title">{{ $t(`ib6.eventDate.pendingTitle`) }}</p>
                <p class="content">{{ $t('ib6.eventDate.pendingContent') }}</p>
            </div>
            <div v-if="eventStatus == 'status4' || eventStatus == 'status5'" class="time_wrapper">
                <p class="title">{{ $t('ib6.eventDate.eventEndTitle') }}</p>
                <p class="sub_title">{{ $t('ib6.eventDate.eventEndSubTitle') }}</p>
                <p class="content">{{ $t('ib6.eventDate.eventEndContent') }}</p>
            </div>

            <div class="progressbar-a">
                <div class="progressbar-wrapper" data-aos="fade-up" data-aos-duration="800">
                    <div class="progressbar" :data-percent="timeline_value">
                    </div>
                    <div class="dot1">
                        <div class="item"><i></i></div>
                        <div class="item"><i></i></div>
                        <div class="item"><i></i></div>
                        <div class="item"><i></i></div>
                    </div>
                </div>

                <div class="event_timeline" data-aos="fade-up" data-aos-duration="800">
                    <p class="event_timeline_title">{{ $t("ib6.eventDate.annualTimelineStatus1") }}<span
                            class="arrow"></span></p>
                    <p class="event_timeline_title">{{ $t("ib6.eventDate.annualTimelineStatus2") }}<span
                            class="arrow"></span></p>
                    <p class="event_timeline_title">{{ $t("ib6.eventDate.annualTimelineStatus3") }}<span
                            class="arrow"></span></p>
                    <p class="event_timeline_title">{{ $t("ib6.eventDate.annualTimelineStatus4") }}<span class="arrow">
                        </span></p>
                </div>
                <div class="event_timeline" data-aos="fade-up" data-aos-duration="800">
                    <div class="event_timeline_wrapper">
                        <p class="event_timeline_date">{{ $t("ib6.eventDate.period1_1") }}</p>
                        <div class="vertical_line"></div>
                        <p class="event_timeline_date">{{ $t("ib6.eventDate.period1_2") }}</p>
                    </div>
                    <div class="event_timeline_wrapper">
                        <p class="event_timeline_date">{{ $t("ib6.eventDate.period2_1") }}</p>
                        <div class="vertical_line"></div>
                        <p class="event_timeline_date">{{ $t("ib6.eventDate.period2_2") }}</p>
                    </div>
                    <div class="event_timeline_wrapper">
                        <p class="event_timeline_date">{{ $t("ib6.eventDate.period3_1") }}</p>
                    </div>
                    <div class="event_timeline_wrapper">
                        <p class="event_timeline_date">{{ $t("ib6.eventDate.period4_1") }}</p>
                        <div class="vertical_line"></div>
                        <p class="event_timeline_date">{{ $t("ib6.eventDate.period4_2") }}</p>
                    </div>
                </div>
            </div>

            <div class="level_wrapper">
                <p class="title">{{ $t('ib6.monthlyStatistics.subTitle') }}</p>
                <div :class="`prize ${verifyPrizeClass()}`">
                    <div class="cover_left"></div>
                    <div class="cover_right"></div>
                    <template v-for="(item, index) in resultApiData.prizeList" :key="index">
                        <div
                            :class="(resultApiData.prizeList.length == 3 && index === 1) ? `prize_wrapper active prize${index}` : `prize_wrapper prize${index}`">
                            <img class="bg_level" :src="imageUrl + `LV${item.level}.png`">
                            <img class="bg_prize" :src="imageUrl + 'prize.png'">
                            <p>{{ $t('ib6.monthlyStatistics.prizeTitle') }} <span>USD {{ item.gifts[0].giftName }}</span>
                            </p>
                            <div class="prize_info_wrapper">
                                <p>{{ $t('ib6.monthlyStatistics.prizeDepositTitle') }} <span>USD {{ item.depositStandard
                                }}</span></p>
                                <p>{{ $t('ib6.monthlyStatistics.prizeVolumeTitle1') }} <span>{{ item.volumeStandard }} {{
                                    $t('ib6.monthlyStatistics.prizeVolumeTitle2')
                                }}</span></p>
                            </div>
                            <img v-if="resultApiData.prizeList.length == 3 && index === 1 || resultApiData.prizeList.length == 2 && resultApiData.agentLevel == 1 && index === 0 || resultApiData.prizeList.length == 2 && resultApiData.agentLevel == 9"
                                class="bg_dot"
                                src="http://doo-prime-static.oss-cn-hongkong.aliyuncs.com/ib_event/ib6/images/timePoint_on.png"
                                alt="">
                            <img v-else class="bg_dot" style="filter: grayscale(100%);"
                                src="http://doo-prime-static.oss-cn-hongkong.aliyuncs.com/ib_event/ib6/images/timePoint_on.png"
                                alt="">
                        </div>
                    </template>
                </div>
                <div :class="`progressbar-wrapper ${verifyPrizeClass()}`">
                    <div class="progressbar" :data-percent="timeline_value"></div>
                </div>
            </div>
            <div class="wrapper">
                <div>
                    <p>{{ $t('ib6.monthlyStatistics.validCustomer') }}</p>
                    <p>
                        <span>
                            <span class="active">{{ resultApiData.qualifiedCustomersNumber }}</span> / {{
                                resultApiData.qualifiedCustomersStand }}
                        </span>
                    </p>
                </div>
                <div>
                    <p>{{ $t('ib6.monthlyStatistics.topUp') }}<a
                            @click="openModal(); setGTMEvent(`${commonStore.currentEvent}_netdepositstatus_logged`)"
                            :id="`${commonStore.currentEvent}_netdepositstatus_logged`"><img class="bg_help"
                                :src="imageUrl + 'help.svg'"></a></p>
                    <p>
                        <span>
                            <span class="active">{{ resultApiData.eventInGold }}</span> / {{
                                resultApiData.prizeInfo.depositStandard }}
                        </span>
                    </p>
                </div>
                <div>
                    <p>{{ $t('ib6.monthlyStatistics.trade') }}</p>
                    <p>
                        <span>
                            <span class="active">{{ resultApiData.tradingVolume }}</span> / {{
                                resultApiData.prizeInfo.volumeStandard }}
                        </span>
                    </p>
                </div>
            </div>
            <p class="rewardClaimTitle">{{ $t('ib6.monthlyStatistics.totalClaimTitle') }}{{ resultApiData.totalCommission
            }}</p>

            <div v-if="resultApiData.agentLevel > 0 && eventStatus == 'status2' || resultApiData.agentLevel > 1 && eventStatus == 'status3' || resultApiData.agentLevel > 1 && eventStatus == 'status4'"
                class="
            gold_wrapper">
                <p class="title">{{ $t('ib6.monthlyStatistics.goldTitle') }}</p>
                <div class="gold_content">
                    <img class="bg_gold" :src="imageUrl + 'gold.png'" alt="">
                    <p>USD {{ resultApiData.goldInfo.goldPrize.giftName }}</p>
                </div>
                <div class="progress-bar">
                    <div class="bar" :style="{
                        width: `${(Number(String(resultApiData.goldAmount).replace(/[^0-9\.-]+/g,
                            '')) / Number(String(resultApiData.goldInfo.goldLot).replace(/[^0-9\.-]+/g, ''))) * 100}%`
                    }
                        ">
                    </div>
                </div>
                <div>
                    <p class="sub_title">{{ $t('ib6.monthlyStatistics.gold') }}</p>
                    <p>
                        <span>
                            <span class="active">{{ resultApiData.goldAmount }}</span> / {{
                                resultApiData.goldInfo.goldLot }}
                        </span>
                    </p>
                </div>
                <p class="sub_content">{{ $t('ib6.monthlyStatistics.goldSubTitle') }}</p>
            </div>
            <p v-if="eventStatus == 'status1' || eventStatus == 'status2' || eventStatus == 'status3' && resultApiData.agentLevel > 0"
                class="sub_content">{{
                    $t('ib6.monthlyStatistics.description') }}</p>
            <p v-if="eventStatus == 'status3' && resultApiData.agentLevel == 0 || eventStatus == 'status4' && resultApiData.agentLevel == 0"
                class="sub_content_fail">{{ $t('ib6.monthlyStatistics.noRewardTitle') }}</p>
            <EventInfoBtn :btn-info-title="$t('ib6.monthlyStatistics.btn_more_info')"
                :btnInfoLink="`${this.$i18n.locale}/levelDetail`"
                :btn-id="`${commonStore.currentEvent}_leveldetail_logged`" />
            <EventRuleBtn :btnRulePath="`${this.$i18n.locale}/levelDetail#section_event_rule`"
                :btn-id="`${commonStore.currentEvent}_levelrule_logged`" />
        </div>
    </div>
</template>

<script lang="ts">
import { useAgentStore } from '@/stores/user'
import { useCommonStore } from '@/stores/common'
import { ResultData } from "@/modal/Result";
import { removeNumberWithCommas } from "@/service/general";
import EventTimeTable from "@/views/ib6/EventTimeTable.vue";
import ProgressBar from "@/components/ProgressBar.vue";
import EventInfoBtn from "@/components/button/EventInfoBtn.vue";
import EventRuleBtn from "@/components/button/EventRuleBtn.vue";
import { setGTMEvent } from "@/service/gtm";
import CountdownNoFlip from "@/components/CountdownNoFlip.vue";
import CountdownNoFlipWithBackground from "@/components/CountdownNoFlipWithBackground.vue";

export default {
    props: {
        eventStatus: {
            default: "",
        },
        dateToCount: {
            default: '',
        },
        resultApiData: {
            default: {} as ResultData,
        },
        previousEventStatus: {
            default: ''
        },
    },
    components: {
        EventTimeTable,
        ProgressBar,
        EventInfoBtn,
        EventRuleBtn,
        CountdownNoFlip,
        CountdownNoFlipWithBackground
    },
    data() {
        return {
            agentStore: useAgentStore(),
            commonStore: useCommonStore(),
            imageUrl: "https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/ib_event/ib6/images/",
            receiveData: [],
            selectedPrizeIndex: -1,
            selectedPrize: [],
            timeline_value: [0, 25, 50, 75, 100],

        }
    },

    methods: {
        setGTMEvent,
        openModal() {
            // this.IB4Store.setSelectedGift(type, code, name);
            this.commonStore.openModal('DepositInfo')
            // this.commonStore.openModal('PassEventRecord')
            // this.commonStore.openModal('RedeemSuccess2')
        },
        selectOption(giftType: any, giftCode: any, giftName: any, index: any) {
            this.selectedPrizeIndex = index;
            this.selectedPrize = [giftType, giftCode, giftName];
        },
        openRedeem() {
            if (this.selectedPrize.length) {
                this.openModal(...this.selectedPrize)
            }
        },
        progressbar() {
            let progressbar: HTMLElement = document.querySelector(".progressbar");
            const progressBarIndex = {
                "status1": 0,
                "status2": 1,
                "status3": 2,
                "status4": 3,
                "status5": 4,
            }
            let dots = document.querySelectorAll(".dot1 .item");
            progressbar.style.width = `${this.timeline_value[progressBarIndex[this.eventStatus]]}%`;
            if (progressBarIndex[this.eventStatus] > 0) {
                for (let i = 0; i < dots.length; i++) {
                    if (i < progressBarIndex[this.eventStatus]) {
                        dots[i].classList.add("on");
                    }
                }
            }
        },
        verifyPrizeClass() {
            if (this.resultApiData.agentLevel === 0 || this.resultApiData.agentLevel === 1) {
                return 'left'
            } else if (this.resultApiData.agentLevel === 9) {
                return 'right'
            } else {
                return ''
            }
        },


    },
    mounted() {
        this.progressbar();
    },

}
</script>

<style lang="scss">
#agentResult {
    p {
        margin: 0;
    }

    .result {
        // padding: 24px 16px;

        .title_wrapper {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 8px;

            img {
                width: 42px;
            }

            p {
                font-size: 16px;
                font-weight: 600;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                text-align: center;
                color: #252525;
            }
        }

        .time_wrapper {

            .title {
                margin: 16px auto;
                font-size: 16px;
                font-weight: 600;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                text-align: center;
                color: #14142a;
            }

            .sub_title {
                margin-bottom: 4px;
                font-size: 14px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                text-align: center;
                color: #ee0a24;
            }

            .content {
                font-size: 24px;
                font-weight: bold;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                text-align: center;
                color: #ee0a24;
            }
        }

        .progressbar-a {

            .progressbar-wrapper {
                background: #e4e4f0;
                border-radius: 13px;
                margin-top: 27px;
                margin-bottom: 20px;
                position: relative;

                .progressbar {
                    width: 0%;
                    padding: .1em .5em;
                    border-radius: 1em;
                    text-align: right;
                    background: #ee0a24;
                    color: white;
                    box-sizing: border-box;
                    min-height: 6px;
                    position: relative;
                }

                .dot1 {
                    position: absolute;
                    left: 1px;
                    top: -6px;
                    width: 100%;
                    display: flex;
                    justify-content: space-between;

                    .item {
                        width: 25%;


                        &:last-child {
                            margin-right: 0;
                        }

                        &.on {
                            i {
                                box-shadow: 0 1px 2px 0 rgba(238, 10, 36, 0.4);
                                border: solid 0.5px #fff;
                                background-image: linear-gradient(225deg, #ee0a24 75%, #fa8485 25%);
                            }
                        }
                    }

                    i {
                        width: 14px;
                        height: 14px;
                        margin: auto 1px 1px auto;
                        display: inline-block;
                        border-radius: 8px;
                        border: solid 0.5px #fff;
                        background-image: linear-gradient(91deg, #f7f7f7 16%, #c3c3c3 85%);
                    }

                }
            }

            .event_timeline {
                display: flex;
                justify-content: space-around;
                align-items: center;
                width: 100%;

                .event_timeline_wrapper {
                    width: 25%;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;

                    .vertical_line {
                        margin: 0 40px;
                        height: 4px;
                        width: 1px;
                        background-color: #252525;
                    }

                    .event_timeline_date {

                        font-family: MicrosoftYaHei;
                        font-size: 12px;
                        font-weight: normal;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: normal;
                        letter-spacing: normal;
                        text-align: left;
                        color: #252525;


                        &:last-child {
                            margin-right: 0;
                        }
                    }
                }



            }

            .event_timeline_title {
                width: 25%;
                border-radius: 2px;
                background-color: rgba(255, 96, 96, 0.12);
                font-family: MicrosoftYaHei;
                font-size: 12px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                text-align: center;
                color: #ff2b30;
                position: relative;
                padding: 2px 4px;
                // margin: 12px 9.15px 4px;
                max-width: 64px;

                &:first-child {
                    margin-left: 0;
                }

                &:last-child {
                    margin-right: 0;
                }

                .arrow {
                    overflow: hidden;
                    position: relative;
                    margin: 7em auto 0;
                    border-radius: 20%;
                    transform: translateY(50%) rotate(30deg) skewY(30deg) scaleX(.866);
                    pointer-events: none;
                    width: 16px;
                    height: 16px;
                    position: absolute;
                    top: -97px;
                    left: 50%;
                    margin-left: -8px;

                    &:before,
                    &:after {
                        position: absolute;
                        left: 0;
                        background: #f9e8ec;
                        pointer-events: auto;
                        content: '';

                        width: 16px;
                        height: 16px;
                    }

                    &:before {
                        border-radius: 20% 20% 20% 53%;
                        transform: scaleX(1.155) skewY(-30deg) rotate(-30deg) translateY(-42.3%) skewX(30deg) scaleY(.866) translateX(-24%);
                    }

                    &:after {
                        border-radius: 20% 20% 53% 20%;
                        transform: scaleX(1.155) skewY(-30deg) rotate(-30deg) translateY(-42.3%) skewX(-30deg) scaleY(.866) translateX(24%);
                    }
                }
            }


            .btn-red {
                padding: 12px 45px 12px;
                margin-bottom: 32px;
            }

        }

        .level_wrapper {
            position: relative;

            .title {
                margin: 40px auto 24px;
                font-size: 16px;
                font-weight: 600;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                text-align: center;
                color: #252525;
            }

            .prize {
                display: flex;
                justify-content: center;
                position: relative;
                overflow: hidden;

                &.left {
                    justify-content: flex-end;
                }

                &.right {
                    justify-content: flex-start;

                    @media screen and (max-width:600px) {
                        .prize0 {
                            margin: auto 25px auto -20px;
                        }
                    }

                }


                .cover_left {
                    width: 170px;
                    height: 100%;
                    left: 0;
                    position: absolute;
                    z-index: 1;
                    background: linear-gradient(to right, white 0%, white 40%, transparent 100%);

                    @media screen and (max-width:400px) {
                        width: 60px;
                    }

                    @media screen and (max-width:600px) {
                        width: 120px;
                    }
                }

                .cover_right {
                    width: 170px;
                    height: 100%;
                    right: 0;
                    position: absolute;
                    z-index: 1;
                    background: linear-gradient(to left, white 0%, white 40%, transparent 100%);

                    @media screen and (max-width:450px) {
                        width: 60px;
                    }

                    @media screen and (max-width:600px) {
                        width: 120px;
                    }
                }

                .active {
                    margin: auto 22px;
                }

                .prize_wrapper {
                    width: 33.3%;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;

                    &:nth-of-type(0) {
                        margin: 0 22px;
                    }


                    .bg_level {
                        width: 53px;
                        margin: 0 0 10px;
                    }

                    .bg_prize {
                        width: 126px;
                        margin: 0 0 10px;
                    }

                    p {
                        font-size: 10px;
                        font-weight: normal;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: normal;
                        letter-spacing: normal;
                        text-align: center;
                        color: #14142a;

                        span {
                            font-size: 14px;
                            font-weight: 600;
                            font-stretch: normal;
                            font-style: normal;
                            line-height: 1.43;
                            letter-spacing: normal;
                            text-align: center;
                            color: #14142a;

                        }
                    }

                    .prize_info_wrapper {

                        &:nth-of-type(1) {
                            margin: 8px 22px 18px;
                            // margin: 8px 0 18px;

                        }

                        margin: 8px 0 18px;
                        padding: 4px;
                        border-style: solid;
                        border-width: 1px;
                        border-image-source: linear-gradient(to bottom, #ffebc6, #f2e0bf 4%);
                        border-image-slice: 1;
                        background-image: linear-gradient(to bottom, #fff5e3, rgba(255, 245, 227, 0) 200%);


                    }

                    p {
                        text-wrap: nowrap;
                        font-size: 10px;
                        font-weight: normal;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: 1.8;
                        letter-spacing: normal;
                        text-align: center;
                        color: #252525;

                        span {
                            font-size: 12px;
                            font-weight: 600;
                            font-stretch: normal;
                            font-style: normal;
                            line-height: 1.5;
                            letter-spacing: normal;
                            text-align: left;
                            color: #252525;

                        }
                    }

                    .bg_dot {
                        width: 24px;
                    }
                }


            }

            .progressbar-wrapper {
                background: #e4e4f0;
                border-radius: 13px;
                // margin-top: 27px;
                margin-bottom: 11px;
                position: relative;
                top: -15px;
                z-index: -1;

                &.left {
                    width: 50%;
                    margin: 0 0 11px auto;

                    .progressbar {
                        background: #e4e4f0;
                    }
                }

                &.right {
                    width: 50%;
                    margin: 0 auto 11px 0;

                    .progressbar {
                        width: 100%;
                    }
                }


                .progressbar {
                    width: 65%;
                    padding: .1em .5em;
                    border-radius: 1em;
                    text-align: right;
                    background: #e2b571;
                    color: white;
                    box-sizing: border-box;
                    min-height: 6px;
                    position: relative;
                }

            }
        }

        .wrapper {


            div {
                display: flex;
                justify-content: space-between;
                margin-bottom: 10px;

                p {
                    display: flex;
                    font-size: 12px;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.67;
                    letter-spacing: normal;
                    text-align: left;
                    color: #252525;

                    .bg_help {
                        width: 20px;
                        height: 20px;
                    }

                    span {
                        font-size: 12px;
                        font-weight: normal;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: 1.67;
                        letter-spacing: normal;
                        text-align: left;
                        color: #878793;

                        .active {
                            color: #ee0a24;
                        }
                    }
                }
            }
        }

        .rewardClaimTitle {
            font-size: 12px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.67;
            letter-spacing: normal;
            text-align: left;
            color: #000;
        }

        .gold_wrapper {
            margin: 32px 0 0;
            padding: 16px 12px;
            border-radius: 6px;
            border: solid 1.5px #cea66c;
            background-color: #fcfdff;

            .title {
                font-size: 12px;
                font-weight: 600;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.67;
                letter-spacing: normal;
                text-align: center;
                color: #252525;
            }


            .gold_content {
                margin: 8px 0 16px 0;
                display: flex;
                justify-content: center;
                align-items: center;

                .bg_gold {
                    max-width: 36px;
                    width: 100%;
                }

                p {
                    font-family: MicrosoftYaHeiBold;
                    font-size: 20px;
                    font-weight: bold;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 0.9;
                    letter-spacing: normal;
                    text-align: left;
                    color: #c39654;
                }
            }

            .progress-bar {
                height: 6px;
                width: 100%;
                background-color: #f5f5f7;
                border-radius: 20px;
                overflow: hidden;
                position: relative;
                margin-bottom: 8px;

                .bar {
                    width: 0;
                    height: 100%;
                    background-color: #e4be65;
                    transition: width 2s linear;
                    border-radius: 9px;
                }
            }

            div {
                display: flex;
                justify-content: space-between;

                p {
                    font-size: 14px;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.43;
                    letter-spacing: normal;
                    text-align: left;
                    color: #252525;

                    span {
                        font-size: 14px;
                        font-weight: normal;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: 1.67;
                        letter-spacing: normal;
                        text-align: left;
                        color: #878793;

                        .active {
                            color: #c39654;
                        }
                    }
                }
            }

            .sub_content {
                margin: auto;
                text-align: left;
            }

        }

        .sub_content {
            margin: 16px auto 40px;
            font-size: 10px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.8;
            letter-spacing: normal;
            text-align: center;
            color: #878793;
        }

        .sub_content_fail {
            margin: 16px auto 40px;
            font-family: MicrosoftYaHeiSemibold;
            font-size: 16px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.5;
            letter-spacing: normal;
            text-align: center;
            color: #14142a;
        }

    }
}
</style>