<template>
  <div id="rankDetail" class="child_bg">
    <div class="container">
      <Back :pageTitle="this.$t('ib6.eventRanking.title')" />
      <childPageContainer :eventTitle="this.$t('ib6.eventRanking.descriptionTitle')">
        <div class="rankContent">
          <p>{{ $t("ib6.eventRanking.description1") }}</p>
          <!-- <p>{{ $t("ib6.eventRanking.top10client") }}</p> -->
          <p>{{ $t("ib6.eventRanking.rewardTitle") }}</p>
        </div>

        <img :src="imageUrl + 'USD_50000.png'" class="pd-img" />

        <EventSatisticTime period="ib6.eventLevel.periodInGold" period2="ib6.eventLevel.period"
          condition="ib6.eventLevel.conditionTimeZone" title="ib6.eventTime.inGoldStatistic"
          title2="ib6.eventTime.OutGoldStatistic" />
        <div class="rt_ribbon">
          <img class="ribbon5" :src="imageUrl + 'ribbon5.png'" />
        </div>
        <RankList :key="'1'" :id="'1'" :title="$t('ib6.eventRanking.richList')"
          :subtitle="$t('ib6.eventRanking.topupDescription')" :reward="richList" />
        <div class="lf_ribbon">
          <img class="ribbon2" :src="imageUrl + 'ribbon2.png'" />
        </div>
        <RankList :key="'2'" :id="'2'" :title="$t('ib6.eventRanking.topTraderList')"
          :subtitle="$t('ib6.eventRanking.tradingDescription')" :reward="topTraderList" />
        <div class="rt_btm_ribbon">
          <img class="ribbon6" :src="imageUrl + 'ribbon6.png'" />
        </div>
        <RankList :key="'3'" :id="'3'" :title="$t('ib6.eventRanking.expansionList')"
          :subtitle="$t('ib6.eventRanking.validCustomerDescription')" :reward="expansionList" />
      </childPageContainer>
      <EventRule eventRuleTitle="eventRanking" />
    </div>
  </div>
</template>

<script lang="ts">
import EventSatisticTime from "@/views/ib6/home_event/EventSatisticTime.vue";
import RankList from "@/views/ib6/RankList.vue";
import Back from "@/components/button/Back.vue";
import EventRule from "@/views/ib6/EventRule.vue"
import childPageContainer from "@/components/childPageContainer.vue";

export default {
  name: "RankDetail",
  data() {
    return {
      imageUrl:
        "https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/ib_event/ib6/images/",
      richList: [
        "28,888",
        "18,888",
        "8,888",
        "6,888",
        "2,888",
        "1,288",
        "888",
        "368",
        "288",
        "168",
      ],
      topTraderList: [
        "18,888",
        "6,688",
        "3,688",
        "1,688",
        "1,268",
        "868",
        "668",
        "368",
        "268",
        "168",
      ],
      expansionList: [
        "2,888",
        "1,468",
        "1,268",
        "1,088",
        "868",
        "668",
        "468",
        "368",
        "268",
        "168",
      ],
    };
  },
  components: {
    EventSatisticTime,
    RankList,
    Back,
    EventRule,
    childPageContainer,
  },
};
</script>

<style lang="scss">
#rankDetail {

  .rankContent {
    margin: 30px 7px 16px 8px;

    p {
      margin: 0;
      font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #252525;
    }
  }

  .pd-img {
    width: 267px;
  }

  .rt_ribbon {
    position: relative;

    .ribbon5 {
      position: absolute;
      top: 0;
      right: 0;
      transform: translate(30%, -70%);
      width: 124px;
      height: 202.4px;
    }
  }

  .lf_ribbon {
    .ribbon2 {
      position: absolute;
      top: 0;
      left: 0;
      transform: translate(0%, 430%);
      width: 120.3px;
      height: 202px;
    }
  }

  .rt_btm_ribbon {
    .ribbon6 {
      position: absolute;
      top: 0;
      right: 0;
      transform: translate(0%, 675%);
      width: 76px;
      height: 202.4px;
    }
  }

  .satisticTime {
    margin: 35px 0;
  }
}
</style>
