<template>
    <div id="qqmask">
        <div class="qqmask"></div>
    </div>
</template>
<script lang="ts">
export default {
    name: "MaskComponent"
}
</script>

<style scoped>
#chat-widget-container {
    display:none !important;
}

#qqmask {
    position: relative;
    z-index: 1000;
}

.qqmask {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-image: url('@/assets/mask.png');
    background-color: #ffffff;
    background-repeat: no-repeat;
    background-size: auto auto;
    background-position: center;
}
</style>