<template>
    <div id="benefitDetail" class="child_bg">
        <div class="container">
            <Back :pageTitle="this.$t('ib6.eventBenefit.title')" />
            <childPageContainer :eventTitle="this.$t('ib6.eventBenefit.descriptionTitle')">

                <div id="redeem">
                    <img :src="imageUrl + 'pending_redeem.png'" class="pending_redeem" />
                    <p>{{ $t('ib6.eventBenefit.redeem') }}</p>
                </div>

                <div class="benefit_subtitle">
                    <p>{{ $t("ib6.eventBenefit.subTitle1") }}</p>
                    <p>{{ $t("ib6.eventBenefit.subTitle2") }}</p>
                </div>
                <div class="benefit_content">
                    <div class="dob_box">
                        <img :src="imageUrl + 'dob_redpacket.png'" class="dob_redpacket"/>
                        <h3>{{ $t("ib6.eventBenefit.dob") }}</h3>
                        <p>{{ $t("ib6.eventBenefit.dobDescription1") }}</p>
                        <p>{{ $t("ib6.eventBenefit.dobDescription2") }}</p>
                    </div>
                    <img :src="imageUrl + 'add.svg'" class="add" />
                    <div class="festive_box">
                        <img :src="imageUrl + 'festive_redpacket.png'" class="festive_redpacket"/>
                        <h3>{{ $t("ib6.eventBenefit.festive") }}</h3>
                        <p>{{ $t("ib6.eventBenefit.festiveDescription1") }}</p>
                        <p>{{ $t("ib6.eventBenefit.festiveDescription2") }}</p>
                    </div>
                </div>
            </childPageContainer>
            <EventRule />
        </div>
    </div>
</template>

<script lang="ts">
import Back from "@/components/button/Back.vue";
import childPageContainer from "@/components/childPageContainer.vue";
import EventBenefit from "@/views/ib6/home_event/EventBenefit.vue";
import EventRule from "@/views/ib6/EventRule.vue";

export default {
    name: "BenefitDetail",
    data() {
        return {
            imageUrl:
                "https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/ib_event/ib6/images/",
            isAvaliableRedeem: []
        };
    },
    components: {
        Back,
        childPageContainer,
        EventBenefit,
        EventRule,
    },
};
</script>

<style lang="scss">
#benefitDetail {
    #redeem {
        img {
            width: 240px;
            height: 147px;
        }

        .pending_redeem {
            margin-top: 30px;
        }

        p {
            margin: 0;
            margin-bottom: 41px;
            font-size: 12px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: center;
            color: #979797;
        }
    }

    .benefit_subtitle {
        margin-bottom: 16px;

        p {
            margin: 0;
            font-size: 14px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: center;
            color: #252525;
        }
    }

    .benefit_content {
        display: flex;
        align-items: center;
        justify-content: space-around;
        margin-bottom: 40px;

        .dob_box {
            .dob_redpacket {
                width: 95px;
                height: 88px;
            }

            h3 {
                font-family: AlibabaPuHuiTi;
                margin: 0;
                margin-bottom: 8px;
                font-size: 16px;
                font-weight: 900;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                color: #000;
            }

            p {
                margin: 0;
                font-size: 14px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                color: #424b55;
            }
        }

        .add {
            height: 16px;
            width: 16px;
            margin-bottom: 60px;
        }

        .festive_box {
            .festive_redpacket {
                width: 95px;
                height: 88px;
            }

            h3 {
                font-family: AlibabaPuHuiTi;
                margin: 0;
                margin-bottom: 8px;
                font-size: 16px;
                font-weight: 900;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                color: #000;
            }

            p {
                margin: 0;
                font-size: 14px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                color: #424b55;
            }
        }
    }
}
</style>
