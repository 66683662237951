<template>
    <div class="container-timer" :key="keyTimer">
        <div id="countdown">
            <ul>
                <li>
                    <span class="time" :id="`${countDownType}days`"></span>
                    <span class="timer-txt">{{ $t('ib6.time.day') }}</span>
                </li>
                <li class="colon"><span>:</span></li>
                <li><span :id="`${countDownType}hours`" class="time"></span><span class="timer-txt">{{ $t('ib6.time.hours')
                }}</span>
                </li>
                <li class="colon"><span>:</span></li>
                <li><span :id="`${countDownType}minutes`" class="time"></span><span class="timer-txt">{{
                    $t('ib6.time.minutes')
                }}</span>
                </li>
                <li class="colon"><span>:</span></li>
                <li><span :id="`${countDownType}seconds`" class="time"></span><span class="timer-txt">{{
                    $t('ib6.time.second')
                }}</span>
                </li>
            </ul>
        </div>
    </div>
</template>

<script lang="ts">

export default {

    props: {
        deadlineAP: {
            default: '',
        },
        countDownType: {
            default: 'prize',
        }
    },

    data() {
        return {
            keyTimer: 1,
            interval: null
        }
    },
    methods: {
        countdownFunction() {
            //time in milliseconds
            const second = 1000,
                minute = second * 60,
                hour = minute * 60,
                day = hour * 24;

            let countDown = new Date(this.deadlineAP).getTime();

            let x = setInterval(
                async () => {
                    const now = Date.parse(new Date().toUTCString())
                    const distance = countDown - now
                    if (now < countDown) {
                        if (document.getElementById(`${this.countDownType}days`)) {
                            document.getElementById(`${this.countDownType}days`).innerText = String(Math.floor(distance / (day))).padStart(2, "0"),
                                document.getElementById(`${this.countDownType}hours`).innerText = String(Math.floor((distance % (day)) / (hour))).padStart(2, "0"),
                                document.getElementById(`${this.countDownType}minutes`).innerText = String(Math.floor((distance % (hour)) / (minute))).padStart(2, "0"),
                                document.getElementById(`${this.countDownType}seconds`).innerText = String(Math.floor((distance % (minute)) / second)).padStart(2, "0");
                        }


                        if (distance < 0) {
                            document.getElementById(`${this.countDownType}days-ld`).innerText = String(0).padStart(2, "0"),
                                document.getElementById(`${this.countDownType}hours-ld`).innerText = String(0).padStart(2, "0"),
                                document.getElementById(`${this.countDownType}minutes-ld`).innerText = String(0).padStart(2, "0"),
                                document.getElementById(`${this.countDownType}seconds-ld`).innerText = String(0).padStart(2, "0");
                            clearInterval(x);
                        }
                    }


                    //happen every second
                }, 0)
        }

    },
    mounted() {
        this.countdownFunction()
    },
}



</script>

<style lang="scss" scoped>
/* general styling */
:root {
    --smaller: .75;
}


.container-timer {
    display: block;
    // position: relative;
    margin: auto 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 241px;
    // width: 343px;
    // height: 112px;
    // border-radius: 10px;


    // margin-bottom: 35px;
}

#countdown {
    display: block;
}

ul {
    display: flex;
    padding-left: 0;
}

li {
    font-family: SegoeUI;
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    // color: #fffedc;
    display: inline-block;
    list-style-type: none;
    // padding: 0 5px;
    text-transform: uppercase;



    .timer-txt {
        padding-top: 4px;
        font-family: PingFangSC;
        font-size: 12px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: #73828e;
    }

    .timer-txt.th {
        letter-spacing: 1.5px;
    }


}

.colon {
    padding: 0 0 20px;
    width: inherit !important;
    padding: 0 12px;
    // display: flex;
    // align-items: center;

    span {
        display: block;
        font-family: PingFangSC;
        font-size: 36px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: #e1c89c;
    }
}

li span {
    display: block;
    font-family: SegoeUI;
    font-size: 40px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #d91d22;
}

span.time {
    display: block;
    width: 35px;
    height: 43px;
    display: flex;
    justify-content: center;
    align-items: center;
    // border-radius: 5.4px;
    // box-shadow: 0 8.1px 11.7px 0 rgba(197, 197, 212, 0.3);
    // background-color: #fff;

}
</style>