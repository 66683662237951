<template>
  <EventTabHeader :id="'3'" :title="this.$t('ib6.eventAnnual.descriptionTitle')"
    :btnDetailLink="`${this.$i18n.locale}/annualDetail#section_event_rule`"
    :btnId="`${commonStore.currentEvent}_subpagerule3_visitor`">
    <div class="eventThreeContainer">
      <img :src="imageUrl + 'annual_prize_1.png'" class="prize" />
      <div class="eventThreeContentContainer">
        <p class="completeTask">
          {{ $t(`ib6.eventDescription.complete`) }}
        </p>
        <div class="eventThreeContent">
          <div class="eventThreeContent1">
            <p>{{ $t(`ib6.eventDescription.description7`) }}</p>
            <h4>{{ $t(`ib6.eventDescription.description13`) }}</h4>
          </div>

          <img :src="imageUrl + 'add.svg'" class="add" />

          <div class="eventThreeContent1">
            <p>{{ $t(`ib6.eventDescription.description8`) }}</p>
            <h4>{{ $t(`ib6.eventDescription.description12`) }}</h4>
          </div>

          <img :src="imageUrl + 'arrow_right.svg'" class="arrow_right" />
          <img :src="imageUrl + 'trophy.png'" class="trophy" />
        </div>
      </div>
    </div>
    <p class="desc">{{ $t(`ib6.eventDescription.description9`) }}</p>
    <EventSatisticTime :period="this.$t('ib6.eventAnnual.period')"
      :condition="this.$t('ib6.eventAnnual.conditionTimeZone')" />
    <EventInfoBtn :btnInfoTitle="this.$t('ib6.eventInfo.prize')" :btnInfoLink="`${this.$i18n.locale}/annualDetail`"
      :btn-id="`${commonStore.currentEvent}_subpage3_visitor`" />
  </EventTabHeader>
</template>

<script lang="ts">
import EventTabHeader from "@/views/ib6/home_event/EventTabHeader.vue";
import EventSatisticTime from "./EventSatisticTime.vue";
import EventInfoBtn from "@/components/button/EventInfoBtn.vue";
import { useCommonStore } from "@/stores/common";

export default {
  data() {
    return {
      imageUrl: "https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/ib_event/ib6/images/",
      commonStore: useCommonStore(),
    };
  },
  components: {
    EventTabHeader,
    EventSatisticTime,
    EventInfoBtn,
  },
};
</script>

<style lang="scss">
$desk: 600px;

#tab_3 {

  .event_description {
    padding: 24px 22.5px;
  }

  .desc {
    margin: 57px 0 48px 0;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #000;
  }

  .eventThreeContainer {
    .prize {
      scale: 1.1;
      width: 267px;
    }

    .eventThreeContentContainer {
      width: 100%;
      margin: -20px 0 0 0;
      padding: 0 0 5px 10px;
      box-shadow: 0 2px 8px 0 rgba(151, 151, 151, 0.1);
      border-style: solid;
      border-width: 1px;
      border-image-source: linear-gradient(to bottom, #ffebc6 100%, #f2e0bf 4%);
      border-image-slice: 1;
      background-image: linear-gradient(to bottom, #fff5e3 0%, rgba(255, 245, 227, 0) 100%);

      .completeTask {
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        color: #c39654;
        padding: 16px 0 8px 0;
        margin: 0;
      }

      .eventThreeContent {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        h4 {
          font-size: 14px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          text-align: left;
          color: #252525;
          margin: 8px 0 0 0;
        }

        p {
          font-size: 12px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          text-align: left;
          color: #3a424b;
          margin: 0;
        }

        .add {
          margin: 0 9px 0 3.5px;
        }

        .trophy {
          width: 66px;
          height: 61px;
          margin-right: 6px;
        }

        @media screen and (min-width: $desk) {
          .add {
            margin: 0 39px 0 38px;
          }

          .arrow_right {
            margin-right: 11.5px;
          }
        }
      }
    }
  }



}
</style>
