import api from "./config";

const baseUrl = "/ib4-0";

export const activityTime = async () => {
  try {
    const payload = await api({
      method: "get",
      url: `${baseUrl}/activityTime`,
      withCredentials: true,
    });
    return payload;
  } catch (e) {
    return e;
  }
};

export const getAllLevel = async (location) => {
  try {
    const payload = await api({
      method: "get",
      url: `${baseUrl}/getLevelPrize`,
      params: { location },
      withCredentials: true,
    });
    return payload;
  } catch (e) {
    return e;
  }
};

export const getPublishResults = async () => {
  try {
    const payload = await api({
      method: "get",
      url: `${baseUrl}/getPublishResults`,
      withCredentials: true,
    });
    return payload;
  } catch (e) {
    return e;
  }
};

export const receiveBonus = async (data: any) => {
  try {
    const payload = await api({
      method: "post",
      url: `${baseUrl}/receiveBonus`,
      data: data,
      withCredentials: true,
    });
    return payload;
  } catch (e) {
    return e;
  }
};
