import api from "./config";

const baseUrl = "/common";

export const eventConfig = async () => {
  try {
    const payload = await api({
      method: "get",
      url: `${baseUrl}/getCurrentActivityTime`,
    });
    return payload;
  } catch (e) {
    return e;
  }
};

export const claimRewardHistory = async () => {
  try {
    const payload = await api({
      method: "get",
      url: `${baseUrl}/claimRewardHistory`,
    });
    return payload;
  } catch (e) {
    return e;
  }
};

export const claimReward = async (data: any) => {
  try {
    const payload = await api({
      method: "post",
      url: `${baseUrl}/claimReward`,
      data: data,
      withCredentials: true,
    });
    return payload;
  } catch (e) {
    return e;
  }
};

export const getMarqueeList = async () => {
  try {
    const payload = await api({
      method: "get",
      url: `${baseUrl}/getMarqueeList`,
      withCredentials: true,
    });
    return payload;
  } catch (e) {
    return e;
  }
};
