<template>
    <div class="progress-bar">
        <div class="bar" :style="{
            width: `${Number(String(currentValue).replace(/[^0-9\.-]+/g, '')) / Number(String(totalValue).replace(/[^0-9\.-]+/g, '')) * 100}%`
        }
            ">
        </div>
    </div>
</template>

<script lang="ts">
export default {
    props: {
        currentValue: {
            type: [String, Number],
            default: 0,
        },
        totalValue: {
            type: [String, Number],
            default: 0,
        }
    },
}
</script>

<style lang="scss">
.progress-bar {
    height: 6px;
    width: 100%;
    background-color: #f5f5f7;
    border-radius: 20px;
    overflow: hidden;
    position: relative;
    margin-bottom: 8px !important;

    .bar {
        width: 0;
        height: 100%;
        background-color: #ee0a24;
        transition: width 2s linear;
        border-radius: 9px;
    }
}
</style>






