<template>
    <div class="modal-content redeemSuccess">
        <div class="title">
            {{ $t("ib5.modal.redeemSucceeded") }} <br style="display:block !important">
            <template v-if="(ib4Store.selectedPrize as GiftType).type == 0">
                <span>{{ (ib4Store.selectedPrize as GiftType).name }}</span>
            </template>
            <template v-else>
                <template
                    v-if="(ib4Store.selectedPrize as GiftType).code == '1005' || (ib4Store.selectedPrize as GiftType).code == '1007' || (ib4Store.selectedPrize as GiftType).code == '1009'">
                    <span v-html="$t('ib5.level.100' + ib4Store.agentLevel + '_2')"></span>
                </template>
                <template v-else>
                    <span v-html="$t('ib5.level.100' + ib4Store.agentLevel)"></span>
                </template>
            </template>
        </div>
        <SelectedPrize />
        <p>{{ $t("ib5.modal.redeemSucceededDesc") }}</p>
    </div>
</template>

<script lang="ts">
import { useCommonStore } from '@/stores/common'
import { useIB4Store } from '@/stores/ib4-0'
import SelectedPrize from '@/components/Modals/component/SelectedPrizes.vue'

interface GiftType {
    type: number | string;
    code: number | string;
    name: string;
}

export default {
    name: "ModelRedeemSuccess",
    setup() {
        const commonStore = useCommonStore()
        const ib4Store = useIB4Store()
        return {
            commonStore,
            ib4Store
        }
    },
    props: {
        amount: {
            default: '',
        },
    },
    components: {
        SelectedPrize
    },
    data() {
        return {
        }
    },
    methods: {
    },
    mounted() {
    }

}
</script>

<style lang="scss">
.redeemSuccess {
    br {
        display: none;
    }

    .title {
        font-size: 24px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.2;
        letter-spacing: normal;
        text-align: left;
        color: #14142a;
        margin: auto;
        margin-bottom: 32px;
        text-align: center !important;
    }

    p.red {
        font-family: MicrosoftYaHei;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.29;
        letter-spacing: normal;
        text-align: left;
        color: #ee0a24;
        margin-bottom: 48px;
    }
}</style>