import { ref, computed } from 'vue'
import { defineStore } from 'pinia'
import { getRankList } from "@/api/kol";

export const useRankStore = defineStore({
  id: "rankData",
  state: () => ({
    user: "",
    oriRank: [],
    allRank: [],
    selectedCard: "",
    selectedCardContent: [],
    formType: "",
    currentRankId: 0,
    removeRankId: 0
  }),
  actions: {
    async getAllRankData() {
      this.oriRank = [];
      this.allRank = [];
      
      const listData = await getRankList();

      for(let i=0; i<listData.data.length; i++){
        let name = listData.data[i].name;
        
        this.oriRank.push({id:listData.data[i].id, ranking:listData.data[i].ranking, name:listData.data[i].name});

        if(name){
          typeof name === 'number' ? name = name.toString() : "";
          let editor = this.user == "adminn" ? "admin" : "dev"; 
          name = name.slice(0, name.length - 4);
          let dataFormat = {
            id: listData.data[i].id,
            title: `<span class='ctitle'>${listData.data[i].ranking}</span> <span class='name'>${name}****</span>`,
            details: `${editor} : ${listData.data[i].last_updated}`
          }
          this.allRank.push(dataFormat);
        }
      }
    },
    setUser(currentUser : any) {
      this.user = currentUser;
    },
    setSelectedCard(index : any) {
      this.selectedCard = index;
    },
    setSelectedCardContent(id:any) {
      this.selectedCardContent = []; // empty not working
      if(id>0){
        for(let i = 0; i<this.oriRank.length; i++){
          if(id == this.oriRank[i].id){
            this.selectedCardContent.push(this.oriRank[i]);
          }
        }
      }
    },
    setFormType(form : string) {
      this.formType = form
    },
    setCurrentRankId(id : any) {
      this.currentRankId = id;
    },
    setRemoveRankId(id : any) {
      this.removeRankId = id;
    },
  },
})
