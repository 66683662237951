import api from "./config";

const baseUrl = "/ib6";

export const getRankingEventInGold = async () => {
  try {
    const payload = await api({
      method: "get",
      url: `${baseUrl}/getRankingEventInGold`,
      withCredentials: true,
    });
    return payload;
  } catch (e) {
    return e;
  }
};

export const getRankingTradingVolume = async () => {
  try {
    const payload = await api({
      method: "get",
      url: `${baseUrl}/getRankingTradingVolume`,
      withCredentials: true,
    });
    return payload;
  } catch (e) {
    return e;
  }
};

export const getRankingMasterIb = async () => {
  try {
    const payload = await api({
      method: "get",
      url: `${baseUrl}/getRankingMasterIb`,
      withCredentials: true,
    });
    return payload;
  } catch (e) {
    return e;
  }
};

export const getRedPacketStatus = async () => {
  try {
    const payload = await api({
      method: "get",
      url: `${baseUrl}/getRedPacketStatus`,
      withCredentials: true,
    });
    return payload;
  } catch (e) {
    return e;
  }
};

export const getClaimedPrizedRecord = async () => {
  try {
    const payload = await api({
      method: "get",
      url: `${baseUrl}/getClaimedPrizeRecord`,
      withCredentials: true,
    });
    return payload;
  } catch (e) {
    return e;
  }
};

export const getRanking = async (data: any) => {
  try {
    const payload = await api({
      method: "get",
      url: `${baseUrl}/getRanking`,
      params: data,
      withCredentials: true,
    });

    return payload;
  } catch (e) {
    return e;
  }
};
