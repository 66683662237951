<template>
  <a :id="btnId" class="btnEventInfo" :href="`${btnInfoLink}`" @click="setGTMEvent(btnId)"><span class="btnInfoTitle">{{
    btnInfoTitle
  }}</span></a>
</template>
<script lang="ts">
import { setGTMEvent } from "@/service/gtm";

export default {

  props: {
    btnInfoTitle: String,
    btnInfoLink: String,
    btnId: {
      default: 'subpage_1'
    }
  },
  methods: {
    setGTMEvent
  }
};
</script>

<style lang="scss">
.btnEventInfo {
  display: inline-block;
  max-width: 300px;
  margin: auto;
  border-radius: 21px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.1);
  border-style: solid;
  border-width: 2px;
  border-image-source: linear-gradient(92deg, #f1353a 0%, #ffc1c2 23%, rgba(241, 180, 154, 0.57) 50%, #ffc1c2 72%, #f1353a 97%);
  border-image-slice: 0;
  background-image: linear-gradient(to bottom, #fff, #fff), linear-gradient(92deg, #f1353a 0%, #ffc1c2 23%, rgba(241, 180, 154, 0.57) 50%, #ffc1c2 72%, #f1353a 97%);
  background-origin: border-box;
  background-clip: content-box, border-box;

  .btnInfoTitle {
    display: inline-block;
    padding: 7px 16px;
    color: #d91d22;
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 1px;
  }
}
</style>