import api from "./config";

const baseUrl = "/red";

export const limitRedStatusList = async () => {
  try {
    const payload = await api({
      method: "get",
      url: `${baseUrl}/limitRedStatusList`,
      // data: data,
    });

    return payload;
  } catch (e) {
    return e;
  }
};
export const receiveLimitRed = async () => {
  try {
    const payload = await api({
      method: "get",
      url: `${baseUrl}/receiveLimitRed`,
    });
    return payload;
  } catch (e) {
    return e;
  }
};

export const getRedPacketStatus = async () => {
  try {
    const payload = await api({
      method: "get",
      url: `${baseUrl}/redPacketStatus`,
      withCredentials: true,
    });
    return payload;
  } catch (e) {
    return e;
  }
};
