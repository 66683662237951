<template>
    <div id="footer-bottom">
        <div class="footer-bottom-wrapper container">
            <p v-t="'footer.bottom'"></p>
        </div>
    </div>
</template>

<script setup lang="ts">

</script>

<style lang="scss">
#footer-bottom {
    background-color: #000018;
    text-align: center;
    padding: 27px 0;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.71;
    letter-spacing: 1.5px;
    color: #f5f5f5;

    p {
        margin: 0;
    }
}
</style>