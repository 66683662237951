<template>
    <section class="bg">
        <div class="content">
            <p :class="this.$i18n.locale" class="title" v-t="'jpDooTravel.HowToJoin'"></p>
            <div class="wrapper">
                <div class="container-line">
                    <img 
                        :src="`${this.imageUrl}deco/airplane-divider-${isWebView ? 'big' : 'small'}.png`"
                        alt="plane">
                </div>
            </div>
            <div class="steps">
                <div v-for="(i) in 3" class="step" :key="i">
                    <div class="step-box">
                        <img class="plane-number" :src="`${this.imageUrl}airplane-${i}.png`" alt="plane step">
                        <div class="step-txt">
                            <p class="step-title" v-t="`jpDooTravel.joinStep${i}Title`"></p>
                            <p class="step-desc" v-html="this.$t(`jpDooTravel.joinStep${i}Desc`)"></p>
                        </div>
                    </div>
                    <img v-if="!isWebView && i !== 3" class="separate-line" :src="`${this.imageUrl}separate-line.png`" />
                </div>
            </div>
            <a :id="isWebView ? 'button_joinnow2' : 'button_joinnow5'" href="https://my.dooprime.com/ja/login?sl=1" v-t="'jpDooTravel.joinNow'"></a>
        </div>
    </section>
</template>

<script lang="ts">
import { ref,  onMounted } from 'vue'
export default {
    data() {
        return {
            imageUrl: "https://cdn.doogroup.com/jp-doo-travel/",
        };
    },
    setup() {
        const isWebView = ref(window.innerWidth > 1000);

        const calculateScreenWidth = async () => {
            isWebView.value = window.innerWidth > 1000;
        }
        onMounted(() => {
            window.addEventListener("resize", () => { calculateScreenWidth() });
        });
        return {
            isWebView
        }
    },
}
</script>

<style lang="scss" scoped>
p,
h1 {
    margin: 0;
}

ul {

    padding-left: 0;
}

.bg {
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;

    .content {
        padding-bottom: 45px !important;
        display: flex;
        flex-direction: column;
        max-width: 1360px;
        padding: 0 16px;

        .title {
            padding-top: 32px;
            font-size: 36px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: center;
            color: #de2026;
            font-size: 36px;
            margin-bottom: 4px;

            @media screen and (max-width:1000px) {
                font-size: 24px;
            }
        }

        .wrapper {
            display: flex;
            justify-content: center;
            padding: 0 20px;

            .container-line {
                display: flex;
                justify-content: center;
                align-items: center;
                padding-left: 13px;
                width: 262px;
            }
        }

        .steps {
            display: flex;
            flex-direction: row;
            margin-top: 32px;
            max-width: 1360px;

            @media screen and (max-width:1000px) {
                flex-direction: column;
                max-width: 1000px;
            }

            .step {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                flex-basis: 33%;
        
                @media screen and (max-width:1000px) {
                    flex-basis: 100%;
                }

                .step-box {
                    display: flex;
                    flex-direction: row;
        
                    .plane-number {
                        width: 92px;
                        height: 92px;
                        margin-top: 0;
        
                        @media screen and (max-width:1000px) {
                            width: 48px;
                            height: 48px;
                            margin-top: 4px;
                        }
                    }
        
                    .step-txt {
                        display: flex;
                        flex-direction: column;
                        margin-left: 24px;
                        margin-right: 4px;
        
                        .step-title {
                            font-size: 24px;
                            font-weight: bold;
                            font-stretch: normal;
                            font-style: normal;
                            line-height: normal;
                            letter-spacing: normal;
                            text-align: left;
                            color: #000;
                            height: 36px;
        
                            @media screen and (max-width:1000px) {
                                font-size: 20px;
                                height: 30px;
                            }
                        }
        
                        .step-desc {
                            margin-top: 4px;
                            font-size: 20px;
                            font-weight: normal;
                            font-stretch: normal;
                            font-style: normal;
                            line-height: normal;
                            letter-spacing: normal;
                            text-align: left;
                            color: #000;
        
                            @media screen and (max-width:1000px) {
                                font-size: 14px;
                            }
                        }
                    }
                }

                .separate-line {
                    width: 30.5px;
                    height: 74.4px;
                    margin-left: 16px;
                }
            }
        }

        a {
            font-size: 20px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #fff;
            background-image: linear-gradient(to bottom, #f46661 0%, #e51c1c);
            padding: 8px 40px;
            width: fit-content;
            margin-top: 32px;
            display: flex;
            border-radius: 4px;
            align-self: center;
            margin-bottom: 35px;
        }
    }
}
</style>