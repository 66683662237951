<template>
    <div v-if="checkModel()" id="main-modal"
        :class="commonStore.modal.isOpen ? 'show ' + commonStore.modal.name : commonStore.modal.name">
        <div class="modal-overlay" @click="closeModel">
        </div>
        <div class="modal-content-wrapper">

            <RedeemSuccess v-if="commonStore.modal.name === 'RedeemSuccess'" />
            <RedeemSuccess2 v-if="commonStore.modal.name === 'RedeemSuccess2'" />
            <RedeemFail v-if="commonStore.modal.name === 'RedeemFail'" />
            <PrizeHistory v-if="commonStore.modal.name === 'PrizeHistory'" />
            <Login v-if="commonStore.modal.name === 'Login'" />
            <InvalidCountry v-if="commonStore.modal.name === 'InvalidCountry'" />
            <RankModal v-if="commonStore.modal.name === 'RankModal'" />
            <!-- <RedeemConfirm v-if="commonStore.modal.name === 'RedeemConfirm'" :getLevelFunction=getLevelFunc />
            <RedeemForm v-if="commonStore.modal.name === 'RedeemForm'" :getLevelFunction=getLevelFunc /> -->
            <RedeemFail2 v-if="commonStore.modal.name === 'RedeemFail2'" />
            <DepositInfo v-if="commonStore.modal.name === 'DepositInfo'" />
            <RedPacketHoliday v-if="commonStore.modal.name === 'RedPacketHoliday'"
                :getTotalRewardFunction="getTotalRewardFunction" :isClaimedFunction="updateClaimedStatus"
                :checkRedPacketFunction="checkRedPacketFunction" />
            <RedPacketBirthday v-if="commonStore.modal.name === 'RedPacketBirthday'"
                :getTotalRewardFunction="getTotalRewardFunction" :isClaimedFunction="updateClaimedStatus"
                :checkRedPacketFunction="checkRedPacketFunction" />
            <RewardList v-if="commonStore.modal.name === 'RewardList'" :totalRewardApiData="totalRewardApiData"
                :getTotalRewardFunction="getTotalRewardFunction" />
            <PassEventRecord v-if="commonStore.modal.name === 'PassEventRecord'"
                :previousResultApiData="previousResultApiData" />
            <!-- <div v-if="commonStore.modal.name !== 'DepositInfo'" class="modal-btn-close"
                @click="(status === false) ? commonStore.closeModal() : refreshPage()"><img
                    :src="imageUrl + 'icon-close.svg'" />
            </div> -->
        </div>
    </div>
</template>

<script lang="ts">
import { useCommonStore } from '@/stores/common'
import RedeemSuccess from './RedeemSuccess.vue'
import RedeemFail from './RedeemFail.vue'
import PrizeHistory from './PrizeHistory.vue'
import Login from './Login.vue'
import InvalidCountry from './InvalidCountry.vue'
import RankModal from './RankModal.vue'
import RedeemConfirm from './RedeemConfirm.vue'
import RedeemForm from './RedeemForm.vue'
import RedeemFail2 from './RedeemFail2.vue'
import DepositInfo from './DepositInfo.vue'
import RedeemSuccess2 from './RedeemSuccess2.vue'
import RedPacketHoliday from './RedPacketHoliday.vue'
import RedPacketBirthday from './RedPacketBirthday.vue'
import RewardList from './RewardList.vue'
import PassEventRecord from './PassEventRecord.vue'
import { TotalRewardData } from '@/modal/TotalReward'
import { ResultData } from '@/modal/Result'

export default {
    props: {
        // getLevelFunc: {},
        totalRewardApiData: {
            default: {} as TotalRewardData,
        },
        checkRedPacketFunction: {},
        getTotalRewardFunction: {},
        previousResultApiData: {
            default: {} as ResultData,
        }
    },
    setup() {
        const commonStore = useCommonStore()
        return {
            commonStore
        }
    },
    components: {
        RedeemSuccess,
        RedeemFail,
        PrizeHistory,
        Login,
        InvalidCountry,
        RankModal,
        RedeemConfirm,
        RedeemForm,
        RedeemFail2,
        DepositInfo,
        RedeemSuccess2,
        RedPacketHoliday,
        RedPacketBirthday,
        RewardList,
        PassEventRecord,
    },
    data() {
        return {
            imageUrl: "http://doo-prime-static.oss-cn-hongkong.aliyuncs.com/8-year-anniversary/8-year-ib/",
            isClaimed: false,
        }
    },

    methods: {
        checkModel() {
            if (this.commonStore.modal.name == 'EventEnd') {
                return false;
            } else if (this.commonStore.modal.name == 'InvalidCountry') {
                return false;
            } else {
                return true;
            }
        },
        refreshPage() {
            window.location.reload();
        },
        updateClaimedStatus() {
            if (this.isClaimed == false) {
                this.isClaimed = true
            } else {
                this.isClaimed = false
            }
        },
        async closeModel() {
            if (this.isClaimed == true) {
                await this.checkRedPacketFunction()
            } else {
                this.commonStore.closeModal()
            }
        }
    },
}
</script>

<style lang="scss">
#main-modal {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    padding: 0 16px;
    pointer-events: none;

    .modal-content {
        max-width: 311px;


        // @media only screen and (max-width: 500px) {
        //     max-width: 100%;
        // }
    }

    &.show {
        pointer-events: all;

        .modal-overlay {
            transition: 1s;
            opacity: 0.6;
        }

        .modal-content {
            transition: transform 1s, opacity 1s;
            transform: translate(-50%, calc(-50% - 34px));
            opacity: 1;

            @include mobile-screen () {
                transform: translate(-50%, calc(-50% - 34px));
            }
        }
    }
}

.modal-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #000000;
    opacity: 0;
    transition: 1s;
}

.modal-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -40%);
    padding: 16px 28px;
    // background-color: #ffffff;
    /* border-radius: 10px; */
    background-image: linear-gradient(to bottom, #f8edda, #fff);
    box-shadow: 2px 2px 15px 0 rgba(0, 0, 0, 0.15);
    border-radius: 12px;
    width: calc(100% - 32px);
    max-width: 831px;
    opacity: 0;
    transition: transform 1s, opacity 1s;

    @include mobile-screen () {
        transform: translate(-50%, -300px);
        top: 50%;
    }



    .about-title {
        margin-bottom: 22px;
    }

    .about-description {
        font-size: 20px;
        font-weight: normal;
        line-height: 1.5;
        letter-spacing: normal;
        text-align: center;
        color: #313131;
        margin-bottom: 12px;

        @include mobile-screen () {
            font-size: 12px;
        }
    }
}

.modal-btn-close {
    position: relative;
    // bottom: -52px;
    left: 50%;
    margin-left: -15px;
    font-size: 14px;
    font-weight: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #ccc;
    cursor: pointer;
    // height: 0;
    width: 32px;
    height: 32px;
}
</style>