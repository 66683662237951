<template>
  <div id="annualDetail" class="child_bg">
    <div class="container">
      <Back :pageTitle="this.$t('ib6.eventAnnual.title')" />
      <childPageContainer :eventTitle="this.$t('ib6.eventAnnual.descriptionTitle')">
        <p class="desc">{{ $t(`ib6.eventDescription.description9`) }}</p>
        <EventSatisticTime :period="this.$t('ib6.eventAnnual.period')"
          :condition="this.$t('ib6.eventAnnual.conditionTimeZone')" />
        <div class="rt_ribbon">
          <img class="ribbon5" :src="imageUrl + 'ribbon5.png'" />
        </div>
        <AnnualList />
        <div class="lf_ribbon">
          <img class="ribbon8" :src="imageUrl + 'ribbon8.png'" />
        </div>
      </childPageContainer>
      <EventRule eventRuleTitle="eventAnnual" />
    </div>
  </div>
</template>

<script lang="ts">
import AnnualList from "@/views/ib6/AnnualList.vue";
import EventSatisticTime from "@/views/ib6/home_event/EventSatisticTime.vue";
import childPageContainer from "@/components/childPageContainer.vue";
import Back from "@/components/button/Back.vue";
import EventRule from "@/views/ib6/EventRule.vue";

export default {
  data() {
    return {
      imageUrl:
        "https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/ib_event/ib6/images/",
      trophyType: ["gold", "sliver", "bronze"],
    };
  },
  components: {
    EventSatisticTime,
    AnnualList,
    childPageContainer,
    Back,
    EventRule,
  },
};
</script>

<style lang="scss">
#annualDetail {
  .desc {
    margin: 30px 0 0 0 !important;
    padding: 0;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #000;
  }

  .rt_ribbon {
    position: relative;

    .ribbon5 {
      position: absolute;
      transform: translate(32%, 35%);
      top: 0;
      right: 0;
      width: 124px;
      height: 202.4px;
    }
  }

  .lf_ribbon {
    position: relative;

    .ribbon8 {
      position: absolute;
      transform: translate(-25%, -200%);
      bottom: 0;
      left: 0;
      width: 156px;
      height: 209.3px;
    }
  }
}
</style>
