<template>
    <Navbar />
    <div :class="`banner ${this.$i18n.locale}`">
        <img v-if="$i18n.locale == 'tc'" class="kv_title" :src="imageUrl2 + 'kv_title_tc.png'">
        <img v-else class="kv_title" :src="imageUrl2 + 'kv_title_sc.png'">
    </div>
</template>

<script lang="ts">
import { useI18n } from 'vue-i18n';
import Navbar from '@/components/Header/Navbar.vue'

export default {
    setup() {
        const i18n = useI18n();

        return {
            i18n,
        }
    },
    components: {
        Navbar,
    },
    data() {
        return {
            imageUrl: "http://doo-prime-static.oss-cn-hongkong.aliyuncs.com/ib_3.0/images/",
            imageUrl2: "https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/ib_event/ib6/images/",
        }
    },
}
</script>

<style lang="scss" scoped>
$cdn: 'http://doo-prime-static.oss-cn-hongkong.aliyuncs.com/ib_event/ib6/images/';

.banner {
    background: url($cdn + "banner.png") no-repeat center top;
    display: flex;
    align-items: center;
    min-height: 700px;
    max-width: 600px;
    width: 100%;
    margin: auto;
    background-size: cover !important;

    @media screen and (min-width:600px) {
        min-height: 850px;
    }

    .kv_title {
        max-width: 278px;
        width: 100%;
        position: absolute;
        top: 140px;
        left: 50%;
        transform: translate(-50%, -50%);
        object-fit: contain;

        @media screen and (min-width:600px) {
            top: 140px;
            max-width: 350px;
        }

    }

    &.sc,
    &.zh {
        background: url($cdn + "banner.png") no-repeat center top;
    }

    &.tc {
        background: url($cdn + "banner.png") no-repeat center top;
    }

    &.kr {
        background: url($cdn + "banner_KR@2x.png") no-repeat center top;
    }

    &.jp {
        background: url($cdn + "banner_JP@2x.png") no-repeat center top;
    }

    img {
        max-width: 366px;
    }

    @include mobile-screen() {
        background: url($cdn + "banner.png") no-repeat center top;

        &.sc,
        &.zh {
            background: url($cdn + "banner.png") no-repeat center top;
        }

        &.tc {
            background: url($cdn + "banner.png") no-repeat center top;
        }

        &.kr {
            background: url($cdn + "banner_KR_mobile.png") no-repeat center top;
        }

        &.jp {
            background: url($cdn + "banner_JP_mobile.png") no-repeat center top;
        }

        img {
            max-width: 308px;
        }
    }



    .container {
        max-width: 600px;
        text-align: center;
        padding: 77.6px 16px 0;
        min-height: 700px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;

        h1 {
            font-size: 40px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: 2px;
            text-align: center;
            color: #fff;
            margin: 4px 0 16px;
        }

        hr {
            border-color: #ed0000;
            width: 46px;
            margin-left: 0;
            margin-bottom: 24px;
            display: none;
        }

        .banner-title-1 {
            background-image: linear-gradient(to bottom, #ff7878, #d91d22);
            font-family: MicrosoftYaHei;
            font-size: 22px;
            font-weight: 900;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: 1.38px;
            text-align: center;
            -webkit-background-clip: text;
            background-clip: text;
            -webkit-text-fill-color: transparent;
            margin: 0px 0px 15px 0px;
        }

        .banner-title-2 {
            background-color: #d91d22;
            font-family: MicrosoftYaHei;
            font-size: 27px;
            font-weight: 900;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: 2.19px;
            text-align: center;
            -webkit-background-clip: text;
            background-clip: text;
            -webkit-text-fill-color: transparent;
            margin: 0;
        }
    }
}
</style>